import { ContextStatic } from "@devslane/mobx-entity-manager";
import { UserModel } from "../../models/UserModel";

class UserService {
  static getInstance(): UserService {
    return new UserService();
  }

  async updateUser(data: {
    firstName?: string;
    lastName?: string;
    public_notes?: string;
    private_notes?: string;
    date_of_birth?: string;
  }): Promise<UserModel> {
    return await ContextStatic.default.apiService
      .put("/profile", data)
      .then((res: any) => {
        const me = res?.data || {};
        return UserModel.fromJson({ ...me }) as UserModel;
      });
  }

  async resetPassword(data: {
    token: string;
    password: string;
    confirmPassword: string;
  }): Promise<any> {
    return await ContextStatic.default.apiService.post(
      "accounts/reset-password",
      data
    );
  }

  async forgotPassword(data: { email: string }): Promise<any> {
    return await ContextStatic.default.apiService.post(
      "accounts/forgot-password",
      data,
      {
        extras: { useAuth: false },
      }
    );
  }

  async login(data: {
    email: string;
    password: string;
  }): Promise<{ user: UserModel; token: string }> {
    return await ContextStatic.default.apiService
      .post("accounts/authenticate", data, {
        extras: { useAuth: false },
      })
      .then((res: any) => {
        return {
          user: UserModel.fromJson({ ...(res || []) }) as UserModel,
          token: res?.jwtToken || "",
        };
      });
  }

  async signUp(data: {
    firstName: string;
    lastName: string;
    name?: string;
    acceptTerms: boolean;
    password: string;
    confirmPassword: string;
    email: string;
    source: string;
  }): Promise<{ user: UserModel; token: string }> {
    return await ContextStatic.default.apiService
      .post("accounts/register", data, {
        extras: { useAuth: false },
      })
      .then((res: any) => {
        return {
          user: res.data || [],
          token: res?.verificationToken || "",
        };
      });
  }

  async createBusiness(data: {
    first_name: string;
    last_name: string;
    role: string;
    email: string;
    status: string;
  }): Promise<{ user: UserModel; token: string }> {
    return await ContextStatic.default.apiService
      .post("business/create", data, {
        extras: { useAuth: false },
      })
      .then((res: any) => {
        return {
          user: res || [],
          token: res?.verificationToken || "",
        };
      });
  }

  async verifyEmail(data: { token: any }): Promise<any> {
    return await ContextStatic.default.apiService.post(
      "/accounts/verify-email",
      data
    );
  }

  async me(): Promise<{ user: UserModel; token: string }> {
    return await ContextStatic.default.apiService
      .post("/accounts/refresh-token", {})
      .then((res: any) => {
        return {
          user: UserModel.fromJson({ ...(res || []) }) as UserModel,
          token: res?.jwtToken || "",
        };
      });
  }

  async sampleForm(data: any): Promise<{ data: any }> {
    return await ContextStatic.default.apiService
      .post("/crm/sample-form", data)
      .then((res: any) => {
        return res;
      });
  }
}

export const userService = UserService.getInstance();
