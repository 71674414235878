import {
  EntityBaseModel,
  EntityIdentifier,
  ModelJson,
  ModelList,
} from "@devslane/mobx-entity-manager";
import { observable } from "mobx";
import { UserStore } from "store/entities/UserStore";
import { UserRoles } from "../../constants/enums/UserRoles";
import { Utils } from "../../Utils/Utils";
import { BusinessModel } from "./BusinessModel";

export class UserModel extends EntityBaseModel {
  static _store: UserStore;

  @observable
  firstName!: string;

  @observable
  lastName!: string;

  @observable
  memberships?: ModelList<BusinessModel>;

  @observable
  avatar?: string;

  @observable
  created?: string;

  @observable
  date_of_birth?: string;

  @observable
  private_notes?: string;

  @observable
  public_notes?: string;

  @observable
  nationality?: string;

  @observable
  special_requirements?: string;

  @observable
  email?: string;

  @observable
  isVerified?: string;

  @observable
  source?: string;

  @observable
  role?: UserRoles;

  @observable
  account_id?: EntityIdentifier;

  deserialize_memberships(data: ModelJson[]) {
    this.memberships = new ModelList<BusinessModel>(BusinessModel);
    this.memberships.deserialize(data);
  }

  toJSON() {
    return Utils.sanitizeObject({
      id: this.id,
      firstName: this.firstName,
      lastName: this.lastName,
      avatar: this.avatar,
      created: this.created,
      date_of_birth: this.date_of_birth,
      email: this.email,
      isVerified: this.isVerified,
      role: this.role,
      source: this.source,
      private_notes: this.private_notes,
      public_notes: this.public_notes,
      nationality: this.nationality,
      special_requirements: this.special_requirements,
      memberships: (this.memberships?.items || []).map(
        (member: BusinessModel) => member.toJSON()
      ),
    });
  }
}
