import React, { FC, useEffect, useState } from "react";
import Modal from "../../../../shared/Modal/Modal";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import Table from "../../../../shared/Table/Table";
import AddTripContact from "./AddTripContact";
import GenericHeader from "../../../../shared/Headers/GenericHeader";
import { AppContext } from "App.context";
import IconButton from "shared/IconButton/IconButton";
import { ContactModel } from "store/models/ContactModal";
import { EntityIdentifier } from "@devslane/mobx-entity-manager";
import { ERROR_WENT_WRONG } from "constants/message";
import { observer } from "mobx-react";
import { InputWithoutFormik } from "../../../../shared/Input/Input";
import Loader from "react-loader-spinner";
import { debounce } from "lodash";

const TripContacts: FC = () => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [editData, setEditData] = useState<ContactModel | undefined>();
  const { store, toast } = AppContext;
  const currentTripId = new URLSearchParams(location.search).get("id");
  const businessId = store.userStore.loggedInUser?.memberships?.items?.[0]?.id;

  const contactList = store.tripStore.tripContactList.items;

  const [filterContact, setFilterContact] =
    useState<ContactModel[]>(contactList);

  const staffTable = {
    headingsArray: [
      "Name",
      "Contact Number",
      "Email",
      "Location",
      "Contact Hours",
      "Actions",
    ],
  };
  const handleDelete = async (id: EntityIdentifier) => {
    try {
      if (confirm("Are you sure you want to delete this contact ?")) {
        await store.tripStore.deleteTripContact(
          currentTripId as any,
          businessId!,
          id
        );
        toast.showSuccess("Contact Deleted Successfully");
      }
    } catch (e: any) {
      toast.showError(e.message || ERROR_WENT_WRONG);
    }
  };
  const dataArray: any = (filterContact || [])?.map((staff) => {
    return [
      staff.name,
      staff.number,
      staff.email,
      staff.location,
      staff.hours,
      <div className={"flex space-x-3 items-center"}>
        <IconButton
          child={FaEdit}
          className="w-4 h-4"
          onClick={() => setEditData(staff)}
          buttonClassName={"text-white rounded-full p-2 bg-success-500"}
        />

        <div className="">
          <IconButton
            child={FaTrashAlt}
            onClick={() => handleDelete(staff.id)}
            className="w-4 h-4"
            buttonClassName={" text-white rounded-full p-2 bg-error-500"}
          />
        </div>
      </div>,
    ];
  });

  const ifSearchIncludes = (data: ContactModel, searchKey: string) => {
    const searchCriteriaArray: Array<any> = [
      data.name?.trim()?.toLowerCase(),
      data.number?.trim()?.toLowerCase(),
      data.email?.trim()?.toLowerCase(),
      data.location?.trim()?.toLowerCase(),
    ];
    if (
      searchCriteriaArray?.some((val) =>
        val?.includes(searchKey?.trim()?.toLowerCase())
      )
    )
      return true;

    return false;
  };

  const handleSearch = debounce((searchKey: string): void => {
    const filtered = contactList.filter((data) => {
      return ifSearchIncludes(data, searchKey);
    });

    setFilterContact(filtered);
  }, 200);
  useEffect(() => {
    store.tripStore.loadTripContacts(currentTripId as any, businessId!);
  }, [businessId]);

  useEffect(() => {
    setFilterContact(contactList);
  }, [contactList]);

  const closeModal = () => {
    setEditData(undefined);
    setOpenModal(false);
  };

  return (
    <>
      <Modal
        modalStyle={{ width: "50rem", maxHeight: "95%", overflowY: "auto" }}
        open={openModal || !!editData}
        onOutsideClick={() => setOpenModal(false)}
      >
        <AddTripContact modalHandler={closeModal} editData={editData} />
      </Modal>
      <div className="bg-white">
        <div className="pb-8">
          <GenericHeader
            heading={"Trip Contacts"}
            subHeading={
              "Add and Manage contact numbers for the trip, that guests can view and contact"
            }
            buttonOnClick={() => setOpenModal(true)}
            buttonText={"Add Contact"}
          />
          <hr className="h-0.5 mt-2 bg-gray-50 mx-auto w-full" />

          <div className={"px-8 mt-4"}>
            <InputWithoutFormik
              type="text"
              placeholder="Search Keyword"
              onChange={(e) => handleSearch(e.target.value || "")}
            />
          </div>
          <div className="mt-6 w-full px-8">
            {store.tripStore.tripContactList.loading ? (
              <div className={"flex justify-center mx-auto"}>
                <Loader type="BallTriangle" height={30} width={30} />
              </div>
            ) : (
              <Table headers={staffTable.headingsArray} data={dataArray} />
            )}
          </div>
          <hr className="h-0.5 mt-10 bg-gray-50 mx-auto w-full" />
        </div>
      </div>
    </>
  );
};
export default observer(TripContacts);
