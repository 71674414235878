import {
  EntityBaseModel,
  ModelItem,
  ModelJson,
  ModelList,
} from "@devslane/mobx-entity-manager";
import { observable } from "mobx";
import { TripStore } from "store/entities/TripStore";
import { Utils } from "../../Utils/Utils";
import { ImageModel } from "./ImageModel";
import { OverviewModal } from "./OverviewModal";

export class TripModel extends EntityBaseModel {
  static _store: TripStore;

  @observable
  name!: string;

  @observable
  trip_code!: string;

  @observable
  start_date!: string;

  @observable
  end_date!: string;

  @observable
  location!: string;

  @observable
  cover_image!: ImageModel;

  @observable
  images?: ModelList<ImageModel>;

  @observable
  overview?: ModelItem<OverviewModal>;

  deserialize_images(data: ModelJson[]) {
    this.images = new ModelList<ImageModel>(ImageModel);
    this.images.deserialize(data);
  }

  deserialize_overview(data: ModelJson) {
    this.overview = new ModelItem<OverviewModal>(OverviewModal);
    this.overview.deserialize(data);
  }

  toJSON() {
    return Utils.sanitizeObject({
      id: this.id,
      name: this.name,
      start_date: this.start_date,
      trip_code: this.trip_code,
      location: this.location,
      images: (this.images?.items || []).map((image: ImageModel) =>
        image.toJSON()
      ),
      overview: this.overview?.item.toJSON(),
    });
  }
}
