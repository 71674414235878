import { action } from "mobx";
import { ChildStore } from "./ChildStore";
import { UserStore } from "./entities/UserStore";
import { BusinessStore } from "./entities/BusinessStore";
import { SectionStore } from "./entities/SectionStore";
import { TripStore } from "./entities/TripStore";
import { ArticleStore } from "./entities/ArticleStore";
import { ImageStore } from "./entities/ImageStore";

export class RootStore {
  private static _instance: RootStore;

  userStore: UserStore;
  businessStore: BusinessStore;
  sectionStore: SectionStore;
  tripStore: TripStore;
  articleStore: ArticleStore;
  imageStore: ImageStore;

  private constructor() {
    // We will pass the instance of RootStore in ChildStore for better accessibility of siblings
    this.userStore = new UserStore(this);
    this.businessStore = new BusinessStore(this);
    this.sectionStore = new SectionStore(this);
    this.tripStore = new TripStore(this);
    this.articleStore = new ArticleStore(this);
    this.imageStore = new ImageStore(this);
  }

  static getInstance(): RootStore {
    if (!this._instance) {
      this._instance = new RootStore();
    }

    return this._instance;
  }

  @action
  logout(): void {
    Object.values(this)
      .filter((v) => v instanceof ChildStore)
      .forEach((v) => (v as ChildStore<any>).reset());
  }
}
