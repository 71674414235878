import { ContextStatic, EntityIdentifier } from "@devslane/mobx-entity-manager";
import queryString from "query-string";
import { map } from "lodash";
import { ImageModel } from "../../models/ImageModel";
import { AppContext } from "App.context";

class ImageService {
  static getInstance(): ImageService {
    return new ImageService();
  }

  async updateImage(
    business_id: EntityIdentifier,
    data: {
      _id: EntityIdentifier;
      url: string;
      name: string;
      tags: Array<string>;
    }
  ): Promise<any> {
    return await ContextStatic.default.apiService
      .post(`business/images/?business_id=${business_id}`, data)
      .then((res: any) => {
        const section = res?.data || [];
        return {
          data: map(section, (information) => {
            return ImageModel.fromJson(information) as ImageModel;
          }),
        };
      });
  }
  async updateDocument(
    trip_id: EntityIdentifier,
    business_id: EntityIdentifier,
    data: {
      _id: EntityIdentifier;
      url: string;
      name: string;
    }
  ): Promise<any> {
    debugger
    const { store } = AppContext;
    return await ContextStatic.default.apiService
      .post(
        `trips/documents/?business_id=${business_id}&trip_id=${trip_id}`,
        data
      )
      .then((res: any) => {
        const section = res?.data || [];
        return {
          data: map(section, (information) => {
            information = {
              ...information,
              id: information._id,
            };
            return ImageModel.fromJson(information) as ImageModel;
          }),
        };
      });
  }

  async deleteDocument(
    trip_id: EntityIdentifier,
    business_id: EntityIdentifier,
    document_id: EntityIdentifier
  ): Promise<any> {
    const { store } = AppContext;
    return await ContextStatic.default.apiService
      .delete(
        `trips/documents?business_id=${business_id}&trip_id=${trip_id}&document_id=${document_id}`
      )
      .then((res: any) => {
        const section = res?.data || [];
        return {
          data: map(section, (information) => {
            information = {
              ...information,
              id: information._id,
            };
            return ImageModel.fromJson(information) as ImageModel;
          }),
        };
      });
  }

  async deleteImage(data: {
    business_id: EntityIdentifier;
    image_id: EntityIdentifier;
  }): Promise<any> {
    const query = queryString.stringify(data);

    return await ContextStatic.default.apiService.delete(
      `business/images?${query}`
    );
  }

  async addImage(data: {
    business_id: EntityIdentifier;
    name: string;
    url: string;
    tags: Array<string>;
  }): Promise<{ data: any }> {
    return await ContextStatic.default.apiService
      .post(`business/images/add/?business_id=${data.business_id}`, data)
      .then((res: any) => {
        return {
          data: res.data || [],
        };
      });
  }

  async addDocument(data: {
    trip_id: EntityIdentifier;
    business_id: EntityIdentifier;
    name: string;
    url: string;
  }): Promise<{ data: any }> {
    debugger
    return await ContextStatic.default.apiService
      .post(
        `trips/documents/add/?business_id=${data.business_id}&trip_id=${data.trip_id}`,
        data
      )
      .then((res: any) => {
        const staffs = res?.data || [];
        return {
          data: map(staffs, (staff) => {
            staff = {
              ...staff,
              id: staff._id,
            };
            return ImageModel.fromJson(staff);
          }),
        };
      });
  }
  async loadDocuments(
    trip_id: EntityIdentifier,
    business_id: EntityIdentifier
  ): Promise<{ data: any }> {
    const { store } = AppContext;
    return await ContextStatic.default.apiService
      .get(`trips/documents?business_id=${business_id}&trip_id=${trip_id}`)
      .then((res: any) => {
        console.log("res.data", res.data.documents)
        const staffs = res?.data?.documents || [];
        return {
          data: map(staffs, (staff) => {
            staff = {
              ...staff,
              id: staff._id,
            };
            return ImageModel.fromJson(staff);
          }),
        };
      });
  }
}

export const imageService = ImageService.getInstance();
