import React from "react";
import AsyncSelect from "react-select/async";
import { SingleValue } from "react-select";

interface Props {
  label?: string;
  name: string;
  disabled?: boolean;
  loadAutoCompleteOption: any;
  onPlaceSelect: (v: SingleValue<{ placeId: string; label: string }>) => void;
  defaultValue: string;
  defaultOption: string;
}

const ReactAsyncSelectMenu: React.FC<Props> = ({
  label,
  loadAutoCompleteOption,
  onPlaceSelect,
  name,
  defaultOption,
  defaultValue,
}) => {
  const customStyles = {
    control: (base: any, state: any) => ({
      ...base,
      boxShadow: "10px",
      borderWidth: 1,
      borderRadius: "6px",
      minHeight: 42,
      height: 42,
      borderColor: state.isFocused ? "#84e1bc" : base.borderColor,
      "&:hover": {
        borderColor: state.isFocused ? "#84e1bc" : base.borderColor,
      },
    }),
    placeholder: (provided: any) => ({
      ...provided,
      color: "#6b7280",
      fontSize: "16px",
    }),
    input: (provided: any) => ({
      ...provided,
      color: "#6b7280",
      fontSize: "16px",
    }),
  };

  return (
    <div className="w-full">
      {label && (
        <span className={`mb-2 block text-sm  text-gray-800`}>{label}</span>
      )}

      <AsyncSelect
        defaultOptions={
          defaultOption ? [{ placeId: "", label: defaultOption }] : undefined
        }
        value={defaultValue ? { placeId: "", label: defaultValue } : undefined}
        cacheOptions
        id={name}
        isClearable
        getOptionValue={(option) => option.placeId}
        placeholder="Enter address here..."
        loadOptions={loadAutoCompleteOption}
        onChange={onPlaceSelect}
        styles={customStyles}
      />
    </div>
  );
};

export default ReactAsyncSelectMenu;
