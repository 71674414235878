import React, { FunctionComponent, CSSProperties } from "react";
import classNames from "classnames";
import Transition from "../Transition/Transition";
import useOutsideClickDetector from "../../Hooks/useOutsideClickDetector";
import useEscapeKeyDetector from "../../Hooks/useEscapeKeyDetector";

export interface ModalProps {
  open: boolean;
  modalStyle?: CSSProperties;
}

interface Props extends ModalProps {
  onOutsideClick: VoidFunction;
  className?: string;
}

const Modal: FunctionComponent<Props> = ({
  open,
  onOutsideClick,
  children,
  modalStyle,
  className,
}) => {
  const wrapperRef = useOutsideClickDetector<HTMLDivElement>(onOutsideClick);
  const wrap = useEscapeKeyDetector<HTMLDivElement>(onOutsideClick);

  return (
    <div
      className={classNames(
        "fixed bottom-0 inset-x-0 px-4 pb-4 inset-0  flex items-center justify-center " +
          className
        // { '-z-999': !open },
      )}
      style={{ zIndex: open ? 1002 : -999 }}
    >
      <Transition
        show={open}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 transition-opacity">
          <div className="absolute inset-0 bg-gray-500 opacity-75" />
        </div>
      </Transition>

      <Transition
        show={open}
        enter="ease-out duration-300"
        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        enterTo="opacity-100 translate-y-0 sm:scale-100"
        leave="ease-in duration-100"
        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      >
        <div
          ref={wrapperRef || wrap}
          style={modalStyle}
          className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:w-full"
          role="dialog"
          aria-modal={open}
          aria-labelledby="modal-headline"
        >
          {children}
        </div>
      </Transition>
    </div>
  );
};

export default Modal;
