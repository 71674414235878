import { action, observable } from "mobx";
import { ChildStore } from "../ChildStore";
import { Context } from "../Context";
import { RootStore } from "../RootStore";
import { businessService } from "../services/api-services/BusinessService";
import {
  EntityIdentifier,
  ModelItem,
  ModelJson,
  ModelList,
} from "@devslane/mobx-entity-manager";
import { UserRoles } from "../../constants/enums/UserRoles";
import { map } from "lodash";
import { UserModel } from "../models/UserModel";
import { SectionModel } from "store/models/SectionModal";

export class SectionStore extends ChildStore<SectionModel> {
  constructor(rootStore: RootStore) {
    super(rootStore);
    SectionModel._store = this;
  }

  @observable sectionList: ModelList<SectionModel> =
    new ModelList<SectionModel>(SectionModel);

  @action
  async addSection(data: {
    business_id: EntityIdentifier;
    name: string;
    order?: number;
  }): Promise<any> {
    try {
      return await businessService.createSection(data).then((res) => {
        const sectionResponse = res.data?.data?.[res.data.data.length - 1];
        const section = {
          id: sectionResponse?._id,
          name: sectionResponse?.name,
          content: sectionResponse?.content,
        };
        const sectionData: any = SectionModel.fromJson(section);
        this.sectionList.appendItem(sectionData);
      });
    } catch (e) {
      throw e;
    }
  }

  @action
  async deleteSection(data: {
    business_id: EntityIdentifier;
    section_id: EntityIdentifier;
  }): Promise<any> {
    try {
      return await businessService.deleteSection(data).then((res) => {
        const allItems = this.sectionList.items.map((section: SectionModel) =>
          section.toJSON()
        );
        const section = allItems.filter(
          (session) => data.section_id !== session.id
        );
        this.sectionList.deserialize(section as ModelJson[]);

        return res.message;
      });
    } catch (e) {
      throw e;
    }
  }

  @action
  async deleteArticle(data: {
    business_id: EntityIdentifier;
    section_id: EntityIdentifier;
    article_id: EntityIdentifier;
  }): Promise<any> {
    try {
      return await businessService.deleteArticle(data).then((res) => {
        this.sectionList.deserialize(
          map(res.data, (section) => section.toJSON()) as ModelJson[]
        );
        this.sectionList.setLoading(false);
        return res.message;
      });
    } catch (e) {
      throw e;
    }
  }
  @action
  async updateSection(data: {
    business_id: EntityIdentifier;
    section_id: EntityIdentifier;
    name: string;
  }): Promise<any> {
    return await businessService.updateSection(data).then((res) => {
      this.sectionList.deserialize(
        map(res.data, (section) => section.toJSON()) as ModelJson[]
      );
      this.sectionList.setLoading(false);
      return res.message;
    });
  }
  @action
  async updateArticle(data: {
    business_id: EntityIdentifier;
    section_id: EntityIdentifier;
    _id: EntityIdentifier;
    name: string;
    description: string;
  }): Promise<any> {
    return await businessService.updateArticle(data).then((res) => {
      this.sectionList.deserialize(
        map(res.data, (section) => section.toJSON()) as ModelJson[]
      );
      this.sectionList.setLoading(false);
      return res;
    });
  }
  @action
  async addArticle(data: {
    business_id: EntityIdentifier;
    section_id: EntityIdentifier;
    name: string;
    description: string;
    order?: number;
  }): Promise<any> {
    try {
      return await businessService.createArticle(data).then((res) => {
        this.sectionList.deserialize(
          map(res.data, (section) => section.toJSON()) as ModelJson[]
        );
        this.sectionList.setLoading(false);
        return res;
      });
    } catch (e) {
      throw e;
    }
  }

  @action
  async loadSection(business_id: EntityIdentifier): Promise<any> {
    this.sectionList.setLoading(true);
    try {
      return await businessService.getSection(business_id).then((res) => {
        this.sectionList.deserialize(
          map(res.data, (staff) => staff.toJSON()) as ModelJson[]
        );
        this.sectionList.setLoading(false);
        return res;
      });
    } catch (e) {
      this.sectionList.setLoading(false);
      throw e;
    }
  }

  @action
  async reorderSection(
    payload: any,
    business_id: EntityIdentifier
  ): Promise<any> {
    try {
      return await businessService
        .reorderSection(payload, business_id)
        .then((res) => {
          return res;
        });
    } catch (e) {
      throw e;
    }
  }
  @action
  async reorderQuestion(
    payload: any,
    business_id: EntityIdentifier,
    section_id: EntityIdentifier
  ): Promise<any> {
    try {
      return await businessService
        .reorderQuestion(payload, business_id, section_id)
        .then((res) => {
          return res;
        });
    } catch (e) {
      throw e;
    }
  }

  reset() {
    this.entities.clear();
    Context.storage.reset();
  }
}
