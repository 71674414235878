import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import TripsListing from "./TripsListing";
import TripHomePage from "./TripHomePage";
import { useLocation } from "react-router-dom";

const TripDashboard: React.FC = () => {
  const [isTripListing, setIsTripListing] = useState<boolean>(true);

  const search = useLocation().search;

  useEffect(() => {
    if (search) {
      setIsTripListing(false);
    } else {
      setIsTripListing(true);
    }
  }, [search]);

  return (
    <div className="">
      {isTripListing ? <TripsListing /> : <TripHomePage />}
    </div>
  );
};

export default observer(TripDashboard);
