import { AxiosError } from "axios";

export enum ErrorCode {
  UNIDENTIFIED,
  UNAUTHORIZED = 401,
  NOT_FOUND = 404,
  UNPROCESSABLE_ENTITY = 422,
  TOO_MANY_REQUESTS = 429,
}

export type validationErrorType = {
  field: string;
  message: string;
  rule: string;
};
export class SafeTravelsBaseError {
  constructor(
    readonly message: string = "Error. Please Try Again.",
    readonly code?: any,
    readonly status: ErrorCode = ErrorCode.UNIDENTIFIED,
    readonly error_code?: any,
    readonly errors: validationErrorType[] = [],
    readonly data: any = []
  ) {}

  static fromJSON(json: AxiosError) {
    return SafeTravelsBaseError.from(json);
  }

  static toJSON(json: SafeTravelsBaseError) {
    return {
      message: json.message || "Error. Please Try Again.",
      code: json.code || -1,
      error_code: json.error_code || -1,
      status: json.status || -1,
      errors: json.errors || [],
      data: json.data || [],
    };
  }

  static from(axiosError: AxiosError): SafeTravelsBaseError {
    if (!axiosError.response) {
      return new SafeTravelsBaseError();
    }

    const { status, data } = axiosError.response;
    return new SafeTravelsBaseError(
      data?.message,
      data?.code,
      status,
      data?.error_code,
      data?.errors || [],
      data?.data || []
    );
  }

  isValidationError(): boolean {
    return (this.errors || []).length > 0;
  }

  errorsByKey(key: string): validationErrorType | undefined {
    return this.errors?.find((er: validationErrorType) => er.field === key);
  }

  hasErrorByKey(key: string): boolean {
    return !!this.errorsByKey(key);
  }
}
