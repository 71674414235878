import React, { useEffect, useState } from "react";
import TripsHeaderNavigation, {
  TripsHeaderNavigationItem,
} from "../TripsHeaderNavigation";
import GenericHeader from "shared/Headers/GenericHeader";
import TripOverviewOverview from "./TripOverviewOverview";
import TripOverivewDescription from "./TripOverviewDescription";
import TripOverviewImages from "./TripOverviewImages";
import { Formik, FormikProps, FormikValues } from "formik";
import * as yup from "yup";
import { AppContext } from "App.context";
import { observer } from "mobx-react";
import { EntityIdentifier } from "@devslane/mobx-entity-manager";
import moment from "moment";
import { TripModel } from "../../../../store/models/TripModel";

interface Props {
  trip: TripModel;
}

enum TripsOverviewSteps {
  TRIP_OVERVIEW = "trip_overview",
  TRIP_DESCRIPTION = "trip_description",
  TRIP_IMAGES = "trip_images",
}

const tripOverviewItems: TripsHeaderNavigationItem[] = [
  {
    label: "Trip Overview",
    value: TripsOverviewSteps.TRIP_OVERVIEW,
  },
  {
    label: "Trip Description",
    value: TripsOverviewSteps.TRIP_DESCRIPTION,
  },
  {
    label: "Trip Images",
    value: TripsOverviewSteps.TRIP_IMAGES,
  },
];

const TripOverview: React.FC<Props> = ({ trip }) => {
  const [currentStep, setCurrentStep] = useState<string>(
    TripsOverviewSteps.TRIP_OVERVIEW
  );
  const { store, toast } = AppContext;

  const businessId = store.userStore.loggedInUser?.memberships?.items?.[0]?.id;
  const currentTripId = new URLSearchParams(location.search).get("id");

  const handleStepChange = (tab: TripsOverviewSteps) => {
    setCurrentStep(tab);
  };

  const [selectedImages, setSelectedImages] = useState<
    Array<{
      image_id: EntityIdentifier;
      cover: boolean;
    }>
  >([]);

  useEffect(() => {
    setSelectedImages(
      trip?.images?.items?.map((item) => ({
        image_id: item.image_id,
        cover: item.cover || false,
      })) || []
    );
  }, [trip]);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        cover_image: trip?.overview?.item.cover_image || "",
        location: trip?.overview?.item.location || "",
        name: trip?.overview?.item.name || "",
        start_date: moment(trip?.overview?.item.start_date).format(
          "YYYY-MM-DD"
        ),
        end_date: moment(trip?.overview?.item.end_date).format("YYYY-MM-DD"),
        description: trip?.overview?.item.description || "",
      }}
      validationSchema={yup.object().shape({
        name: yup.string(),
        business_description: yup.string(),
        description: yup.string(),
        start_date: yup.date().default(() => new Date()),
        end_date: yup
          .date()
          .when(
            "start_date",
            (eventStartDate, schema) =>
              eventStartDate &&
              schema.min(
                eventStartDate,
                "Finish Date Must Later Then Start Date"
              )
          ),
      })}
      onSubmit={async (data, formikHelpers) => {
        formikHelpers.setSubmitting(true);
        const payload = { ...trip, ...data, images: selectedImages };

        try {
          await store.tripStore.updateTrip(
            currentTripId as any,
            businessId!,
            payload
          );

          formikHelpers.setSubmitting(false);
          toast.showInfo("Trip Updated Successfully!");
        } catch (e: any) {
          formikHelpers.setSubmitting(false);
        }
      }}
    >
      {(formikProps: FormikProps<FormikValues>) => (
        <form onSubmit={formikProps.handleSubmit}>
          <div className="flex flex-col  w-full bg-white   h-full">
            <div className="pb-8">
              <GenericHeader
                heading={"Trip Details"}
                subHeading={"Edit Details for the trip"}
                buttonText="Save"
                buttonOnClick={formikProps.handleSubmit}
                isSubmitting={formikProps.isSubmitting}
              />
              <hr className="h-0.5 mt-2 bg-gray-50 mx-auto w-full" />
            </div>
            <div className={""}>
              <TripsHeaderNavigation
                currentSelectedStep={currentStep}
                headerItems={tripOverviewItems}
                handleChange={handleStepChange}
              />
            </div>
            <div className={""}>
              {currentStep === TripsOverviewSteps.TRIP_OVERVIEW && (
                <TripOverviewOverview formikProps={formikProps} />
              )}
              {currentStep === TripsOverviewSteps.TRIP_DESCRIPTION && (
                <TripOverivewDescription formikProps={formikProps} />
              )}

              {currentStep === TripsOverviewSteps.TRIP_IMAGES && (
                <TripOverviewImages
                  formikProps={formikProps}
                  selectedImages={selectedImages}
                  setSelectedImage={setSelectedImages}
                />
              )}
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default observer(TripOverview);
