import { AppContext } from "App.context";
import { ERROR_WENT_WRONG } from "constants/message";
import { Formik, FormikProps, FormikValues } from "formik";
import React, { FC } from "react";
import { ContactModel } from "store/models/ContactModal";
import * as yup from "yup";
import { BrandButton } from "../../../../shared/Button/BrandButton";
import Input from "../../../../shared/Input/Input";
import Loader from "react-loader-spinner";
import GenericHeader from "shared/Headers/GenericHeader";

interface Props {
  modalHandler: () => void;
  editData?: ContactModel;
}
const AddTripContact: FC<Props> = ({ modalHandler, editData }) => {
  const { store, toast } = AppContext;
  const isUpdate = !!editData;
  const businessId = store.userStore.loggedInUser?.memberships?.items?.[0].id;
  const currentTripId = new URLSearchParams(location.search).get("id");

  return (
    <Formik
      initialValues={{
        name: editData?.name || "",
        number: editData?.number || "",
        email: editData?.email || "",
        location: editData?.location || "",
        hours: editData?.hours || "",
      }}
      validationSchema={yup.object().shape({
        email: yup.string().email(),
        name: yup.string().required("First Name is Required"),
        number: yup.string(),
        location: yup.string(),
        hours: yup.string(),
      })}
      onSubmit={async (data, formikHelpers) => {
        formikHelpers.setSubmitting(true);

        try {
          if (isUpdate) {
            await store.tripStore.updateTripContact(
              currentTripId as any,
              businessId!,
              editData.id,
              data
            );
          } else {
            await store.tripStore.addTripContact(
              currentTripId as any,
              businessId!,
              data as ContactModel
            );
          }

          formikHelpers.setSubmitting(false);
          modalHandler();
        } catch (e: any) {
          formikHelpers.setSubmitting(false);
          toast.showError(e.message || ERROR_WENT_WRONG);
        }
        formikHelpers.setSubmitting(false);
      }}
    >
      {(formikProps: FormikProps<FormikValues>) => (
        <form onSubmit={formikProps.handleSubmit}>
          <div className="bg-white ">
            <div className="mx-auto bg-white ">
              <div className="border-b border-gray-300 dark:border-gray-700 py-5 bg-white ">
                <div className="w-11/12 mx-auto items-center pb-4">
                  <GenericHeader
                    heading={"Trip Contact"}
                    subHeading={
                      " Add or Edit a Trip Contact. ie Examples of contacts could beyour Head Office, Destination Management company, Hotel / venue, Emergency Services"
                    }
                  />
                </div>
              </div>
              <div className="w-2/3 mx-auto flex flex-col space-y-4 mt-4">
                <div className="w-3/4">
                  <Input
                    className="w-10/12"
                    label="Name"
                    helperText="Please enter the name of the Contact."
                    name={"name"}
                  />
                </div>
                <div className="w-1/2">
                  <Input
                    label="Contact Number"
                    helperText="Please enter the contact number of the contact."
                    name={"number"}
                  />
                </div>
                <div className={"w-1/2"}>
                  <Input
                    className=""
                    type="email"
                    label="Email Address"
                    helperText="Please enter the contact's email."
                    name={"email"}
                  />
                </div>

                <div className="w-1/2">
                  <Input
                    label="Contact Location"
                    helperText="Please enter the contact location of the contact."
                    name={"location"}
                  />
                </div>
                <div className="w-1/2">
                  <Input
                    label="Contact Hours"
                    helperText="Please enter the contact hours of the contact."
                    name={"hours"}
                  />
                </div>
              </div>
            </div>
            <hr className="h-0.5 mt-6 bg-gray-400 mx-auto" />

            <div className="w-11/12 mx-auto border-gray-300 flex justify-end dark:border-gray-700 py-5 bg-white ">
              {formikProps.isSubmitting && (
                <span className="mr-3">
                  <Loader type="BallTriangle" height={30} width={30} />
                </span>
              )}
              <div className="w-52 items-center justify-between flex pb-4">
                <BrandButton
                  className=" text-base font-semibold bg-gray-200 hover:bg-gray-400"
                  disabled={formikProps.isSubmitting}
                  type="button"
                  theme="basic"
                  onClick={modalHandler}
                  size={"xl"}
                >
                  Cancel
                </BrandButton>
                <BrandButton
                  className=" text-base font-semibold"
                  disabled={formikProps.isSubmitting}
                  type="submit"
                  theme="primary"
                  size={"xl"}
                >
                  Save
                </BrandButton>
              </div>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};
export default AddTripContact;
