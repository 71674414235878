import React, { FC } from "react";
import GenericHeader from "../../../../shared/Headers/GenericHeader";
import { FormikProps, FormikValues } from "formik";
import FormikBrandTextEditor from "../../../../shared/Input/FormikBrandTextEditor";

interface Props {
  formikProps: FormikProps<FormikValues>;
}

const TripItineraryStaffNotes: FC<Props> = ({ formikProps }) => {
  const mapStaffNotes = (data: string, formikProps: any) => {
    formikProps.setFieldValue("staff_notes", data);
  };

  return (
    <div className="bg-white ">
      <div className="container mx-auto  rounded">
        <div className="xl:w-full py-5 ">
          <GenericHeader
            heading={"Internal staff notes"}
            subHeading={"Only staff on trip will see this in app"}
          />
        </div>
        <hr className="h-0.5 mt-6 bg-gray-200 mx-auto w-full" />

        <div className="mx-10">
          <div className=" mx-auto xl:mx-0 flex flex-col space-y-10 mt-12">
            <div>
              <FormikBrandTextEditor
                placeholder="Staff notes"
                name={"staff_notes"}
                height={"500px"}
                labelClassName={"text-sm  text-gray-800 mb-2"}
              />
            </div>
          </div>
        </div>

        <hr className="h-0.5 mt-6 bg-gray-200 mx-auto w-full" />
      </div>
    </div>
  );
};
export default TripItineraryStaffNotes;
