export class NavigationService {
  private static _instance: NavigationService;

  private constructor() {
    console.log("[SILLY] Navigation Service Constructor");
  }

  static getInstance(): NavigationService {
    if (!this._instance) {
      this._instance = new NavigationService();
    }

    return this._instance;
  }
}
