import React from "react";
import { BrandButton } from "../../../../shared/Button/BrandButton";
import Loader from "react-loader-spinner";

interface ActionButtonsProps {
  formikProps: any;
  onCancelClick: () => void;
  onDeleteClick: () => void;
  disableDelete: boolean;
}

export const TripItineraryActionButton: React.FC<ActionButtonsProps> = ({
  formikProps,
  onCancelClick,
  onDeleteClick,
  disableDelete,
}) => {
  return (
    <>
      <div className={"flex justify-between items-center w-full"}>
        <BrandButton
          className=" text-base font-semibold"
          disabled={formikProps.isSubmitting || !disableDelete}
          type="button"
          theme="error"
          onClick={onDeleteClick}
          size={"xl"}
        >
          Delete
        </BrandButton>

        <div className="flex space-x-3 ">
          {formikProps.isSubmitting && (
            <span className="">
              <Loader type="BallTriangle" height={30} width={30} />
            </span>
          )}
          <BrandButton
            className=" text-base font-semibold"
            disabled={formikProps.isSubmitting}
            type="button"
            theme="basic"
            size={"xl"}
            onClick={onCancelClick}
          >
            Cancel
          </BrandButton>
          <BrandButton
            className=" text-base font-semibold"
            disabled={formikProps.isSubmitting}
            theme="primary"
            size={"xl"}
          >
            Submit
          </BrandButton>
        </div>
      </div>
    </>
  );
};
