import angleDoubleLeft from "./svg-icons/Angle-double-left.svg";
import angleDoubleRight from "./svg-icons/Angle-double-right.svg";

export enum SvgIconType {
  angleDoubleLeft = "angleDoubleLeft",
  angleDoubleRight = "angleDoubleRight",
}

export const svgList: { [key in keyof typeof SvgIconType]: string } = {
  angleDoubleLeft,
  angleDoubleRight,
};

export default svgList;
