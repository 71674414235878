import { random, times, pickBy, isArray } from "lodash";
import moment from "moment";

export const getUniqueId = () =>
  (
    Date.now().toString(36) + Math.random().toString(36).substr(2, 5)
  ).toUpperCase();

export class Utils {
  static getTrimmedString = (text: string, limit: number) => {
    if (text.length > limit) return text.substring(0, limit) + "...";
    return text;
  };

  static sanitizeObject = (data: any) => {
    return pickBy(data, (v: any) =>
      isArray(v) ? !!v.length : v !== null && v !== undefined && v !== ""
    );
  };

  static getRandomString = (length = 20) =>
    times(length, () => random(35).toString(36)).join("");

  static toTitleCase(input: string) {
    return input
      ?.toLowerCase()
      ?.split(/[_ ]/)
      ?.map((part) => part?.charAt(0)?.toUpperCase() + part?.slice(1))
      ?.join(" ");
  }

  static getDateforServer = (date: string) => {
    const xyz = {
      date: moment(date).format("DD MMM YYYY"),
      start_time: moment(date).format("HH:mm"),
    };
    return moment(
      xyz.date + " " + xyz.start_time,
      "DD MMM YYYY HH:mm"
    ).toDate();
  };

  static getDateAppendWithTime = (date: Date, time: string) => {
    // console.log(date, time)
    const d = moment(
      moment(date).utc().format("YYYY-MM-DD").toString() +
        " " +
        moment().utc().format("HH:mm").toString()
    );
    // console.log(d)
    return d;
  };

  static getDateRange(startDate: string, endDate: string) {
    const fromDate = moment(startDate);
    const toDate = moment(endDate);
    const diff = toDate.diff(fromDate, "days");
    const range = [];
    for (let i = 0; i <= diff; i++) {
      range.push(moment(startDate).add(i, "days"));
    }
    return range;
  }
}
