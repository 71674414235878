import { FormikProps, FormikValues, useFormik, Formik } from "formik";
import React, { FC, useState } from "react";
import * as yup from "yup";
import { BrandButton } from "../../../shared/Button/BrandButton";
import Input, { InputWithoutFormik } from "../../../shared/Input/Input";
import FilePicker from "../../../shared/FilePicker/FilePicker";
import { ChipWithCrossIcon } from "../../../shared/ChipWithCrossIcon/ChipWithCrossIcon";
import { Utils } from "../../../Utils/Utils";
import { map } from "lodash";
import { AppContext } from "../../../App.context";
import { UploadStatus } from "../../../constants/enums/UploadStatus";
import { FileService } from "../../../store/services/api-services/FileService";
import { ImageModel } from "../../../store/models/ImageModel";
import { observer } from "mobx-react";
import InputHelper from "../../../shared/Input/Inputhelper/InputHelper";

interface Props {
  modalHandler: () => void;
  editData?: ImageModel;
}

const AddImageModal: FC<Props> = ({ modalHandler, editData }) => {
  const [inputTag, setInputTag] = useState<string>();
  const [selectedTags, setSelectedTags] = useState<Array<string>>(
    editData?.tags || []
  );
  const { store, toast } = AppContext;
  const businessId = store.userStore.loggedInUser?.memberships?.items?.[0]?.id;

  const isUpdate = !!editData;

  const [uploadedUrls, setUploadedUrls] = useState<{
    url: string;
    uploading: UploadStatus;
  }>({
    url: "",
    uploading: UploadStatus.NOT_STARTED,
  });

  const [image, setImage] = useState<{ preview: string }>({
    preview: "",
  });

  const handleDeleteTags = (tag: string): void => {
    setSelectedTags(
      selectedTags.filter((item) => item.toLowerCase() !== tag.toLowerCase())
    );
  };

  const handleAddTags = (tag: string): void => {
    if (
      selectedTags?.some((value) => value.toLowerCase() === tag.toLowerCase())
    ) {
      return;
    } else {
      if (!!tag) setSelectedTags([...selectedTags, tag]);
    }
  };
  const onKeyDown = (e: any): void => {
    if (e.key == "Enter" || e.key === ",") {
      handleAddTags(Utils.toTitleCase(inputTag?.toUpperCase().trim() || ""));
      setInputTag("");
    } else {
      setInputTag(e.target.value.toString().replace(",", ""));
    }
  };

  const handleUploadProfilePic = async (file: File, formikProps: any) => {
    if (file) {
      try {
        toast.showInfo("Image Upload Start");
        formikProps.setSubmitting(true);
        setUploadedUrls((prev) => ({
          ...prev,
          uploading: UploadStatus.IN_PROGRESS,
        }));
        const res = await FileService.uploadFile(file);
        setUploadedUrls((prev) => ({
          ...prev,

          url: res.url || "",
        }));
        formikProps.setFieldValue("url", res.url);
        setUploadedUrls((prev) => ({ ...prev, uploading: UploadStatus.DONE }));
        toast.showSuccess("Image Uploaded Successfully");
        formikProps.setSubmitting(false);
      } catch (e: any) {
        toast.showError("Image Upload Failed");
        formikProps.setSubmitting(false);
        setUploadedUrls((prev) => ({
          ...prev,
          uploading: UploadStatus.NOT_STARTED,
        }));
      }
    } else {
      setUploadedUrls((prev) => ({ url: "", uploading: UploadStatus.DONE }));
    }
  };

  const handleImagePreview = (file: File | undefined, formikProps: any) => {
    setImage({
      preview: file ? URL.createObjectURL(file) : "",
    });

    if (file) {
      handleUploadProfilePic(file, formikProps);
    }
  };

  return (
    <Formik
      initialValues={{
        name: editData?.name || "",
        url: editData?.url || "",
      }}
      validationSchema={yup.object().shape({
        name: yup.string().required("Image Name is Required"),
        url: yup.string().required("Image is Required"),
      })}
      onSubmit={async (data, formikHelpers) => {
        formikHelpers.setSubmitting(true);

        try {
          if (isUpdate) {
            await store.imageStore.updateImage(businessId!, {
              _id: editData?.id,
              tags: selectedTags,
              name: data.name,
              url: data.url,
            });
          } else {
            await store.imageStore.addImage({
              business_id: businessId!,
              tags: selectedTags,
              name: data.name,
              url: data.url,
            });
          }

          modalHandler();
          formikHelpers.setSubmitting(false);
        } catch (e: any) {
          formikHelpers.setSubmitting(false);
        }
      }}
    >
      {(formikProps: FormikProps<FormikValues>) => (
        <form onSubmit={formikProps.handleSubmit}>
          <div className="bg-white">
            <div className="mx-auto bg-white rounded ">
              <div className="py-5 bg-white ">
                <div className="w-11/12 mx-auto items-center pb-4">
                  <div className=" items-start  flex flex-col">
                    <h1 className="text-5xl tracking-tight font-bold text-gray-800 sm:text-5xl md:text-5xl py-10">
                      <span className="block xl:inline">Add / Edit </span>
                      <span className="block text-indigo-600 xl:inline">
                        Image{" "}
                      </span>
                    </h1>
                    <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                      Images can be used for Trips and Itinerary Activities
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-2/3 mx-auto flex flex-col space-y-4 mt-4 ">
                <div className={""}>
                  <span className={`mb-2 block text-sm  text-gray-800`}>
                    Upload Image
                  </span>
                  <FilePicker
                    handleFileUpdate={(file) =>
                      handleImagePreview(file, formikProps)
                    }
                    uploading={
                      uploadedUrls.uploading === UploadStatus.IN_PROGRESS
                    }
                    src={image.preview || editData?.url || ""}
                    disabled={formikProps.isSubmitting}
                    btnText="Upload Image"
                    fileTypes={"image/*"}
                    btnType="secondary"
                    name={"name"}
                  />
                  {formikProps.errors["url"] && formikProps.touched["url"] && (
                    <InputHelper
                      type={"error"}
                      text={(formikProps.errors["url"] as string) || ""}
                    />
                  )}
                </div>

                <div className="w-1/2">
                  <Input
                    className=""
                    label="Image Name"
                    helperText="Please enter the name of the Image"
                    name={"name"}
                  />
                </div>
                <div className="w-1/2">
                  <InputWithoutFormik
                    label="Image Tag"
                    onChange={onKeyDown}
                    helperText="Please Enter or comma to add new tag"
                    value={inputTag}
                    onKeyPress={onKeyDown}
                    onKeyDown={onKeyDown}
                  />
                </div>

                <div className="flex flex-wrap">
                  {map(selectedTags, (item) => (
                    <>
                      <div className="pr-2 pt-2">
                        <ChipWithCrossIcon
                          tagName={item}
                          onClickHandler={() => handleDeleteTags(item)}
                        />
                      </div>
                    </>
                  ))}
                </div>
              </div>
            </div>
            <hr className="h-0.5 mt-6 bg-gray-400 mx-auto" />

            <div className="w-11/12 mx-auto border-gray-300 flex justify-end dark:border-gray-700 py-5 bg-white ">
              <div className="w-52 items-center justify-between flex pb-4">
                <BrandButton
                  className=" text-base font-semibold bg-gray-200 hover:bg-gray-400"
                  disabled={formikProps.isSubmitting}
                  type="button"
                  theme="basic"
                  size={"xl"}
                  onClick={modalHandler}
                >
                  Cancel
                </BrandButton>
                <BrandButton
                  className=" text-base font-semibold"
                  disabled={formikProps.isSubmitting}
                  type="button"
                  theme="primary"
                  size={"xl"}
                  onClick={formikProps.handleSubmit}
                >
                  Save
                </BrandButton>
              </div>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};
export default observer(AddImageModal);
