import { EntityBaseModel, EntityStore } from "@devslane/mobx-entity-manager";
import { RootStore } from "./RootStore";

export abstract class ChildStore<
  M extends EntityBaseModel
> extends EntityStore<M> {
  protected constructor(protected rootStore: RootStore) {
    super();
  }

  abstract reset(): void;
}
