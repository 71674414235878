import React, { FunctionComponent, useEffect, useState } from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import NotFoundRoute from "../../guards/NotFoundRoutes";
import PublicRoute from "../../guards/PublicRoute";
import Register from "../Register/Register";
import AuthRoute from "../Auth/Auth";
import Dashboard from "../Dashboard/Dashboard";
import Login from "../Login/Login";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import googleAPIServices from "../../store/services/GoogleAPIServices";
import useScript from "../../Hooks/useScript";
import { AppContext } from "../../App.context";
import Loader from "react-loader-spinner";
import { RouteConfig } from "../../constants/RouteConfig";
import ForgotPassword from "../Dashboard/ResetPassword/ForgotPassword";
import ResetPassword from "../Dashboard/ResetPassword/ResetPassword";
import { AxiosError } from "axios";
import { ErrorCode } from "../../classes/SafeTravelsBaseError";
import Home from "../Dashboard/Home/Home";
import TermsAndConditions from "../Terms/TermsAndConditions";
import PrivacyPolicy from "../Terms/PrivacyPolicy";
import Pricing from "../Dashboard/Pricing/Pricing";
import Affiliates from "../Dashboard/Affiliates/Affiliates";
import WhiteLabel from "../Dashboard/WhiteLabel/WhiteLabel";
import VerifyEmail from "../VerifyEmail/VerifyEmail";

const App: FunctionComponent = () => {
  const { status, scriptLoaded } = useScript(googleAPIServices.scriptSRC);
  const [loading, setLoading] = useState(false);
  const { store, storage } = AppContext;

  useEffect(() => {
    const refreshToken = async () => {
      setLoading(true);

      try {
        const token = await storage.getAuthToken();

        if ((token || "").length) {
          store.userStore
            .me()
            .then((res) => {
              setLoading(false);
            })
            .catch(() => {
              setLoading(false);
            });
        } else {
          setLoading(false);
        }
      } catch (e: any) {
        setLoading(false);
      }
    };
    refreshToken();
  }, []);

  const globalAxiosErrorHandler = (ev: CustomEvent): void => {
    const error: AxiosError = ev.detail;
    console.error("[AXIOS ERROR]", { ...error });

    //checking for 401 status
    if (error?.response?.status === ErrorCode.UNAUTHORIZED) {
      store.logout();
      storage.reset();
      window.location.reload();
    }
  };

  useEffect(() => {
    //for handling  axios error
    document.addEventListener(
      "globalAxiosError",
      globalAxiosErrorHandler as EventListener
    );

    return () => {
      document.removeEventListener(
        "globalAxiosError",
        globalAxiosErrorHandler as EventListener
      );
    };
  }, []);

  useEffect(() => {
    if (status === "ready") {
      googleAPIServices.initializeAutoCompleteService();
    }
  }, [status]);

  if (status === "loading" || loading || !scriptLoaded) {
    return (
      <div className="h-screen w-screen flex items-center justify-center">
        <Loader type="BallTriangle" height={30} width={30} />
      </div>
    );
  }
  return (
    <BrowserRouter>
      <ToastContainer
        hideProgressBar
        pauseOnFocusLoss={false}
        toastClassName={
          "relative flex p-2 min-h-10 rounded-md justify-between overflow-hidden cursor-pointer font-medium"
        }
      />
      <Switch>
        <PublicRoute path={RouteConfig.login._ROOT} component={Login} exact />
        <PublicRoute
          path={RouteConfig.signUp._ROOT}
          component={Register}
          exact
        />
        <PublicRoute
          path={RouteConfig.signUp.staffSignup}
          component={Register}
          exact
        />
        <PublicRoute
          path={RouteConfig.resetPassword.forgotPassword}
          component={ForgotPassword}
          exact
        />
        <PublicRoute
          path={RouteConfig.resetPassword._ROOT}
          component={ResetPassword}
          exact
        />
        <PublicRoute
          path={RouteConfig.verify._ROOT}
          component={VerifyEmail}
          exact
        />
        <PublicRoute path={RouteConfig.home._ROOT} component={Home} exact />
        <PublicRoute
          path={RouteConfig.termsAndConditions._ROOT}
          component={TermsAndConditions}
          exact
        />
        <PublicRoute
          path={RouteConfig.privacyPolicy._ROOT}
          component={PrivacyPolicy}
          exact
        />
        <PublicRoute
          path={RouteConfig.pricing._ROOT}
          component={Pricing}
          exact
        />
        <PublicRoute
          path={RouteConfig.affiliates._ROOT}
          component={Affiliates}
          exact
        />
        <PublicRoute
          path={RouteConfig.whiteLabel._ROOT}
          component={WhiteLabel}
          exact
        />
        <AuthRoute path={"/"} component={Dashboard} />
        <NotFoundRoute />
      </Switch>
    </BrowserRouter>
  );
};
export default App;
