import React, { useEffect, useState } from "react";
import TripsHeaderNavigation, {
  TripsHeaderNavigationItem,
} from "../TripsHeaderNavigation";
import TripItineraryActivityDescription from "./TripItineraryActivityDescription";
import TripItineraryImages from "./TripItineraryImages";
import TripActivityGuests from "./TripActivityGuests";
import TripItineraryDescription from "./TripItineraryDescription";
import TripItineraryStaffNotes from "./TripItineraryStaffNotes";
import * as yup from "yup";
import { Formik, FormikProps, FormikValues } from "formik";
import { AppContext } from "../../../../App.context";
import moment from "moment";
import { Utils } from "../../../../Utils/Utils";
import { ActivityModal } from "../../../../store/models/ActivityModal";
import { observer } from "mobx-react";
import { TripItineraryActionButton } from "./TripItineraryActionButton";
import { EntityIdentifier } from "@devslane/mobx-entity-manager";
import { ERROR_WENT_WRONG } from "../../../../constants/message";
import { unset } from "lodash";
import Loader from "react-loader-spinner";

enum TripsOverviewSteps {
  ACTIVITY_DESCRIPTION = "activity_description",
  DESCRIPTION = "description",
  IMAGES = "images",
  NOTES = "notes",
  GUESTS = "guests",

}

const tripOverviewItems: TripsHeaderNavigationItem[] = [
  {
    label: "Activity Description",
    value: TripsOverviewSteps.ACTIVITY_DESCRIPTION,
  },
  {
    label: "Description",
    value: TripsOverviewSteps.DESCRIPTION,
  },
  {
    label: "Images",
    value: TripsOverviewSteps.IMAGES,
  },
  {
    label: "Internal Staff Notes",
    value: TripsOverviewSteps.NOTES,
  },
  {
    label: "Activity Guests",
    value: TripsOverviewSteps.GUESTS,
  },
];

interface Props {
  handleClose: () => void;
  selectedEvent?: any;
  eventForEdit?: ActivityModal;
}

const TripItineraryActivityAdd: React.FC<Props> = ({
  handleClose,
  selectedEvent,
  eventForEdit,
}) => {
  const { store, toast } = AppContext;
  const businessId =
    store?.userStore?.loggedInUser?.memberships?.items?.[0]?.id;
  const currentTripId = new URLSearchParams(location.search).get("id");
  const [selectedImages, setSelectedImages] = useState<
    Array<{ id: EntityIdentifier; url: string }>
  >(eventForEdit?.images || []);
  const imageListLoading = store.imageStore.imagesList.loading;
  const imageList = store.imageStore.imagesList.items?.map((image) => ({
    id: image.id,
    url: image.url,
  }));
  const favouritesActivityLoading =
    store?.tripStore?.favoriteTripActivities?.loading;

  useEffect(() => {
    store.imageStore.loadImages(businessId!);
  }, []);

  useEffect(() => {
    if (!eventForEdit) {
      store.tripStore.loadFavouritesTripActivities(businessId!);
    }
  }, [eventForEdit]);

  const handleAddRemoveImage = (
    value: { id: EntityIdentifier; url: string },
    type: "add" | "remove"
  ) => {
    if (type === "add") {
      setSelectedImages([...selectedImages, value]);
      return;
    } else {
      setSelectedImages(
        selectedImages.filter((image) => image.id !== value.id)
      );
      return;
    }
  };

  const [currentStep, setCurrentStep] = useState<string>(
    TripsOverviewSteps.ACTIVITY_DESCRIPTION
  );

  const handleStepChange = (tab: TripsOverviewSteps) => {
    setCurrentStep(tab);
  };
  const handleDelete = async (id: EntityIdentifier) => {
    try {
      if (confirm("Are you sure you want to delete this activity ?")) {
        await store.tripStore.deleteTripActivity(
          currentTripId as any,
          businessId!,
          id
        );
        toast.showSuccess("Activity Deleted Successfully");
        handleClose();
      }
    } catch (e: any) {
      toast.showError(e.message || ERROR_WENT_WRONG);
    }
  };

  // if(eventForEdit?.start){
  //   eventForEdit.start = moment
  // }
  const eventStartDate = eventForEdit?.start
    ? new Date(eventForEdit?.start as any).toISOString() ||
      selectedEvent?.start.toISOString() ||
      new Date()
    : selectedEvent?.start.toISOString() || new Date();

  //   console.log(eventForEdit?.start)
  // console.log(eventStartDate)
  const eventEndDate = eventForEdit?.end
    ? new Date(eventForEdit?.end as any).toISOString() ||
      selectedEvent?.start.toISOString() ||
      new Date()
    : selectedEvent?.start.toISOString() || new Date();

  const startTime = eventForEdit
    ? moment(eventStartDate).utc().format("HH:mm")
    : moment().format("HH:mm");

  const endTime = eventForEdit
    ? moment(eventForEdit?.end).utc().format("HH:mm")
    : moment().add(1, "h").format("HH:mm");

  const toggleFavorite = async (values: any) => {
    if (eventForEdit) {
      try {
        await store.tripStore.updateTripActivity(
          currentTripId as any,
          businessId!,
          {
            ...values,
            _id: eventForEdit.id,
            library: !values.library,
            images: selectedImages,
          }
        );
        toast.showSuccess(
          !values?.library ? "Added to Library" : "Removed from Library"
        );
      } catch (e) {
        toast.showError(ERROR_WENT_WRONG);
      }
    }
  };

  useEffect(() => {
    const a = eventForEdit;
    console.log(a, "--------------->");
  }, [eventForEdit])

  return (
    <Formik
      enableReinitialize
      initialValues={{
        title: eventForEdit?.title || "",
        start:
          Utils.getDateAppendWithTime(eventStartDate, startTime).format(
            "YYYY-MM-DD"
          ) || "",
        end:
          Utils.getDateAppendWithTime(eventEndDate, endTime).format(
            "YYYY-MM-DD"
          ) || "",
        description: eventForEdit?.description || "",
        staff_notes: eventForEdit?.staff_notes || "",
        end_location: eventForEdit?.end_location
          ? {
              address: eventForEdit?.end_location?.address,
              name: eventForEdit?.end_location?.name,
              latitude: eventForEdit?.end_location?.latitude,
              longitude: eventForEdit?.end_location?.longitude,
              notes: eventForEdit?.end_location?.notes,
            }
          : {},
        start_location: eventForEdit?.end_location
          ? {
              address: eventForEdit?.start_location?.address,
              name: eventForEdit?.start_location?.name,
              latitude: eventForEdit?.start_location?.latitude,
              longitude: eventForEdit?.start_location?.longitude,
              notes: eventForEdit?.start_location?.notes,
            }
          : {},
        start_time: startTime,
        finish_time: endTime,
        button: eventForEdit?.button
          ? {
              button_text: eventForEdit?.button?.button_text,
              button_url: eventForEdit?.button?.button_url,
            }
          : {},
        library: eventForEdit?.library ?? false,
        private_guests: eventForEdit?.private_guests
      }}
      validationSchema={yup.object().shape({
        title: yup.string().trim().required("Activity Name Is Required"),
        staff_notes: yup.string(),
        description: yup.string(),

        start_location: yup.object().shape({
          address: yup.string().required(),
          name: yup.string(),
          latitude: yup.string(),
          longitude: yup.string(),
          notes: yup.string(),
        }),
        end_location: yup.object().shape({
          address: yup.string().required(),
          name: yup.string(),
          latitude: yup.string(),
          longitude: yup.string(),
          notes: yup.string(),
        }),
        button: yup.object().shape({
          button_text: yup.string(),
          button_url: yup.string(),
        }),
      })}
      onSubmit={async (data, formikHelpers) => {
        formikHelpers.setSubmitting(true);

        const mappedValues = { ...data };
        // mappedValues["start"] = moment(
        //   new Date(`${mappedValues.start} ${mappedValues.start_time}:00`)
        // )
        //   .toDate()
        //   .toUTCString();

        mappedValues[
          "start"
        ] = `${mappedValues.start}T${mappedValues.start_time}:00.000+00:00`;

        mappedValues[
          "end"
        ] = `${mappedValues.end}T${mappedValues.finish_time}:00.000+00:00`;

        if (
          moment(mappedValues.start).unix() > moment(mappedValues.end).unix()
        ) {
          toast.showError(
            "End Date Time Must Be Later Then The Start Date Time"
          );
          formikHelpers.setSubmitting(false);

          return;
        }
        unset(mappedValues, "finish_time");
        unset(mappedValues, "start_time");
        unset(mappedValues, "selectedLibrary");

        try {
          if (eventForEdit) {
            await store.tripStore.updateTripActivity(
              currentTripId as any,
              businessId!,
              {
                ...mappedValues,
                _id: eventForEdit.id,
                images: selectedImages,
              }
            );
          } else {
            await store.tripStore.addTripActivity(
              currentTripId as any,
              businessId!,
              {
                ...mappedValues,
                images: selectedImages,
              }
            );
          }
          formikHelpers.setSubmitting(false);
          toast.showSuccess("Activity Save Successfully");
          handleClose();
        } catch (e: any) {
          formikHelpers.setSubmitting(false);
          toast.showError(e.message || ERROR_WENT_WRONG);
        }
      }}
    >
      {(formikProps: FormikProps<FormikValues>) => (
        <form onSubmit={formikProps.handleSubmit}>
          <div className="flex flex-col  w-full flex-row  w-full bg-white   h-full">
            <div className={""}>
              <TripsHeaderNavigation
                handleClose={handleClose}
                currentSelectedStep={currentStep}
                headerItems={tripOverviewItems}
                handleChange={handleStepChange}
              />
            </div>

            <div className={"bg-white pb-8"}>
              {currentStep === TripsOverviewSteps.ACTIVITY_DESCRIPTION && (
                <>
                  {favouritesActivityLoading ? (
                    <div className={"flex justify-center mx-auto my-20"}>
                      <Loader type="BallTriangle" height={30} width={30} />
                    </div>
                  ) : (
                    <TripItineraryActivityDescription
                      formikProps={formikProps}
                      isEditing={!!eventForEdit}
                      onFavouriteToggle={toggleFavorite}
                      favouritesActivities={
                        store?.tripStore?.favoriteTripActivities?.items
                      }
                    />
                  )}
                </>
              )}
              {currentStep === TripsOverviewSteps.DESCRIPTION && (
                <TripItineraryDescription formikProps={formikProps} />
              )}

              {currentStep === TripsOverviewSteps.IMAGES && (
                <TripItineraryImages
                  selectedImages={selectedImages}
                  handleAddRemoveImage={handleAddRemoveImage}
                  loading={imageListLoading}
                  images={imageList ?? []}
                />
              )}

              {currentStep === TripsOverviewSteps.NOTES && (
                <TripItineraryStaffNotes formikProps={formikProps} />
              )}
              {currentStep === TripsOverviewSteps.GUESTS && (
                <TripActivityGuests formikProps={formikProps} eventForEdit={eventForEdit!} />
              )}
              <div className={"mt-8 px-16"}>
                <TripItineraryActionButton
                  formikProps={formikProps}
                  onCancelClick={handleClose}
                  disableDelete={!!eventForEdit}
                  onDeleteClick={() => handleDelete(eventForEdit!.id)}
                />
              </div>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default observer(TripItineraryActivityAdd);
