import { action, computed, observable, runInAction } from "mobx";
import { ChildStore } from "../ChildStore";
import { Context } from "../Context";
import { RootStore } from "../RootStore";
import {
  EntityIdentifier,
  ModelItem,
  ModelJson,
  ModelList,
} from "@devslane/mobx-entity-manager";
import { map } from "lodash";
import { TripModel } from "store/models/TripModel";
import { tripService } from "store/services/api-services/TripService";
import { ActivityModal } from "../models/ActivityModal";
import { ContactModel } from "store/models/ContactModal";

export class TripStore extends ChildStore<TripModel> {
  constructor(rootStore: RootStore) {
    super(rootStore);
    TripModel._store = this;
  }
  @observable tripList: ModelList<TripModel> = new ModelList<TripModel>(
    TripModel
  );
  @observable tripContactList: ModelList<ContactModel> =
    new ModelList<ContactModel>(ContactModel);
  @observable templateTripList: ModelList<TripModel> = new ModelList<TripModel>(
    TripModel
  );
  @observable currentTripActivities: ModelList<ActivityModal> =
    new ModelList<ActivityModal>(ActivityModal);

  @observable trip: ModelItem<TripModel> = new ModelItem<TripModel>(TripModel);

  @observable favoriteTripActivities: ModelList<ActivityModal> =
    new ModelList<ActivityModal>(ActivityModal);

  @action
  async loadTrip(business_id: EntityIdentifier): Promise<any> {
    this.tripList.setLoading(true);
    try {
      return await tripService.getTrips(business_id).then((res) => {
        this.tripList.deserialize(
          map(res.data, (trip) => trip.toJSON()) as ModelJson[]
        );
        this.tripList.setLoading(false);
        return res;
      });
    } catch (e) {
      this.tripList.setLoading(false);
      throw e;
    }
  }
  @action
  async loadTripContacts(
    trip_id: EntityIdentifier,
    business_id: EntityIdentifier
  ): Promise<any> {
    this.tripContactList.setLoading(true);
    try {
      return await tripService
        .getTripContacts(trip_id, business_id)
        .then((res) => {
          this.tripContactList.deserialize(
            map(res.data, (trip) => trip.toJSON()) as ModelJson[]
          );
          this.tripContactList.setLoading(false);
          return res;
        });
    } catch (e) {
      this.tripContactList.setLoading(false);
      throw e;
    }
  }

  @action
  async loadTripTemplate(business_id: EntityIdentifier): Promise<any> {
    this.templateTripList.setLoading(true);
    try {
      return await tripService.getTripTemplatess(business_id).then((res) => {
        this.templateTripList.deserialize(
          map(res.data, (trip) => trip.toJSON()) as ModelJson[]
        );
        this.templateTripList.setLoading(false);
        return res;
      });
    } catch (e) {
      this.templateTripList.setLoading(false);
      throw e;
    }
  }

  @action
  async addTrip(data: {
    business_id: EntityIdentifier;
    overview: {
      name: string;
      location: string;
      start_date: string;
    };
  }): Promise<any> {
    try {
      return await tripService.createTrip(data).then((res) => {
        const tripResponse = res.data.data;
        const trip = {
          id: tripResponse._id,
          name: tripResponse.overview.name,
          trip_code: tripResponse.overview.trip_code,
          location: tripResponse.overview.location,
          start_date: tripResponse.overview.start_date,
          end_date: tripResponse.overview.end_date,
          ...tripResponse,
        };
        const tripData = TripModel.fromJson(trip);
        this.tripList.appendItem(tripData as TripModel);
      });
    } catch (e) {
      throw e;
    }
  }
  @action
  async createFromTemplate(data: {
    business_id: EntityIdentifier;
    id: string;
    overview: {
      name: string;
      location: string;
      start_date: string;
    };
  }): Promise<any> {
    try {
      return await tripService.createFromTemplate(data).then((res) => {
        const tripResponse = res.data.data;
        const trip = {
          id: tripResponse._id,
          name: tripResponse.overview.name,
          trip_code: tripResponse.overview.trip_code,
          location: tripResponse.overview.location,
          start_date: tripResponse.overview.start_date,
          end_date: tripResponse.overview.end_date,
          ...tripResponse,
        };
        const tripData = TripModel.fromJson(trip);
        this.tripList.appendItem(tripData as TripModel);
      });
    } catch (e) {
      throw e;
    }
  }

  @action
  async makeTemplate(business_id: EntityIdentifier, data: any): Promise<any> {
    return await tripService.makeTemplate(business_id, data).then((res) => {
      const allItems = this.tripList.items.map((trip: TripModel) => {
        return trip.toJSON();
      });
      const staffs = allItems.filter((session) => data.id !== session.id);

      this.tripList.deserialize(staffs as ModelJson[]);
      const tripResponse = res.data.data;
      const trip = {
        id: tripResponse._id,
        name: tripResponse.overview.name,
        trip_code: tripResponse.overview.trip_code,
        location: tripResponse.overview.location,
        ...tripResponse,
      };
      const tripData = TripModel.fromJson(trip);
      this.templateTripList.appendItem(tripData as TripModel);
      this.trip.setLoading(false);
      return res;
    });
  }

  @action
  async getTrip(
    business_id: EntityIdentifier,
    trip_id: EntityIdentifier
  ): Promise<any> {
    this.trip.setLoading(true);
    try {
      return await tripService.getTrip(business_id, trip_id).then((res) => {
        this.trip.deserialize(res.toJSON() as ModelJson);
        this.trip.setLoading(false);
        return res;
      });
    } catch (e) {
      this.trip.setLoading(false);
      throw e;
    }
  }

  @action
  async deleteTrip(data: {
    business_id: EntityIdentifier;
    trip_id: EntityIdentifier;
  }): Promise<any> {
    try {
      return await tripService.deleteTrip(data).then((res) => {
        const allItems = this.tripList.items.map((trip: TripModel) => {
          return trip.toJSON();
        });
        const staffs = allItems.filter(
          (session) => data.trip_id !== session.id
        );
        this.tripList.deserialize(staffs as ModelJson[]);

        return res.message;
      });
    } catch (e) {
      throw e;
    }
  }

  @action
  async loadCurrentTripActivities(
    trip_id: EntityIdentifier,
    business_id: EntityIdentifier
  ): Promise<any> {
    this.currentTripActivities.setLoading(true);
    try {
      return await tripService
        .getTripActivity(business_id, trip_id)
        .then((res) => {
          this.currentTripActivities.deserialize(
            map(res.activities, (trip) => trip.toJSON()) as ModelJson[]
          );
          this.currentTripActivities.setLoading(false);
          return res;
        });
    } catch (e) {
      this.currentTripActivities.setLoading(false);
    }
  }

  @action
  async addTripActivity(
    trip_id: EntityIdentifier,
    business_id: EntityIdentifier,
    data: {
      title: string;
      start: string;
      end: string;
      description: string;
      staff_notes: string;
      start_location: any;
      end_location: any;
      images: Array<{ id: EntityIdentifier; url: string }>;
      button: any;
    }
  ): Promise<any> {
    return await tripService
      .createTripActivity(business_id, trip_id, data)
      .then((res) => {
        this.currentTripActivities.deserialize(
          map(res.activities, (trip) => trip.toJSON()) as ModelJson[]
        );
        this.currentTripActivities.setLoading(false);
        return res;
      })
      .catch((err) => {
        throw err;
      });
  }

  @action
  async updateTripActivity(
    trip_id: EntityIdentifier,
    business_id: EntityIdentifier,
    data: {
      _id: EntityIdentifier;
      title: string;
      start: string;
      end: string;
      description: string;
      staff_notes: string;
      start_location: any;
      end_location: any;
      button: any;
      images: Array<{ id: EntityIdentifier; url: string }>;
      library?: boolean;
      private_guests?: [{
        guest_id: string;
        first_name: string;
        last_name: string;
        email: string;
      }];
    }
  ): Promise<any> {
    return await tripService
      .updateTripActivity(business_id, trip_id, data)
      .then((res) => {
        this.currentTripActivities.deserialize(
          map(res.activities, (trip) => trip.toJSON()) as ModelJson[]
        );
        this.currentTripActivities.setLoading(false);
        return res;
      })
      .catch((err) => {
        throw err;
      });
  }
  @action
  async deleteTripActivity(
    trip_id: EntityIdentifier,
    business_id: EntityIdentifier,
    activity_id: EntityIdentifier
  ): Promise<any> {
    return await tripService
      .deleteTripActivity(business_id, trip_id, activity_id)
      .then((res) => {
        this.currentTripActivities.deserialize(
          map(res.activities, (trip) => trip.toJSON()) as ModelJson[]
        );
        this.currentTripActivities.setLoading(false);
        return res;
      })
      .catch((err) => {
        throw err;
      });
  }

  @action
  async deleteTripContact(
    trip_id: EntityIdentifier,
    business_id: EntityIdentifier,
    contact_id: EntityIdentifier
  ): Promise<any> {
    try {
      return await tripService
        .deleteTripContact(trip_id, business_id, contact_id)
        .then((res) => {
          this.tripContactList.deserialize(
            map(res.data, (trip) => trip.toJSON()) as ModelJson[]
          );
          return res.message;
        });
    } catch (e) {
      throw e;
    }
  }

  @action
  async addTripContact(
    trip_id: EntityIdentifier,
    business_id: EntityIdentifier,
    data: ContactModel
  ): Promise<any> {
    return await tripService
      .createTripContact(trip_id, business_id, data)
      .then((res) => {
        this.tripContactList.deserialize(
          map(res.data, (section) => section.toJSON()) as ModelJson[]
        );
        this.tripContactList.setLoading(false);
        return res.data;
      })
      .catch((err) => {
        throw err;
      });
  }

  @action
  async updateTripContact(
    trip_id: EntityIdentifier,
    business_id: EntityIdentifier,
    contact_id: EntityIdentifier,
    data: any
  ): Promise<any> {
    return await tripService
      .updateTripContact(trip_id, business_id, contact_id, data)
      .then((res) => {
        this.tripContactList.deserialize(
          map(res.data, (trip) => trip.toJSON()) as ModelJson[]
        );
        return res.message;
      });
  }

  @action
  async updateTrip(
    trip_id: EntityIdentifier,
    business_id: EntityIdentifier,
    data: any
  ): Promise<any> {
    return await tripService
      .updateTrip(trip_id, business_id, data)
      .then((res) => {
        this.trip.deserialize(res.toJSON() as ModelJson);

        this.trip.setLoading(false);
        return res;
      });
  }

  @action
  async updateTripChatSettings(
    trip_id: EntityIdentifier,
    business_id: EntityIdentifier,
    data: any
  ): Promise<any> {
    return await tripService
      .updateTripChatSettings(trip_id, business_id, data)
      .then((res) => {
        this.trip.deserialize(res.toJSON() as ModelJson);

        this.trip.setLoading(false);
        return res;
      });
  }

  @action
  async loadFavouritesTripActivities(
    business_id: EntityIdentifier
  ): Promise<any> {
    this.favoriteTripActivities.setLoading(true);
    try {
      return await tripService
        .getFavouritesTripActivity(business_id)
        .then((res) => {
          this.favoriteTripActivities.deserialize(
            map(res, (trip) => trip.toJSON()) as ModelJson[]
          );
          this.favoriteTripActivities.setLoading(false);
          return res;
        });
    } catch (e) {
      this.favoriteTripActivities.setLoading(false);
    }
  }

  reset() {
    this.entities.clear();
    Context.storage.reset();
  }
}
