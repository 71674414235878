import classNames from "classnames";
import React, { CSSProperties, FocusEvent, FunctionComponent } from "react";
import InputHelper from "../Input/Inputhelper/InputHelper";
import SvgIconComponent from "../svgIconComponent/svgIcon.component";
import withFormikContext from "../withFormikContext";
import Icon from "../Icon/Icon";
import { FaExclamationCircle } from "react-icons/fa";
import useId from "../../Hooks/useId";
import { ThemeType } from "../../constants/enums/ThemeType";

interface InputProps {
  name?: string;
  onChange?: (event: any) => void;
  onBlur?: (event: FocusEvent) => void;
  onClick?: (event: any) => void;
  placeholder?: string;
  error?: string;
  label?: string;
  helperText?: string;
  type?:
    | "email"
    | "password"
    | "text"
    | "date"
    | "radio"
    | "file"
    | "number"
    | "time";
  className?: string;
  leadingIcon?: any;
  trailingIcon?: any;
  trailingIconClass?: string;
  trailingSvgIcon?: any;
  leadingSvgIcon?: any;
  placeholderColor?: string;
  min?: number;
  max?: string;
  labelClassName?: string;
  style?: CSSProperties;
  disabled?: boolean;
  tooltip?: string;
  maxLength?: number;
  onKeyPress?: (event: any) => void;
  onKeyDown?: (event: any) => void;
  labelColor?: string;
  theme?: ThemeType;
}

const Input: FunctionComponent<InputProps & Partial<any>> = ({
  name,
  type,
  value,
  onBlur,
  onChange,
  onClick,
  placeholder,
  label,
  helperText,
  leadingIcon,
  trailingIcon,
  error,
  leadingSvgIcon,
  trailingSvgIcon,
  className,
  placeholderColor,
  min,
  max,
  labelClassName,
  style,
  disabled,
  tooltip,
  maxLength,
  onKeyPress,
  labelColor,
  theme,
  onKeyDown,
}) => {
  const id = useId();

  const classForTheme: any = {
    primary:
      "border border-gray-300  px-3 py-2 shadow-sm  text-sm focus:outline-none focus:border-success-300 bg-transparent placeholder-gray-500 text-gray-500 ",
    secondary:
      " border border-transparent focus:border-gray-300 py-4 px-2.5 bg-gray-100 text-gray-700 placeholder-gray-400 focus:outline-none",
  };
  const labelTheme: any = {
    primary: " text-sm  text-gray-800",
    secondary: " font-bold",
  };

  return (
    <>
      {label && (
        <span className={`mb-2 block  ${labelTheme[theme!]}`}>{label}</span>
      )}

      <div className="relative rounded-md">
        <>
          {(leadingSvgIcon || leadingIcon) && (
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              {leadingSvgIcon ? (
                <SvgIconComponent
                  icon={trailingSvgIcon}
                  onClick={() => null}
                  className={""}
                />
              ) : (
                leadingIcon
              )}
            </div>
          )}
          <input
            id={id}
            name={name}
            value={value}
            type={type}
            onChange={onChange}
            onBlur={onBlur}
            onClick={onClick}
            min={min}
            max={max}
            maxLength={maxLength}
            disabled={disabled}
            placeholder={placeholder}
            onKeyPress={onKeyPress}
            onKeyDown={onKeyDown}
            style={style}
            className={classNames(
              `rounded-md  flex-1 appearance-none w-full shadow-sm text-base`,
              {
                "pl-10": !!leadingIcon,
                " text-brandGrey2 bg-gray-300": !!disabled,
                "border border-error-300 text-error-900 placeholder-error-300 focus:border-error-300 focus:ring-red":
                  !!error,
              },
              className,
              classForTheme[theme!]
            )}
          />

          {error && type !== "date" && type !== "time" && (
            <div className="absolute inset-y-0 right-0 flex items-center pointer-events-none pr-2">
              <Icon value={FaExclamationCircle} className={"text-warn-700"} />
            </div>
          )}
        </>
      </div>
      <InputHelper type={"helper"} text={error ? undefined : helperText} />
      <InputHelper type={"error"} text={error} />
    </>
  );
};

Input.defaultProps = {
  type: "text",
  placeholderColor: "brandPlaceholder",
  disabled: false,
  theme: "primary",
};

export default withFormikContext(Input);

export const InputWithoutFormik = Input;
