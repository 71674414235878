import React from "react";
import classNames from "classnames";
import { FaTimes } from "react-icons/fa";
import IconButton from "../IconButton/IconButton";

export interface Props {
  tagName?: string;
  onClickHandler?: any;
}

export const ChipWithCrossIcon: React.FunctionComponent<Props> = ({
  tagName,
  onClickHandler,
}) => {
  return (
    <>
      {tagName && (
        <div className="">
          <span
            className={classNames(
              "inline-flex relative",
              "items-center",
              "px-4 py-2 text-white rounded-full bg-indigo-600"
            )}
          >
            {tagName}
            {onClickHandler && (
              <div className="absolute right-0" style={{ top: "-6px" }}>
                <IconButton
                  className={"text-error-500"}
                  child={FaTimes}
                  onClick={onClickHandler}
                />
              </div>
            )}
          </span>
        </div>
      )}
    </>
  );
};

export default React.memo(ChipWithCrossIcon);
