import { RootStore } from "store/RootStore";
import { StorageService } from "store/services/StorageService";
import { ToastService } from "./store/services/ToastService";

export class AppContext {
  static store: RootStore;
  static storage: StorageService;
  static toast: ToastService;

  static boot(): void {
    this.store = RootStore.getInstance();
    this.storage = StorageService.getInstance();
    this.toast = ToastService.getInstance();
  }
}
