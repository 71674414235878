import React, { FunctionComponent } from "react";

import { BrandButton } from "../Button/BrandButton";
import Loader from "react-loader-spinner";

interface Props {
  heading: string;
  subHeading?: string;
  buttonText?: string;
  buttonOnClick?: (e?: any) => void;
  isSubmitting?: boolean;
}

const GenericHeader: FunctionComponent<Props> = ({
  heading,
  buttonText,
  subHeading,
  buttonOnClick,
  isSubmitting,
}) => (
  <div className="py-5 px-8">
    <div className="w-full flex bg-white  justify-between items-center">
      <div className=" items-start  flex flex-col">
        <h1 className="text-5xl tracking-tight font-bold text-gray-800 sm:text-5xl md:text-5xl">
          <span className="block xl:inline">Manage your </span>{" "}
          <span className="block text-indigo-600 xl:inline">{heading}</span>
        </h1>
        <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
          {subHeading}
        </p>
        {/* <p className="text-lg text-gray-800 dark:text-gray-100 font-bold pb-2">
          {heading}
        </p>
        {subHeading && (
          <div className="text-gray-400 text-sm">{subHeading}</div>
        )} */}
      </div>
      {buttonText && (
        <div className={"flex"}>
          {isSubmitting && (
            <span className="mr-3">
              <Loader type="BallTriangle" height={30} width={30} />
            </span>
          )}
          <BrandButton
            className=" text-base font-semibold "
            type="button"
            onClick={buttonOnClick}
            theme="primary"
            size={"xl"}
          >
            {buttonText}
          </BrandButton>
        </div>
      )}
    </div>
  </div>
);

export default GenericHeader;
