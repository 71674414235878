import { AppContext } from "App.context";
import { observer } from "mobx-react";
import React, { FC, useEffect, useState } from "react";
import GenericHeader from "../../../../shared/Headers/GenericHeader";
import ChatSetting from "./ChatSetting";

const ChatSettings: FC = () => {
  const { store, toast } = AppContext;
  const businessId = store.userStore.loggedInUser?.memberships?.items?.[0]?.id;
  const tripId = new URLSearchParams(location.search).get("id");
  const trip = store.tripStore.trip.item;
  const currentTripId = new URLSearchParams(location.search).get("id");

  useEffect(() => {
    store.tripStore.getTrip(businessId!, tripId!);
  }, [businessId]);

  const setData = (name: string, value: boolean) => {
    const payload = {
      ...trip,
      overview: {
        ...trip.overview?.item,
        chat_types: { ...trip.overview?.item.chat_types, [name]: value },
      },
    };

    store.tripStore.updateTripChatSettings(
      currentTripId as any,
      businessId!,
      payload
    );
  };
  return (
    <>
      <div className="bg-white ">
        <div className="container mx-auto bg-white  rounded">
          <GenericHeader
            heading={"Trip Chat / Messaging Settings"}
            subHeading={
              " Control which Chat / Messaging options are available in the app for the Trip"
            }
          />
          <div className="py-5 px-8 flex flex-col">
            <ChatSetting
              initialValue={trip?.overview?.item.chat_types.news_updates}
              setData={(v) => setData("news_updates", v)}
              label="News/ Updates"
              helper="Send Broadcast messages to all members on the trip"
            />
            <ChatSetting
              initialValue={trip?.overview?.item.chat_types.customer_service}
              setData={(v) => setData("customer_service", v)}
              label="Customer Chat"
              helper="Send a message directly to a single customer (all staff on trip can view)"
            />
            <ChatSetting
              initialValue={trip?.overview?.item.chat_types.group_chat}
              label="Group Chat"
              setData={(v) => setData("group_chat", v)}
              helper="Group Chat for all Guests on the trip"
            />
            <ChatSetting
              initialValue={trip?.overview?.item.chat_types.staff_chat}
              label="Staff Chat"
              setData={(v) => setData("staff_chat", v)}
              helper="Group Chat for all Staff on a Trip"
            />
            {/* <ChatSetting
              initialValue={trip?.overview?.item.chat_types.parent_chat}
              label="Parent/ Guardian Chat"
              setData={(v) => setData("parent_chat", v)}
              helper="Send updates to all parents/ quardians added to the trip."
            /> */}
          </div>
        </div>
      </div>
    </>
  );
};
export default observer(ChatSettings);
