import { Formik, FormikProps, FormikValues } from "formik";
import React, { FC, useState } from "react";
import * as yup from "yup";
import { BrandButton } from "../../../shared/Button/BrandButton";
import { CheckInput, CheckType } from "../../../shared/CheckInput/CheckInput";
import Input from "../../../shared/Input/Input";
import { AppContext } from "../../../App.context";
import { UserRoles } from "../../../constants/enums/UserRoles";
import { ERROR_WENT_WRONG } from "../../../constants/message";
import { UserModel } from "../../../store/models/UserModel";
import Loader from "react-loader-spinner";
import { observer } from "mobx-react";

interface Props {
  modalHandler: () => void;
  editData?: UserModel;
}

const AddStaffMember: FC<Props> = ({ modalHandler, editData }) => {
  const { store, toast } = AppContext;

  const [selectedCheckBox, setSelectedCheckBox] = useState(
    editData?.role?.toLowerCase() || "staff"
  );

  const isUpdate = !!editData;

  const businessDetails = store.userStore.loggedInUser?.memberships?.items?.[0];

  return (
    <Formik
      initialValues={{
        first_name: editData?.firstName || "",
        last_name: editData?.lastName || "",
        email: editData?.email || "",
      }}
      validationSchema={yup.object().shape({
        email: yup
          .string()
          .required("Email is Required")
          .trim()
          .email("Please Enter a Valid Email"),
        first_name: yup.string().trim().required("First Name is Required"),
        last_name: yup.string(),
      })}
      onSubmit={async (data, formikHelpers) => {
        formikHelpers.setSubmitting(true);

        const mappedValues = {
          ...data,
          business_id: businessDetails!.business_id!,
          business_name: businessDetails?.name || "",
          role:
            selectedCheckBox === "staff" ? UserRoles.STAFF : UserRoles.ADMIN,
        };

        try {
          if (isUpdate) {
            await store.businessStore.updateStaff({
              _id: editData?.id,
              ...mappedValues,
            });
          } else {
            await store.businessStore.addStaff({
              ...mappedValues,
              status: "Pending",
            });
          }

          formikHelpers.setSubmitting(false);
          modalHandler();
        } catch (e: any) {
          formikHelpers.setSubmitting(false);
          toast.showError(e.message || ERROR_WENT_WRONG);
        }

        formikHelpers.setSubmitting(false);
      }}
    >
      {(formikProps: FormikProps<FormikValues>) => (
        <form onSubmit={formikProps.handleSubmit}>
          <div className="bg-white ">
            <div className="mx-auto bg-white  rounded ">
              <div className="border-b border-gray-300 py-5 p-5 bg-white ">
                <div className=" items-start  flex flex-col">
                  <h1 className="text-5xl tracking-tight font-bold text-gray-800 sm:text-5xl md:text-5xl py-10">
                    <span className="block xl:inline">Add / Edit a </span>
                    <span className="block text-indigo-600 xl:inline">
                      Staff Member
                    </span>
                  </h1>
                  <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                    Add staff to manage their access to the SafeTravels app
                  </p>
                </div>
              </div>
              <div className="w-2/3 mx-auto flex flex-col space-y-4 mt-4">
                <div className="flex w-full space-x-6">
                  <div className="w-1/2">
                    <Input
                      label="First Name"
                      helperText="Please enter the first name of the Staff Member."
                      name={"first_name"}
                    />
                  </div>
                  <div className="w-1/2">
                    <Input
                      label="Last Name"
                      helperText="Please enter the last name of the Staff Member."
                      name={"last_name"}
                    />
                  </div>
                </div>
                <div className="flex w-full space-x-6">
                  <div className={"w-1/2"}>
                    <Input
                      className=""
                      type="email"
                      label="Email Address*"
                      helperText="Please enter the email of the Staff Member."
                      name={"email"}
                    />
                  </div>
                  <div className={"w-1/2"}>
                    <label
                      htmlFor="username"
                      className="pb-2 text-sm text-gray-800 "
                    >
                      {"Staff Role*"}
                    </label>

                    <div className="flex space-x-2 py-1">
                      <div
                        className="flex items-center"
                        onClick={(e) => setSelectedCheckBox("admin")}
                      >
                        <CheckInput
                          type={CheckType.Radio}
                          name={"admin"}
                          selectedOption={selectedCheckBox}
                          className="w-5 h-5"
                        />
                        <span className="mt-1 text-sm text-gray-800 ">
                          Admin
                        </span>
                      </div>

                      <div
                        className="flex items-center"
                        onClick={(e) => setSelectedCheckBox("staff")}
                      >
                        <CheckInput
                          type={CheckType.Radio}
                          name={"staff"}
                          selectedOption={selectedCheckBox}
                          className="w-5 h-5"
                        />
                        <span className="mt-1 text-sm text-gray-800 ">
                          Staff
                        </span>
                      </div>
                    </div>
                    <div className="text-xs font-thin text-gray-700">
                      {"Please Enter a Staff Role"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr className="h-0.5 mt-6 bg-gray-400 mx-auto" />

            <div className="w-11/12 mx-auto border-gray-300 flex justify-end dark:border-gray-700 py-5 bg-white ">
              {formikProps.isSubmitting && (
                <span className="mr-3">
                  <Loader type="BallTriangle" height={30} width={30} />
                </span>
              )}
              <div className="w-52 items-center justify-between flex pb-4">
                <BrandButton
                  className=" text-base font-semibold bg-gray-200 hover:bg-gray-400"
                  disabled={formikProps.isSubmitting}
                  type="button"
                  onClick={modalHandler}
                  theme="basic"
                  size={"xl"}
                >
                  Cancel
                </BrandButton>
                <BrandButton
                  className=" text-base font-semibold"
                  disabled={formikProps.isSubmitting}
                  type="submit"
                  theme="primary"
                  size={"xl"}
                >
                  Save
                </BrandButton>
              </div>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};
export default observer(AddStaffMember);
