import { EntityBaseModel } from "@devslane/mobx-entity-manager";
import { observable } from "mobx";
import { ArticleStore } from "store/entities/ArticleStore";
import { Utils } from "../../Utils/Utils";

export class ArticleModel extends EntityBaseModel {
  static _store: ArticleStore;

  @observable
  name!: string;

  @observable
  description!: string;

  toJSON() {
    return Utils.sanitizeObject({
      id: this.id,
      name: this.name,
      description: this.description,
    });
  }
}
