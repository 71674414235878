import React, { FC, useCallback, useEffect, useState } from "react";
import TableRow from "./TableRow";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "./ArrayMove";
import { observer } from "mobx-react";

const SortableCont = SortableContainer(({ children }: any) => {
  return <tbody>{children}</tbody>;
});

const SortableItem = SortableElement((props: any) => <TableRow {...props} />);
interface Props {
  headers: any;
  items: any;
  setItems: React.Dispatch<React.SetStateAction<any>>;
}
const ReorderTable: FC<Props> = ({ headers, items, setItems }) => {
  const onSortEnd = useCallback(({ oldIndex, newIndex }) => {
    setItems((oldItems: any) => arrayMove(oldItems, oldIndex, newIndex));
  }, []);

  return (
    <table className="table p-4 bg-white  w-full">
      {headers && (
        <thead className="sticky top-0 bg-white  w-full z-10">
          <tr className="text-left">
            {headers?.map((header: any) => (
              <th
                key={header}
                className="p-4 dark:border-dark-5 whitespace-nowrap font-bold uppercase text-gray-700 text-sm"
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
      )}
      <SortableCont
        onSortEnd={onSortEnd}
        axis="y"
        lockAxis="y"
        lockToContainerEdges={true}
        lockOffset={["30%", "50%"]}
        helperClass="helperContainerClass"
        useDragHandle={true}
        distance={1}
      >
        {items?.map((value: any, index: any) => (
          <SortableItem
            key={`item-${index}`}
            distance={1}
            index={index}
            dataArray={value}
          />
        ))}
      </SortableCont>
    </table>
  );
};

export default observer(ReorderTable);
