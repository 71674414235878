import React, { useState } from "react";
import { useEffect } from "react";
import { useDropzone } from "react-dropzone";
import AvatarPreview from "./AvatarPreview";
import styles from "./filepicker.module.scss";
import { BrandButton } from "../Button/BrandButton";
import { Utils } from "../../Utils/Utils";

interface FilePickerProps {
  fileTypes?: string | string[];
  handleFileUpdate: (file: File | undefined) => void;
  btnType: "primary" | "secondary";
  btnText: string;
  showList?: boolean;
  btnClass?: string;
  uploading?: boolean;
  disabled?: boolean;
  src?: string;
  desktopHeightWidth?: string;
  mobileHeightWidth?: string;
  name?: string;
  onlyView?: boolean;
}

const FilePicker: React.FC<FilePickerProps> = ({
  fileTypes,
  handleFileUpdate,
  btnText,
  btnType,
  showList,
  btnClass,
  uploading,
  disabled,
  src,
  desktopHeightWidth,
  mobileHeightWidth,
  name,
  onlyView,
}) => {
  const [file, setFile] = useState<File>();

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (newFiles) => setFile(newFiles[0]),
    multiple: false,
    accept: fileTypes,
    noDrag: true,
    disabled: uploading || disabled || onlyView,
  });

  useEffect(() => {
    handleFileUpdate(file);
  }, [file]);

  const getBtn = () => {
    if (btnType === "secondary") {
      return (
        <AvatarPreview
          uploading={uploading}
          src={src || ""}
          disabled={disabled}
          desktopHeightWidth={desktopHeightWidth}
          btnText={btnText}
        />
      );
    }
    return (
      <BrandButton
        type={"button"}
        className={`text-base font-semibold my-4 ${btnClass} ${
          disabled && "opacity-50"
        }`}
        disabled={disabled}
        theme="primary"
        size={"xl"}
      >
        {btnText}
      </BrandButton>
    );
  };

  return (
    <>
      {file && showList && (
        <div className="my-6 flex">
          <span className="mr-6 font-medium text-brandPrimary">
            {Utils.getTrimmedString(file?.name, 50)}
          </span>
          {uploading ? (
            <div className={styles.spinner_list} />
          ) : (
            <span
              onClick={() => setFile(undefined)}
              className={styles.file_remove}
            />
          )}
        </div>
      )}
      <div
        {...(getRootProps() as any)}
        style={{
          width: "fit-content",
        }}
      >
        <input {...getInputProps()} />
        {getBtn()}
      </div>
    </>
  );
};

export default FilePicker;
