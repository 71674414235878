import { FormikProps, FormikValues } from "formik";
import React, { FC, useState } from "react";
import Input from "../../../../shared/Input/Input";
import FilePicker from "../../../../shared/FilePicker/FilePicker";
import GenericHeader from "../../../../shared/Headers/GenericHeader";
import InputHelper from "../../../../shared/Input/Inputhelper/InputHelper";
import { GAutoCompleteResponse } from "../../../../types/GoogleCloudAPI";
import googleAPIServices from "../../../../store/services/GoogleAPIServices";
import debounce from "debounce-promise";
import ReactAsyncSelectMenu from "../../../../shared/SelectMenus/ReactAsyncSelectMenu";
import { SingleValue } from "react-select";
import { UploadStatus } from "../../../../constants/enums/UploadStatus";
import { FileService } from "../../../../store/services/api-services/FileService";
import { observer } from "mobx-react";
import { AppContext } from "../../../../App.context";

interface Props {
  formikProps: FormikProps<FormikValues>;
}

const TripOverviewOverview: FC<Props> = ({ formikProps }) => {
  const { toast } = AppContext;

  const [uploadedUrls, setUploadedUrls] = useState<{
    url: string;
    uploading: UploadStatus;
  }>({
    url: "",
    uploading: UploadStatus.NOT_STARTED,
  });

  const [image, setImage] = useState<{ preview: string }>({
    preview: formikProps.values.cover_image || "",
  });

  const handleUploadProfilePic = async (file: File, formikProps: any) => {
    if (file) {
      try {
        toast.showInfo("Logo Upload Start");
        setUploadedUrls((prev) => ({
          ...prev,
          uploading: UploadStatus.IN_PROGRESS,
        }));
        const res = await FileService.uploadFile(file);
        setUploadedUrls((prev) => ({
          ...prev,

          url: res.url || "",
        }));
        formikProps.setFieldValue("cover_image", res.url);
        setUploadedUrls((prev) => ({ ...prev, uploading: UploadStatus.DONE }));
        toast.showSuccess("Logo Uploaded Successfully");
      } catch (e: any) {
        toast.showError("Logo Upload Fail");
        setUploadedUrls((prev) => ({
          ...prev,
          uploading: UploadStatus.NOT_STARTED,
        }));
      }
    } else {
      setUploadedUrls((prev) => ({ url: "", uploading: UploadStatus.DONE }));
    }
  };
  const handleImagePreview = (file: File | undefined, formikProps: any) => {
    setImage({
      preview: file ? URL.createObjectURL(file) : "",
    });

    if (file) {
      handleUploadProfilePic(file, formikProps);
    }
  };

  return (
    <div className="bg-white ">
      <div className=" mx-auto">
        {/* <GenericHeader
          heading={"Activity Information"}
          subHeading={"Add Activity Information"}
        /> */}
        <hr className="h-0.5 mt-6 bg-gray-200 mx-auto w-full" />

        <div className="px-16 pb-8">
          <div className="w-9/12 flex flex-col space-y-10 mt-12 ">
            <div>
              <div className="w-full items-center pb-4">
                <p className="text-gray-800 font-bold ">Trip Overview</p>
              </div>

              <div className={"flex flex-col space-y-8"}>
                {/* <div>
                  <span className={`mb-2 block text-sm  text-gray-800`}>
                    Trip Logo
                  </span>
                  <div className={"mb-1"}>
                    <InputHelper
                      type={"helper"}
                      text="Upload a logo specific for your trip. Your business logo will be used if no trip logo added."
                    />
                  </div>
                  <FilePicker
                    handleFileUpdate={(file) =>
                      handleImagePreview(file, formikProps)
                    }
                    btnText="Upload Logo"
                    fileTypes={"image/*"}
                    btnType="secondary"
                    uploading={
                      uploadedUrls.uploading === UploadStatus.IN_PROGRESS
                    }
                    disabled={formikProps.isSubmitting}
                    src={image.preview || formikProps.values.cover_image || ""}
                    name={"logo"}
                  />
                </div> */}
                <div>
                  <Input
                    name={"name"}
                    label="Trip Name*"
                    helperText="Please enter the name of the Trip."
                    onChange={formikProps.handleChange}
                  />
                </div>
                <div>
                  <Input
                    name={"location"}
                    label="Trip Location*"
                    helperText="Please enter the Location/s of the Trip"
                    onChange={formikProps.handleChange}
                  />
                </div>
                <div className={"flex items-center justify-between"}>
                  <div className={"w-17/50"}>
                    <Input
                      name={"start_date"}
                      type="date"
                      label="Start Date*"
                      onChange={formikProps.handleChange}
                    />
                  </div>
                  <div className={"w-17/50"}>
                    <Input
                      name={"end_date"}
                      type="date"
                      label="Finish Date*"
                      onChange={formikProps.handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>

            <hr className="h-0.5 mt-6 bg-gray-50 mx-auto w-full" />
          </div>
        </div>

        <hr className="h-0.5 mt-6 bg-gray-200 mx-auto w-full mb-8" />
      </div>
    </div>
  );
};
export default observer(TripOverviewOverview);
