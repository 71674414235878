import React, { FC, useEffect, useState } from "react";
import Table from "../../../../shared/Table/Table";
import CustomCheckbox from "../../../../shared/CustomCheckBox/CustomCheckbox";
import GenericHeader from "../../../../shared/Headers/GenericHeader";
import { AppContext } from "App.context";
import { observer } from "mobx-react";
import { InputWithoutFormik } from "../../../../shared/Input/Input";
import { UserModel } from "../../../../store/models/UserModel";
import Loader from "react-loader-spinner";
import { debounce } from "lodash";
import { ERROR_WENT_WRONG } from "../../../../constants/message";

const TripStaffs: FC = () => {
  const { store, toast } = AppContext;
  const selectedStaffId = store.businessStore.tripStaffIdList.items;
  const staffList = store.businessStore.staffList.items;
  const [filterStaffs, setFilterStaffs] = useState<UserModel[]>(staffList);

  const businessId = store.userStore.loggedInUser?.memberships?.items?.[0]?.id;
  const tripId = new URLSearchParams(location.search).get("id");
  const staffTable = {
    headingsArray: ["First Name", "Last Name", "Include on Trip"],
    selectChildren: <div className=""></div>,
  };

  const dataArray: any = (filterStaffs || [])
    ?.filter((staff) => !!staff.account_id)
    ?.map((staff) => {
      return [
        staff.firstName,
        staff.lastName,
        <div className="flex items-center">
          <CustomCheckbox
            name={"checkbox"}
            value={selectedStaffId.includes(staff.id)}
            onChange={(e: any) => {
              if (selectedStaffId.includes(staff.id)) {
                const removeStaff = async () => {
                  try {
                    store.businessStore.removeStaffFromTrip(
                      businessId!,
                      tripId!,
                      staff.id
                    );
                  } catch (e: any) {
                    toast.showError(e.message || ERROR_WENT_WRONG);
                  }
                };
                removeStaff();
              } else {
                const addStaff = async () => {
                  try {
                    await store.businessStore.addStaffToTrip(
                      businessId!,
                      tripId!,
                      staff
                    );
                  } catch (e: any) {
                    toast.showError(e.message || ERROR_WENT_WRONG);
                  }
                };
                addStaff();
              }
            }}
          />
        </div>,
      ];
    });

  const ifSearchIncludes = (data: UserModel, searchKey: string) => {
    const searchCriteriaArray: Array<any> = [
      data.firstName?.trim()?.toLowerCase(),
      data.lastName?.trim()?.toLowerCase(),
      data.email?.trim()?.toLowerCase(),
    ];
    if (
      searchCriteriaArray?.some((val) =>
        val?.includes(searchKey?.trim()?.toLowerCase())
      )
    )
      return true;

    return false;
  };

  const handleSearch = debounce((searchKey: string): void => {
    const filtered = staffList.filter((data) => {
      return ifSearchIncludes(data, searchKey);
    });

    setFilterStaffs(filtered);
  }, 200);

  useEffect(() => {
    setFilterStaffs(staffList);
  }, [staffList]);

  useEffect(() => {
    store.businessStore.loadTripStaff(businessId!, tripId!);
    store.businessStore.loadStaff(businessId!);
  }, [businessId]);

  return (
    <>
      <div className="bg-white">
        <div className="pb-8">
          <GenericHeader
            heading={"Trip Staff"}
            subHeading={
              "Add Staff members to the trip. They will have access to the Trip in the app."
            }
          />
          <hr className="h-0.5 mt-2 bg-gray-50 mx-auto w-full" />
          <div className={"px-8 mt-4"}>
            <InputWithoutFormik
              type="text"
              placeholder="Search Keyword"
              onChange={(e) => handleSearch(e.target.value || "")}
            />
          </div>
          <div className="mt-6 w-full px-8">
            {store.businessStore.staffList.loading ? (
              <div className={"flex justify-center mx-auto"}>
                <Loader type="BallTriangle" height={30} width={30} />
              </div>
            ) : (
              <Table headers={staffTable.headingsArray} data={dataArray} />
            )}
          </div>
          <hr className="h-0.5 mt-10 bg-gray-50 mx-auto w-full" />
        </div>
      </div>
    </>
  );
};
export default observer(TripStaffs);
