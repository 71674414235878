import { action, observable, runInAction } from "mobx";
import { ChildStore } from "../ChildStore";
import { Context } from "../Context";
import { UserModel } from "../models/UserModel";
import { RootStore } from "../RootStore";
import { userService } from "../services/api-services/UserService";

export enum LoginStatus {
  LOGGED_OUT,
  LOGGED_IN,
  PENDING,
}

export class UserStore extends ChildStore<UserModel> {
  constructor(rootStore: RootStore) {
    super(rootStore);
    UserModel._store = this;
  }

  @observable loginStatus!: LoginStatus;
  @observable loggedInUser?: UserModel;

  reset() {
    this.setLoginStatus(LoginStatus.LOGGED_OUT);
    runInAction(() => {
      this.loggedInUser = undefined;
    });
    this.entities.clear();
    Context.storage.reset();
  }

  hasLoggedInToken = async () => {
    return !!(await Context.storage.getAuthToken());
  };

  getLoggedInToken = async () => {
    return await Context.storage.getAuthToken();
  };

  @action
  async login(data: { email: string; password: string }): Promise<UserModel> {
    return await userService
      .login(data)
      .then(async (res) => {
        await Context.storage.setAuthToken(res.token);
        this.setLoggedInUser(res.user.toJSON() as UserModel);
        return res.user;
      })
      .catch((err) => {
        throw err;
      });
  }

  @action
  async forgotPassword(data: { email: string }): Promise<UserModel> {
    return await userService
      .forgotPassword(data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  }

  @action
  async resetPassword(data: {
    token: string;
    password: string;
    confirmPassword: string;
  }): Promise<UserModel> {
    return await userService
      .resetPassword(data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  }

  @action
  async verifyEmail(data: { token: any }): Promise<any> {
    return await userService
      .verifyEmail(data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  }

  @action
  async signUp(data: {
    firstName: string;
    lastName: string;
    name?: string;
    acceptTerms: boolean;
    password: string;
    confirmPassword: string;
    source: string;
    email: string;
  }): Promise<UserModel> {
    return await userService
      .signUp(data)
      .then((res) => {
        return res.user;
      })
      .catch((err) => {
        throw err;
      });
  }

  @action
  async me(): Promise<UserModel> {
    try {
      const res = await userService.me();
      await Context.storage.setAuthToken(res.token);
      this.setLoggedInUser(res.user.toJSON() as UserModel);
      return res.user;
    } catch (e) {
      throw e;
    }
  }

  async updateUser(data: {
    firstName?: string;
    lastName?: string;
    public_notes?: string;
    private_notes?: string;
    date_of_birth?: string;
  }): Promise<UserModel> {
    return await userService.updateUser(data).then((res: UserModel) => {
      this.setLoggedInUser(res?.toJSON() as UserModel);
      return res;
    });
  }

  @action
  setLoggedInUser(data: UserModel): void {
    this.loggedInUser = UserModel.fromJson(data) as UserModel;

    console.log("loggedinuser", this.loggedInUser);
    this.setLoginStatus(LoginStatus.LOGGED_IN);
  }

  @action
  setLoginStatus(status: LoginStatus) {
    this.loginStatus = status;

    if (status === LoginStatus.LOGGED_OUT) {
      this.loggedInUser = undefined;
      Context.storage.reset();
    }
  }

  @action
  async homeForm(data: {
    name?: string;
    company?: string;
    email?: string;
  }): Promise<any> {
    console.log(data);
    return await userService.sampleForm(data).then((res: any) => {
      // this.setLoggedInUser(res?.toJSON() as UserModel);
      return res;
    });
  }
}
