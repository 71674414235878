import React, { FunctionComponent, useEffect, useState } from "react";
import classNames from "classnames";
import { Editor } from "react-draft-wysiwyg";
import htmlToDraft from "html-to-draftjs";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import useId from "../../Hooks/useId";
import InputHelper from "./Inputhelper/InputHelper";
import { FileService } from "../../store/services/api-services/FileService";

interface BrandTextAreaInputProps {
  placeholder?: string;
  error?: string;
  label?: string;
  helperText?: string;
  className?: string;
  leadingIcon?: any;
  trailingIcon?: any;
  trailingSvgIcon?: any;
  leadingSvgIcon?: any;
  row?: number;
  col?: number;
  style?: any;
  placeholderColor?: string;
  labelClassName?: string;
  disabled?: boolean;
  readOnly?: boolean;
  labelColor?: string;
  tooltip?: string;
  onKeyUp?: (event: any) => void;
  optionalText?: string;
  defaultEditorState?: any;
  handleTextEditorData: (data: string) => void;
  value: string;
  height: string;
}

const BrandTextEditor: FunctionComponent<
  BrandTextAreaInputProps & Partial<any>
> = ({
  value,
  onBlur,
  onChange,
  placeholder,
  label,
  helperText,
  leadingIcon,
  trailingIcon,
  trailingSvgIcon,
  error,
  row,
  col,
  className,
  style,
  placeholderColor,
  labelClassName,
  disabled,
  readOnly,
  leadingSvgIcon,
  labelColor,
  tooltip,
  onKeyUp,
  optionalText,
  defaultEditorState,
  handleTextEditorData,
  height,
}) => {
  const id = useId();
  const [editorState, setEditorState] = useState<EditorState>();

  const [isTouched, setIsTouched] = useState<boolean>(false);

  const uploadImageCallBack = async (file: any) => {
    return await FileService.uploadFile(file).then((res: any) => {
      return {
        data: {
          link: res.url || "",
        },
      };
    });
  };

  const onEditorStateChange = (state: any) => {
    setEditorState(state);
    handleTextEditorData(
      state?.getCurrentContent()
        ? draftToHtml(convertToRaw(state?.getCurrentContent() as ContentState))
        : ""
    );
  };

  // useEffect(() => {
  //   console.log("staff1234567", editorState?.getCurrentContent());
  //   handleTextEditorData(
  //     editorState?.getCurrentContent()
  //       ? draftToHtml(
  //           convertToRaw(editorState?.getCurrentContent() as ContentState)
  //         )
  //       : ""
  //   );
  // }, [editorState]);

  useEffect(() => {
    const html = value || "";
    if (html.length === 0) {
      setEditorState(EditorState.createEmpty());
      return;
    }
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const outputEditorState = EditorState.createWithContent(contentState);
      setEditorState(outputEditorState);
    }
  }, []);

  return (
    <>
      {label && <span className={`mb-2 block ${labelClassName}`}>{label}</span>}
      <div className="relative rounded-md">
        <>
          <Editor
            editorState={editorState}
            wrapperClassName=""
            toolbar={{
              options: [
                "inline",
                "blockType",
                "fontSize",
                "fontFamily",
                "list",
                "textAlign",
                "image",
                "colorPicker",
                "link",
                "embedded",
                "emoji",
                "remove",
                "history",
              ],
              inline: { inDropdown: true },
              link: { inDropdown: true },
              history: { inDropdown: true },
              image: {
                className: undefined,
                component: undefined,
                popupClassName: "",
                urlEnabled: true,
                uploadEnabled: true,
                alignmentEnabled: false,
                uploadCallback: uploadImageCallBack,
                previewImage: true,
                inputAccept:
                  "image/gif,image/jpeg,image/jpg,image/png,image/svg",
                alt: { present: false, mandatory: false },
                defaultSize: {
                  height: "250px",
                  width: "250px",
                },
              },
            }}
            editorClassName={classNames(
              `border border-gray-300  pl-3 py-2 focus:outline-none focus:border-success-300 bg-transparent placeholder-gray-500 text-gray-500 rounded-lg  flex-1 appearance-none w-full`,
              {
                "pl-10": !!leadingIcon,
                " text-gray-400 ": !!disabled,
                "border border-error-300 text-error-900 placeholder-error-300 focus:border-error-300 focus:ring-red":
                  !!error,
              },
              className
            )}
            editorStyle={{
              maxHeight: height || "130px",
              minHeight: height || "130px",
            }}
            placeholder={placeholder}
            onEditorStateChange={onEditorStateChange}
            defaultEditorState={defaultEditorState}
            spellCheck={true}
            handlePastedText={() => false}
            onBlur={() => setIsTouched(true)}
            toolbarClassName={"overflow-visible"}
          />
        </>
      </div>
      <InputHelper type={"helper"} text={error ? undefined : helperText} />
      {error && isTouched && <InputHelper type={"error"} text={error} />}
    </>
  );
};

BrandTextEditor.defaultProps = {
  placeholderColor: "brandPlaceholder",
  labelColor: "brandSignupPrimary",
};

export default BrandTextEditor;
