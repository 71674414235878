import {
  EntityBaseModel,
  EntityIdentifier,
  ModelJson,
  ModelList,
} from "@devslane/mobx-entity-manager";
import { observable } from "mobx";
import { SectionStore } from "store/entities/SectionStore";
import { Utils } from "../../Utils/Utils";
import { ArticleModel } from "./ArticleModal";

export class SectionModel extends EntityBaseModel {
  static _store: SectionStore;

  @observable
  name!: string;
  @observable
  content!: { _id: EntityIdentifier; name: string; description: string }[];

  toJSON() {
    return Utils.sanitizeObject({
      id: this.id,
      name: this.name,
      content: this.content,
    });
  }
}
