import React, { Dispatch, SetStateAction } from "react";
import { FaWindowClose } from "react-icons/fa";
import Icon from "shared/Icon/Icon";
interface Props {
  handleClose?: () => void;
  handleChange: Dispatch<SetStateAction<string>> | any;
  currentSelectedStep: string;
  headerItems: TripsHeaderNavigationItem[];
}

const TripsHeaderNavigation: React.FC<Props> = ({
  handleChange,
  currentSelectedStep,
  headerItems,
  handleClose,
}) => {
  return (
    <div
      className={
        "flex flex-row items-start flex-shrink-0 py-5  space-y-0 cursor-pointer  w-full"
      }
    >
      <div className="flex flex-row justify-between items-center w-full">
        <div className="w-3/4">
          {headerItems.map((item: TripsHeaderNavigationItem, index: number) => (
            <div
              key={item.value + index}
              className={
                "px-5 py-2 text-sm leading-5 font-medium inline-flex cursor-pointer justify-center items-center " +
                (currentSelectedStep === item.value &&
                  "border-b-4 border-brandPrimaryBlue rounded-b-sm")
              }
              onClick={() => handleChange(item.value)}
            >
              <span className={"text-sm"}> {item.label}</span>
            </div>
          ))}
        </div>
        {handleClose && (
          <div className="pr-4" onClick={() => handleClose()}>
            <Icon className="cursor-pointer" value={FaWindowClose} />
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(TripsHeaderNavigation);

export interface TripsHeaderNavigationItem {
  label: string;
  value: string;
}
