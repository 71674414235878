import { FormikProps, FormikValues, useFormik, Formik } from "formik";
import React, { FC, useCallback, useEffect, useState } from "react";
import { EntityIdentifier } from "@devslane/mobx-entity-manager";
import Table from "../../../shared/Table/Table";
import * as yup from "yup";
import { BrandButton } from "../../../shared/Button/BrandButton";
import Input from "../../../shared/Input/Input";
import FilePicker from "../../../shared/FilePicker/FilePicker";
import { AppContext } from "../../../App.context";
import { UploadStatus } from "../../../constants/enums/UploadStatus";
import { FileService } from "../../../store/services/api-services/FileService";
import { ImageModel } from "../../../store/models/ImageModel";
import { observer } from "mobx-react";
import InputHelper from "../../../shared/Input/Inputhelper/InputHelper";
import Loader from "react-loader-spinner";
import GenericHeader from "shared/Headers/GenericHeader";
import CustomCheckbox from "../../../shared/CustomCheckBox/CustomCheckbox";
import { InputWithoutFormik } from "../../../shared/Input/Input";


interface Props {
  modalHandler: () => void;
  editData?: ImageModel;
}

const AddTripsDocumentModal: FC<Props> = ({ modalHandler, editData }) => {
  console.log("eidtdata", editData)
  const staffTable = {
    headingsArray: [
      "First Name",
      "Last Name",
      "Email",
      "Member Type",
      "Actions",
    ],
    selectChildren: <div className=""></div>,
  };

  interface Guest {
    guest_id?: EntityIdentifier,
    email?: string,
    first_name?: string,
    last_name?: string
  }

  const guest: Guest[] = [];

  const [isPrivate, setIsPriavte] = useState(false);
  const [selectedGuests, setSelectedGuests] = useState<Guest[]>([]);

  const { store, toast } = AppContext;

  const guestList = store.businessStore.guestList.items;

  const onClickButtonHandler = (value: Guest) => {
    const index = selectedGuests.findIndex(guest => JSON.stringify(guest) === JSON.stringify(value));
    if (index > -1) {
      // value already exists, remove it
      const newValues = [...selectedGuests];
      newValues.splice(index, 1);
      setSelectedGuests(newValues);
    } else {
      setSelectedGuests([...selectedGuests, value]);
    }
  }

  useEffect(() => {
    if (editData !== undefined) {
      const newSelectedGuests = editData.private_guests?.map(guests => ({
        guest_id: guests.guest_id,
        email: guests.email,
        first_name: guests.first_name,
        last_name: guests.last_name,
      })) || [];
  
      setSelectedGuests(newSelectedGuests);
      setIsPriavte(editData.private);
    }
  }, [editData]);

  let value = false;
  const dataArray: any = (guestList || [])
    ?.filter((staff) => !!staff.account_id)
    ?.map((staff, key) => {
        guest.push({
          guest_id: staff.account_id,
          email: staff.email,
          first_name: staff.firstName,
          last_name: staff.lastName,
        })

      if(staff.email != undefined)
      if(selectedGuests.findIndex(selectedGuest => JSON.stringify(selectedGuest) === JSON.stringify(guest[key])) > -1) value = true;
      else value = false;

      return [
        staff.firstName,
        staff.lastName,
        staff.email,
        staff.role,
        <div className="flex items-center -mt-[13px]">
          <CustomCheckbox
            key = {key}
            name={"checkbox"}
            value={value}
            onChange={(e: any) => {
              onClickButtonHandler(guest[key])
            }}
          />
        </div>,
      ];
    });

  const businessId = store.userStore.loggedInUser?.memberships?.items?.[0]?.id;
  const currentTripId = new URLSearchParams(location.search).get("id");

  const isUpdate = !!editData;

  const [uploadedUrls, setUploadedUrls] = useState<{
    url: string;
    uploading: UploadStatus;
  }>({
    url: "",
    uploading: UploadStatus.NOT_STARTED,
  });

  useEffect(() => {
    store.businessStore.loadGuests(currentTripId as any, businessId!);
  }, [businessId]);

  const handleUploadProfilePic = async (file: File, formikProps: any) => {
    if (file) {
      try {
        toast.showInfo("Document Upload Start");
        formikProps.setSubmitting(true);
        setUploadedUrls((prev) => ({
          ...prev,
          uploading: UploadStatus.IN_PROGRESS,
        }));
        const res = await FileService.uploadFile(file);
        setUploadedUrls((prev) => ({
          ...prev,

          url: res.url || "",
        }));
        formikProps.setFieldValue("url", res.url);
        setUploadedUrls((prev) => ({ ...prev, uploading: UploadStatus.DONE }));
        toast.showSuccess("Document Uploaded Successfully");
        formikProps.setSubmitting(false);
      } catch (e: any) {
        toast.showError("Document Upload Fail");
        formikProps.setSubmitting(false);
        setUploadedUrls((prev) => ({
          ...prev,
          uploading: UploadStatus.NOT_STARTED,
        }));
      }
    } else {
      setUploadedUrls((prev) => ({ url: "", uploading: UploadStatus.DONE }));
    }
  };

  const handleImagePreview = (file: File | undefined, formikProps: any) => {
    if (file) {
      handleUploadProfilePic(file, formikProps);
    }
  };
  const getTrimmedString = (text: string, limit: number) => {
    if (text.length > limit) return text.substring(0, limit) + "...";
    return text;
  };
  const getUploadedFileName = (url: string): string | undefined => {
    const _chunk1 = url.split(".com/");
    const _chunks = _chunk1?.[1]?.split("/");
    return _chunks?.filter((item: string, index: number) => !!index).join("/");
  };

  const handleFileOpen = useCallback((url: string) => {
    const a = document.createElement("a");
    a.href = url;
    a.target = "_blank";
    a.rel = "noreferrer noopener";
    a.download = getUploadedFileName(url) || "newFile.pdf";
    a.click();
    return;
  }, []);
  return (
    <Formik
      initialValues={{
        name: editData?.name || "",
        url: editData?.url || "",
      }}
      validationSchema={yup.object().shape({
        name: yup.string().required("Document Name is Required"),
        url: yup.string().required("Document is Required"),
      })}
      onSubmit={async (data, formikHelpers) => {
        formikHelpers.setSubmitting(true);

        try {
          if (isUpdate) {
            await store.imageStore.updateDocument(
              currentTripId as any,
              businessId!,
              {
                private: isPrivate,
                _id: editData?.id,
                name: data.name,
                url: data.url,
                private_guests: isPrivate ? selectedGuests : []
              }
            );
          } else {
            debugger
            await store.imageStore.addDocument({
              private: isPrivate,
              trip_id: currentTripId as any,
              business_id: businessId!,
              name: data.name,
              url: data.url,
              private_guests: selectedGuests
            });
          }

          modalHandler();
          formikHelpers.setSubmitting(false);
        } catch (e: any) {
          formikHelpers.setSubmitting(false);
        }
      }}
    >
      {(formikProps: FormikProps<FormikValues>) => (
        <form onSubmit={formikProps.handleSubmit}>
          <div className="bg-white">
            <div className="mx-auto bg-white rounded ">
              <div className="py-5 bg-white ">
                <div className="w-11/12 mx-auto items-center pb-4">
                  <div className=" items-start  flex flex-col">
                    <h1 className="text-5xl tracking-tight font-bold text-gray-800 sm:text-5xl md:text-5xl py-10">
                      <span className="block xl:inline">Add Trip </span>
                      <span className="block text-indigo-600 xl:inline">
                        Document{" "}
                      </span>
                    </h1>
                    <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                      Add PDF documents for the Trip that all users can view.
                    </p>
                  </div>
                  <hr className="h-0.5 mt-2 bg-gray-50 mx-auto w-full" />
                  <p className="text-lg text-gray-800 font-bold pb-2">
                    Add Document
                  </p>
                  <div className="text-gray-400">
                    Add Document that can be used in your Trips and Activities
                  </div>
                </div>
              </div>
              <div className="w-2/3 mx-auto flex flex-col space-y-4 mt-4 ">
                <div className={""}>
                  <span className={`mb-2 block text-sm  text-gray-800`}>
                    Upload Document
                  </span>
                  {editData?.url && (
                    <div className="text-base">
                      <span className="font-medium text-brandVioletLight">
                        {"Previous Uploaded File: "}
                      </span>
                      <span
                        className={"cursor-pointer underline"}
                        onClick={() => handleFileOpen(editData?.url)}
                      >
                        &nbsp;
                        {getTrimmedString(`${editData.name}`, 35)}
                      </span>
                    </div>
                  )}

                  <FilePicker
                    showList
                    handleFileUpdate={(file) =>
                      handleImagePreview(file, formikProps)
                    }
                    uploading={
                      uploadedUrls.uploading === UploadStatus.IN_PROGRESS
                    }
                    disabled={formikProps.isSubmitting}
                    src={editData?.url || ""}
                    btnText="Upload Document"
                    fileTypes={[".pdf", "image/*"]}
                    btnType="primary"
                    name={"name"}
                  />
                  {formikProps.errors["url"] && formikProps.touched["url"] && (
                    <InputHelper
                      type={"error"}
                      text={(formikProps.errors["url"] as string) || ""}
                    />
                  )}
                </div>

                {/* <div className="flex"> */}
                  <div className="w-1/2">
                    <Input
                      className=""
                      label="Document Name"
                      helperText="Please enter the name of the Document"
                      name={"name"}
                    />
                  </div>
                  <div className="flex">
                  <span className="">Private:</span> 
                  <div className="flex">
                    <CustomCheckbox 
                      name={"checkbox"}
                      value={isPrivate}
                      onChange={(e: any) => {
                        isPrivate ? setIsPriavte(false) : setIsPriavte(true)
                        }
                      }
                    />
                  </div>
                  </div>
                </div>
              {/* </div> */}
            </div>
          </div>
          {isPrivate === true && 
          <div className="flex flex-wrap py-[10px]">
            {
              <div className="mt-6 w-full px-8">
                  <Table headers={staffTable.headingsArray} data={dataArray} />
              </div>
            }
          </div>}
          <hr className="h-0.5 mt-6 bg-gray-400 mx-auto" />

          <div className="w-11/12 mx-auto border-gray-300 flex justify-end dark:border-gray-700 py-5 bg-white ">
            {formikProps.isSubmitting && (
              <span className="mr-3">
                <Loader type="BallTriangle" height={30} width={30} />
              </span>
            )}
            <div className="w-52 items-center justify-between flex pb-4">
              <BrandButton
                className=" text-base font-semibold bg-gray-200 hover:bg-gray-400"
                disabled={formikProps.isSubmitting}
                type="button"
                theme="basic"
                onClick={modalHandler}
                size={"xl"}
              >
                Cancel
              </BrandButton>
              <BrandButton
                className=" text-base font-semibold"
                disabled={formikProps.isSubmitting}
                type="submit"
                theme="primary"
                size={"xl"}
              >
                Save
              </BrandButton>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};
export default observer(AddTripsDocumentModal);
