import React from "react";
import ReactDOM from "react-dom";

import App from "./container/App/App";
import { Context } from "./store/Context";
import { StorageService } from "./store/services/StorageService";
import { NavigationService } from "./store/services/NavigationService";
import { AppContext } from "./App.context";
import { ToastService } from "./store/services/ToastService";
import envVars from "./Env.config";
import "./tailwind.css";
Context.boot({
  storageService: StorageService.getInstance(),
  baseUrl: envVars.API_BASE_URL!,
  toastService: ToastService.getInstance(),
  navigationService: NavigationService.getInstance(),
});

AppContext.boot();
// replace console.* for disable log on production

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
