import React from "react";

import classNames from "classnames";

import withFormikContext from "../withFormikContext";
import styles from "./CheckInput.module.scss";
import { ThemeType } from "../../constants/enums/ThemeType";
import useId from "../../Hooks/useId";

export interface Props {
  label?: string;
  descriptionText?: string;
  theme?: ThemeType;
  labelTheme?: ThemeType | "black";
  type?: CheckType;
  name?: string;
  className?: string;
  selectedOption?: string | boolean;
  onChange?: (event: any) => void;
}

export const CheckInput: React.FC<Props> = ({
  label,
  descriptionText,
  theme,
  labelTheme,
  type,
  name,
  className,
  selectedOption,
  onChange,
}) => {
  const id = useId();

  return (
    <div className={`flex items-start ${styles.custom_radio_group}`}>
      <input
        id={id}
        name={name}
        onChange={onChange}
        checked={
          name === selectedOption ||
          (typeof selectedOption === "boolean" && selectedOption === true)
        }
        type={type}
        className={classNames(
          "h-4 w-4 transition duration-150 ease-in-out ",
          `text-${theme}-600`,
          `form-${type}`,
          className
        )}
      />
      <div className={`ml-3 text-sm leading-5 ${styles.custom_label_group}`}>
        {/*<InputLabel label={label} theme={labelTheme} />*/}
        <p className={`text-${labelTheme}-500`}>{descriptionText}</p>
      </div>
    </div>
  );
};

export enum CheckType {
  Radio = "radio",
  CheckBox = "checkbox",
}

CheckInput.defaultProps = {
  theme: "primary",
  labelTheme: "black",
  type: CheckType.Radio,
};

export default React.memo(CheckInput);

export const FormikCheckInput = withFormikContext(CheckInput);
