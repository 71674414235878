import React, { FC, useEffect, useState } from "react";
import Table from "../../../../shared/Table/Table";
import BrandTextInput from "../../../../shared/Input/BrandTextInput";
import CustomCheckbox from "shared/CustomCheckBox/CustomCheckbox";
import { FormikProps, FormikValues } from "formik";
import { AppContext } from "App.context";
import { observer } from "mobx-react";
import { EntityIdentifier } from "@devslane/mobx-entity-manager";
import { ImageModel } from "store/models/ImageModel";
import { debounce } from "lodash";
import { InputWithoutFormik } from "shared/Input/Input";
import Loader from "react-loader-spinner";
import { BrandButton } from "../../../../shared/Button/BrandButton";
import AddImageModal from "../../ImageGallery/AddImageModal";
import Modal from "../../../../shared/Modal/Modal";
interface Props {
  selectedImages: Array<{
    image_id: EntityIdentifier;
    cover: boolean;
  }>;
  setSelectedImage: (
    val: Array<{
      image_id: EntityIdentifier;
      cover: boolean;
    }>
  ) => void;
  formikProps: FormikProps<FormikValues>;
}

const TripOverviewImages: FC<Props> = ({
  formikProps,
  selectedImages,
  setSelectedImage,
}) => {
  const { store } = AppContext;
  const businessId = store.userStore.loggedInUser?.memberships?.items?.[0]?.id;
  const staffTable = {
    headingsArray: [
      "Image",
      "Image Name",
      "Image Tags",
      "Select Image",
      "Make Cover Image",
    ],
  };

  const [openModal, setOpenModal] = useState<boolean>(false);
  const selectedImagesId = selectedImages?.map((image) => image.image_id);
  const imageList = store.imageStore.imagesList.items;
  const [filterImages, setFilterImages] = useState<ImageModel[]>(imageList);
  const coverImageId = selectedImages
    ?.filter((image) => image.cover)
    ?.map((image) => image.image_id);

  useEffect(() => {
    setFilterImages(imageList);
  }, [imageList]);

  const dataArray: any = (filterImages || [])?.map((image) => {
    return [
      <img alt="img" src={image.url || ""} className=" h-20 w-20 " />,
      image.name,
      <span>
        <div className={"flex flex-wrap "}>
          {image?.tags?.map((val: any, index: number) => (
            <span>
              {val}
              {(image?.tags?.length || 0) - 1 !== index && ","}
            </span>
          ))}
        </div>
      </span>,
      <CustomCheckbox
        name="selectedImage"
        value={selectedImagesId?.includes(image.id)}
        onChange={() => {
          if (selectedImagesId?.includes(image.id)) {
            setSelectedImage(
              selectedImages.filter((obj) => obj.image_id !== image.id)
            );
          } else {
            setSelectedImage([
              ...selectedImages,
              {
                image_id: image.id,
                cover: coverImageId?.includes(image.id),
              },
            ]);
          }
        }}
      />,
      <CustomCheckbox
        name="selectedCoverImage"
        value={coverImageId?.includes(image.id)}
        onChange={(e: any) => {
          if (coverImageId?.includes(image.id)) {
            setSelectedImage(
              selectedImages.map((obj) => ({
                image_id: obj.image_id,
                cover: false,
              }))
            );
            formikProps.setFieldValue("cover_image", "");
          } else {
            if (selectedImagesId.includes(image.id)) {
              setSelectedImage(
                selectedImages.map((obj) =>
                  obj.image_id === image.id
                    ? { image_id: obj.image_id, cover: true }
                    : { image_id: obj.image_id, cover: false }
                )
              );
            } else {
              setSelectedImage([
                ...selectedImages.map((obj) => ({
                  image_id: obj.image_id,
                  cover: false,
                })),
                { image_id: image.id, cover: true },
              ]);
            }

            formikProps.setFieldValue("cover_image", image?.url);
          }
        }}
      />,
    ];
  });

  const ifSearchIncludes = (data: ImageModel, searchKey: string) => {
    const searchCriteriaArray: Array<any> = [
      data.name?.trim()?.toLowerCase(),
      ...(data.tags || []).map((tag) => tag?.trim()?.toLowerCase()),
    ];
    return !!searchCriteriaArray?.some((val) =>
      val?.includes(searchKey?.trim()?.toLowerCase())
    );
  };

  const handleSearch = debounce((searchKey: string): void => {
    const filtered = imageList.filter((data) => {
      return ifSearchIncludes(data, searchKey);
    });

    setFilterImages(filtered);
  }, 200);

  useEffect(() => {
    store.imageStore.loadImages(businessId!);
  }, []);

  return (
    <div className="bg-white">
      <div className="pb-8">
        <div className="flex items-center justify-end px-8">
          <Modal
            modalStyle={{ width: "50rem", maxHeight: "95%", overflowY: "auto" }}
            open={openModal}
            onOutsideClick={() => setOpenModal(false)}
          >
            <AddImageModal modalHandler={() => setOpenModal(false)} />
          </Modal>
          <BrandButton
            className=" text-base font-semibold "
            type="button"
            onClick={() => setOpenModal(true)}
            theme="primary"
            size={"lg"}
          >
            Add New Image
          </BrandButton>
        </div>
        <div className={"px-8 mt-4"}>
          <InputWithoutFormik
            type="text"
            placeholder="Search Keyword"
            onChange={(e) => handleSearch(e.target.value || "")}
          />
        </div>
        <div className="mt-6 w-full px-8">
          {store.imageStore.imagesList.loading ? (
            <div className={"flex justify-center mx-auto"}>
              <Loader type="BallTriangle" height={30} width={30} />
            </div>
          ) : (
            <Table headers={staffTable.headingsArray} data={dataArray} />
          )}
        </div>
        <hr className="h-0.5 mt-10 bg-gray-50 mx-auto w-full" />
      </div>
    </div>
  );
};
export default observer(TripOverviewImages);
