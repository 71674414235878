import React from "react";
import { CSSProperties } from "react";
import styles from "./svgIcon.module.scss";
import svgList, { SvgIconType } from "./svgIcons.list";

interface SvgIconComponentProps {
  icon: SvgIconType;
  className?: string;
  style?: CSSProperties;
  onClick?: () => void;
  iconClassName?: string;
}

const SvgIconComponent: React.FC<SvgIconComponentProps> = ({
  icon,
  className,
  style,
  onClick,
  iconClassName,
}) => {
  const classes = () => {
    const classNames = [styles.svg_icon];

    if (className) {
      classNames.push(className);
    }

    if (!svgList[icon]) {
      classNames.unshift(`${styles.svg_icon}--hide-empty-container`);
    }
    return classNames.join(" ");
  };

  return (
    <div className={classes()} style={style} onClick={() => onClick?.()}>
      <img src={svgList[icon]} className={iconClassName} />
    </div>
  );
};

export default SvgIconComponent;
