import React, { FunctionComponent, useMemo } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import SideNavbar from "../SideNavbar/SideNavbar";
import Header from "../Header/Header";
import SideBarItemViewModel from "../ViewModels/SideBarItemViewModel";
import { DashboardRoutes } from "../constants/RouteConfigDashboard";
import { AppContext } from "App.context";
import { RouteConfig } from "../constants/RouteConfig";
import { observer } from "mobx-react";
import { UserRoles } from "../constants/enums/UserRoles";

const MainLayout: FunctionComponent = () => {
  const sidebarItems: Array<{
    heading: string;
    items: SideBarItemViewModel[];
  }> = DashboardRoutes.filter(
    (item) => item.items[0].path !== RouteConfig.profile._ROOT
  )?.map((data) => ({
    heading: data.heading,
    items: data.items.map((route) => ({
      path: route.path ? route.path : "",
      label: route.name,
      leadingIcon: route.iconClassName,
    })),
  }));

  const homeRoutes = () => {
    const routes: JSX.Element[] = [];
    DashboardRoutes.forEach((route) => {
      route.items.forEach((item) => {
        if (item.component)
          routes.push(
            <Route
              path={`${item.path}`}
              key={item.path}
              render={() => <item.component />}
            />
          );
      });
    });
    return routes;
  };

  const routes = useMemo(() => homeRoutes(), []);
  const {
    store: {
      userStore: { loggedInUser },
    },
  } = AppContext;

  const role = loggedInUser?.memberships?.items?.[0]?.role;

  const isAdminOrStaff = role === UserRoles.ADMIN || role === UserRoles.STAFF;

  return (
    <div className={"w-screen h-screen overflow-hidden"}>
      <div className={"h-full flex flex-row"}>
        {/* <div className="">
          {isAdminOrStaff && <SideNavbar sidebarItems={sidebarItems} />}
        </div> */}
        <div
          className={
            "overflow-hidden h-screen w-full flex flex-col bg-gray-100"
          }
        >
          <Header
            name={loggedInUser!.firstName}
            avatar={loggedInUser!.avatar}
            isAdminOrStaff={isAdminOrStaff}
          />

          <main className="overflow-y-auto">
            <div className="py-6 sm:px-6 lg:px-8 ">
              {isAdminOrStaff ? (
                <div className="">
                  <Switch>
                    {routes}
                    <Route
                      path="/"
                      render={() => (
                        <Redirect to={RouteConfig.tripsDashboard._ROOT} />
                      )}
                    />
                  </Switch>
                </div>
              ) : (
                <>
                  <div className="font-bold text-gray-600 sm:text-2xl text-center">
                    This dashboard is available only for staff of Tour
                    Companies. If you are using the app for a tour, please log
                    into the SafeTravels app
                  </div>
                </>
              )}
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default observer(MainLayout);
