import { FormikProps, FormikValues } from "formik";
import React, { FC, useCallback, useState } from "react";
import Input from "../../../../shared/Input/Input";
import { BrandTextAreaInputWithoutFormik } from "../../../../shared/Input/BrandTextAreaInput";
import { FormikCheck } from "../../../../shared/CustomCheckBox/CustomCheckbox";
import GenericHeader from "../../../../shared/Headers/GenericHeader";
import ReactAsyncSelectMenu from "../../../../shared/SelectMenus/ReactAsyncSelectMenu";
import { GAutoCompleteResponse } from "../../../../types/GoogleCloudAPI";
import googleAPIServices from "../../../../store/services/GoogleAPIServices";
import { SingleValue } from "react-select";
import debounce from "debounce-promise";
import InputHelper from "../../../../shared/Input/Inputhelper/InputHelper";
import IconButton from "../../../../shared/IconButton/IconButton";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import FormikSelectMenu from "../../../../shared/SelectMenus/FormikSelectMenu";
import { ActivityModal } from "../../../../store/models/ActivityModal";
import { EntityIdentifier } from "@devslane/mobx-entity-manager";
import moment from "moment";

interface Props {
  formikProps: FormikProps<FormikValues>;
  isEditing?: boolean;
  onFavouriteToggle?: (values: FormikValues) => void;
  favouritesActivities?: ActivityModal[];
}

const TripItineraryActivityDescription: FC<Props> = ({
  formikProps,
  isEditing,
  onFavouriteToggle,
  favouritesActivities,
}: Props) => {
  const [checkBox, setCheckBox] = useState(
    !!formikProps.initialValues.end_location.address &&
      formikProps.initialValues.end_location.address ===
        formikProps.initialValues.start_location.address
  );

  console.log(formikProps.initialValues);
  const getSuggestions = async (inputValue: string) => {
    const res: GAutoCompleteResponse | undefined =
      await googleAPIServices.autoCompleteService?.getPlacePredictions({
        input: inputValue,
      });
    return res?.predictions.map((p) => ({
      placeId: p.place_id,
      label: p.description,
    }));
  };

  const setNotesForStartEndLocation = (notes: string, key: string) => {
    formikProps.setFieldValue(key, {
      ...formikProps.values?.[key],
      notes: notes,
    });
  };

  const loadAutoCompleteOption = debounce(getSuggestions, 300);

  const onPlaceSelect = (
    v: SingleValue<{ placeId: string; label: string }>,
    key: string
  ) => {
    if (v?.placeId) {
      googleAPIServices.placeService?.getDetails(
        { placeId: v.placeId },
        async (result) => {
          formikProps.setFieldValue(key, {
            name: v.label,
            address: v.label,
            latitude: result.geometry.location.lat().toString(),
            longitude: result.geometry.location.lng().toString(),
          });
        }
      );
    } else {
      formikProps.setFieldValue(key, {});
    }
  };

  const onLibrarySelect = useCallback(
    (value: EntityIdentifier) => {
      const activity = favouritesActivities?.find((a) => a.id === value);
      if (activity) {
        formikProps.setValues({
          ...formikProps.values,
          ...activity,
          start: moment(activity.start).format("YYYY-MM-DD"),
          end: moment(activity.end).format("YYYY-MM-DD"),
          title: `Copy of ${activity.title}`,
          library: false,
          selectedLibrary: value,
        });
        if (
          !!activity?.end_location?.address &&
          !!activity?.start_location?.address &&
          activity.end_location.address === activity.start_location.address
        ) {
          setCheckBox(true);
        }
      }
    },
    [favouritesActivities, formikProps.values]
  );

  return (
    <div className="bg-white ">
      <div className=" mx-auto">
        <GenericHeader
          heading={"Activity Information"}
          subHeading={"Add Activity Information"}
        />
        <hr className="h-0.5 mt-6 bg-gray-200 mx-auto w-full" />

        <div className="px-16">
          <div className="w-9/12 flex flex-col space-y-10 mt-12 ">
            {!isEditing && (
              <>
                <div className="relative">
                  <div className="flex w-full items-center justify-between pb-4">
                    <p className="text-gray-800 font-bold ">
                      Copy from library
                    </p>
                  </div>
                  <div>
                    <FormikSelectMenu
                      name="selectedLibrary"
                      items={
                        favouritesActivities?.map((a) => ({
                          label: a.title,
                          value: a.id,
                        })) ?? []
                      }
                      onChange={onLibrarySelect}
                      placeholder="Select activity to make a copy from existing activity"
                    />
                  </div>
                </div>

                <hr className="h-0.5 mt-6 bg-gray-50 mx-auto w-full" />
              </>
            )}

            <div className="relative">
              <div className="flex w-full items-center justify-between pb-4">
                <p className="text-gray-800 font-bold ">Activity Overview</p>
                {isEditing && (
                  <div className="flex items-center space-x-2">
                    {/*<p>{formikProps.values.library ? "Remove from library": "Add to library"}</p>*/}
                    <IconButton
                      child={
                        !formikProps.values.library ? AiOutlineStar : AiFillStar
                      }
                      type={"button"}
                      onClick={() => {
                        onFavouriteToggle?.(formikProps.values);
                        formikProps.setFieldValue(
                          "library",
                          !formikProps.values.library
                        );
                      }}
                      buttonClassName="text-yellow-500 p-2"
                      className="h-6 w-6"
                    ></IconButton>
                    Add to Activity Library
                  </div>
                )}
              </div>
              <div>
                <Input
                  name={"title"}
                  label="Activity Name*"
                  helperText="Please enter the name of your Activity Type"
                  onChange={formikProps.handleChange}
                />
              </div>
            </div>

            <hr className="h-0.5 mt-6 bg-gray-50 mx-auto w-full" />

            <div>
              <div className="w-full items-center pb-4">
                <p className="text-gray-800 font-bold ">
                  Activity Start Information
                </p>
              </div>
              <div className={"flex flex-col space-y-4"}>
                <div className={"flex items-center justify-between"}>
                  <div className={"w-17/50"}>
                    <Input
                      name={"start"}
                      type="date"
                      label="Start Date*"
                      onChange={formikProps.handleChange}
                    />
                  </div>
                  <div className={"w-17/50"}>
                    <Input
                      name={"start_time"}
                      type="time"
                      label="Start Time*"
                      onChange={formikProps.handleChange}
                    />
                  </div>
                </div>

                <div className={""}>
                  <div>
                    <div>
                      <ReactAsyncSelectMenu
                        label={"Start Location*"}
                        name={"start_location"}
                        loadAutoCompleteOption={loadAutoCompleteOption}
                        onPlaceSelect={(v) =>
                          onPlaceSelect(v, "start_location")
                        }
                        defaultValue={
                          formikProps.values.start_location?.address || ""
                        }
                        defaultOption={
                          formikProps.values.start_location?.address || ""
                        }
                      />
                      {formikProps.errors["start_location"] &&
                        formikProps.touched["start_location"] && (
                          <InputHelper
                            type={"error"}
                            text={"Start Location is Required"}
                          />
                        )}
                    </div>
                  </div>
                </div>

                <div className={"mt-4"}>
                  <BrandTextAreaInputWithoutFormik
                    label="Notes"
                    labelClassName={"text-sm  text-gray-800 mb-2"}
                    placeholder={
                      "Addition notes for Start location, such as meeting point instructions"
                    }
                    onChange={(val) =>
                      setNotesForStartEndLocation(
                        val.target.value,
                        "start_location"
                      )
                    }
                    value={formikProps.values["start_location"]?.notes}
                  />
                </div>
              </div>
            </div>
            <hr className="h-0.5 mt-6 bg-gray-50 mx-auto w-full" />
            <div>
              <div className="w-full items-center pb-4">
                <p className="text-gray-800 font-bold ">
                  Activity Finish Information
                </p>
              </div>
              <div className={"flex flex-col space-y-4"}>
                <div className={"flex items-center justify-between"}>
                  <div className={"w-17/50"}>
                    <Input
                      name={"end"}
                      type="date"
                      label="Finish Date*"
                      onChange={formikProps.handleChange}
                    />
                  </div>
                  <div className={"w-17/50"}>
                    <Input
                      name={"finish_time"}
                      type="time"
                      label="Finish Time*"
                      onChange={formikProps.handleChange}
                    />
                  </div>
                </div>

                <div className={""}>
                  <div>
                    <div>
                      <ReactAsyncSelectMenu
                        label={"Finish Location*"}
                        name={"end_location"}
                        defaultValue={
                          checkBox
                            ? formikProps.values.start_location?.address || ""
                            : formikProps.values.end_location?.address
                        }
                        defaultOption={
                          checkBox
                            ? formikProps.values.start_location?.address || ""
                            : formikProps.values.end_location?.address
                        }
                        loadAutoCompleteOption={loadAutoCompleteOption}
                        onPlaceSelect={(v) => onPlaceSelect(v, "end_location")}
                      />
                      {formikProps.errors["end_location"] &&
                        formikProps.touched["end_location"] && (
                          <InputHelper
                            type={"error"}
                            text={"End Location is Required"}
                          />
                        )}
                    </div>
                  </div>
                </div>

                <FormikCheck
                  name={"checkbox"}
                  value={checkBox}
                  onChange={(e: any) => {
                    setCheckBox(e.target.checked);
                    if (e.target.checked) {
                      formikProps.setFieldValue(
                        "end_location",
                        formikProps.values.start_location
                      );
                    } else {
                      formikProps.setFieldValue("end_location", null);
                    }
                  }}
                >
                  <div className="text-sm text-gray-500">
                    {"End Location Same as Start"}
                  </div>
                </FormikCheck>

                <div className={"mt-4"}>
                  <BrandTextAreaInputWithoutFormik
                    label="Notes"
                    labelClassName={"text-sm  text-gray-800 mb-2"}
                    placeholder={
                      "Addition notes for End location, such as meeting point instructions"
                    }
                    onChange={(val) =>
                      setNotesForStartEndLocation(
                        val.target.value,
                        "end_location"
                      )
                    }
                    value={formikProps.values["end_location"]?.notes}
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <hr className="h-0.5 mt-6 bg-gray-50 mx-auto w-full" />
            <div className="w-full items-center pb-4">
              <p className="text-gray-800 font-bold ">
                Activity Button (optional)
              </p>
            </div>

            <div>
              <Input
                value={formikProps.values["button"]?.button_text}
                name={"button.button_text"}
                label="Button Name"
                helperText="Add a button to the activity that links to an external website."
                onChange={formikProps.handleChange}
              />
            </div>
            <div>
              <Input
                value={formikProps.values["button"]?.button_url}
                name={"button.button_url"}
                label="Button Url"
                helperText="Add the url of the button"
                onChange={formikProps.handleChange}
              />
            </div>
          </div>
        </div>

        <hr className="h-0.5 mt-6 bg-gray-200 mx-auto w-full" />

        <hr className="h-0.5 mt-6 bg-gray-50 mx-auto w-full" />
      </div>
    </div>
  );
};
export default TripItineraryActivityDescription;
