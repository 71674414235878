import React, { FunctionComponent, useEffect } from "react";
import { useHistory } from "react-router";
import { AppContext } from "../../App.context";
import { RouteConfig } from "../../constants/RouteConfig";
import { ERROR_WENT_WRONG } from "../../constants/message";
import logo from "../../assets/safetravels-logo.png";
import { Formik, FormikProps, FormikValues } from "formik";
import * as yup from "yup";
import Input from "../../shared/Input/Input";
import { BrandButton } from "../../shared/Button/BrandButton";
import Loader from "react-loader-spinner";
import hawaii from "../../assets/hawaii.png";

const VerifyEmail: FunctionComponent = () => {
  const history = useHistory();

  const token = new URLSearchParams(location.search).get("verifyToken");

  const { store, toast } = AppContext;
  useEffect(() => {
    const emailVerify = async () => {
      try {
        await store.userStore.verifyEmail({ token });
        toast.showSuccess("Account verified successfully!");
        // history.push(RouteConfig.login._ROOT);
      } catch (e: any) {
        toast.showError(e.message || ERROR_WENT_WRONG);
        // history.push(RouteConfig.login._ROOT);
      }
    };
    emailVerify();
  }, []);

  return (
    <div className="relative bg-white overflow-hidden">
      <div className="max-w-7xl mx-auto">
        <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
          <svg
            className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
            fill="currentColor"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            aria-hidden="true"
          >
            <polygon points="50,0 100,0 50,100 0,100" />
          </svg>

          <main className="pt-10 mx-auto max-w-7xl px-4 sm:pt-12 sm:px-6 md:pt-16 lg:pt-20 lg:px-8 xl:pt-28 h-screen">
            <a href="/home">
              <img className=" w-80 sm:h- mb-10" src={logo} />
            </a>

            <div className="sm:text-center lg:text-left">
              <h1 className="text-4xl tracking-tight font-bold text-gray-800 sm:text-5xl md:text-6xl">
                <p className="block xl:inline">
                  Thanks for verifying your account
                </p>{" "}
              </h1>
            </div>
          </main>
        </div>
      </div>
      <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 hidden md:block">
        <img
          className="h-56 w-full sm:h-72 md:h-96 lg:w-full lg:h-full object-cover"
          src={hawaii}
          alt=""
        />
      </div>
    </div>
  );
};

export default VerifyEmail;
