import React, { useEffect, useState } from "react";
import TripsSideBar, { TripSideBarItem } from "./TripsSideBar";
import {
  FaUserAlt,
  FaStackExchange,
  FaRegNewspaper,
  FaMapMarkerAlt,
  FaCalendarPlus,
  FaFilePdf,
  FaPhoneAlt,
  FaUsers,
  FaCommentDots,
} from "react-icons/fa";
import { RouteConfig } from "../../../constants/RouteConfig";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { last } from "lodash";
import TripsDocumentSection from "./TripsDocumentSection";
import TripContacts from "./TripContacts/TripContacts";
import TripStaffs from "./TripStaffs/TripStaffs";
import TripGuests from "./TripGuests/TripGuests";
import ChatSettings from "./ChatSettings/ChatSettings";
import TripItinerary from "./TripItineraryComponents/TripItinerary";
import TripOverview from "./TripOverview/TripOverview";
import { AppContext } from "../../../App.context";
import { observer } from "mobx-react";

enum TripsHomeSteps {
  OVERVIEW = "overview",
  STAFF = "staff",
  ITINERARY = "itinerary ",
  GUESTS = "guests",
  DOCUMENTS = "documents",
  CONTACTS = "contacts",

  CHAT = "chat_setting",
}

const tripSideBarItems: TripSideBarItem[] = [
  {
    label: "Trip Overview",
    value: TripsHomeSteps.OVERVIEW,
    icon: FaMapMarkerAlt,
  },
  { label: "Trip Staff", value: TripsHomeSteps.STAFF, icon: FaUserAlt },
  {
    label: "Trip Itinerary",
    value: TripsHomeSteps.ITINERARY,
    icon: FaCalendarPlus,
  },
  {
    label: "Trip Guests",
    value: TripsHomeSteps.GUESTS,
    icon: FaUsers,
  },
  {
    label: "Trip Documents",
    value: TripsHomeSteps.DOCUMENTS,
    icon: FaFilePdf,
  },
  {
    label: "Trip Contacts",
    value: TripsHomeSteps.CONTACTS,
    icon: FaPhoneAlt,
  },

  {
    label: "Chat Settings",
    value: TripsHomeSteps.CHAT,
    icon: FaCommentDots,
  },
];

const TripHomePage: React.FC = () => {
  const [currentStep, setCurrentStep] = useState<string>(
    TripsHomeSteps.OVERVIEW
  );

  const { store } = AppContext;
  const history = useHistory();

  const location = useLocation();
  const businessId = store.userStore.loggedInUser?.memberships?.items?.[0]?.id;
  const trip = store.tripStore.trip.item;

  const currentTripId = new URLSearchParams(location.search).get("id");
  const currentPathName = location.pathname;

  const tab = last(currentPathName?.split("/")) || TripsHomeSteps.OVERVIEW;

  const handleStepChange = (tab: TripsHomeSteps) => {
    history.replace({
      pathname: `${RouteConfig.tripsDashboard.TAB(tab)}`,
      search: `?id=${currentTripId}`,
    });
  };

  useEffect(() => {
    if (tab?.length) {
      setCurrentStep(tab);
    } else {
      history.push(RouteConfig.tripsDashboard._ROOT);
    }
  }, [currentPathName]);

  useEffect(() => {
    if (currentTripId) store.tripStore.getTrip(businessId!, currentTripId);
    else {
      history.push(RouteConfig.tripsDashboard._ROOT);
    }
  }, [currentTripId]);

  return (
    <div className="flex  w-full h-full  flex-row space-x-24">
      <div className="md:w-56 ">
        <div className="md:fixed z-10">
          <TripsSideBar
            handleChange={handleStepChange}
            currentSelectedStep={currentStep}
            sideBarItems={tripSideBarItems}
            heading={trip?.name || "Trip"}
            subHeading={trip?.location || ""}
          />
        </div>
      </div>
      <div className="w-full">
        {currentStep === TripsHomeSteps.STAFF && <TripStaffs />}
        {currentStep === TripsHomeSteps.OVERVIEW && (
          <TripOverview trip={trip} />
        )}
        {currentStep === TripsHomeSteps.ITINERARY && <TripItinerary />}
        {currentStep === TripsHomeSteps.DOCUMENTS && <TripsDocumentSection />}
        {currentStep === TripsHomeSteps.CONTACTS && <TripContacts />}
        {currentStep === TripsHomeSteps.GUESTS && <TripGuests />}
        {currentStep === TripsHomeSteps.CHAT && <ChatSettings />}
      </div>
    </div>
  );
};

export default observer(TripHomePage);
