import classNames from "classnames";
import { isArray } from "lodash";
import React, { useCallback, useMemo, useState } from "react";

import useOutsideClickDetector from "../../Hooks/useOutsideClickDetector";
import InputLabel from "../Input/InputLabel/InputLabel";
import Icon from "../Icon/Icon";
import { FaAngleDown, FaSearch } from "react-icons/fa";
import { AutoCompleteItemType } from "../../types/AutoComplete.type";

export type SelectMenuType = "default" | "multiple";

interface SelectMenuProps {
  label?: string;
  items: AutoCompleteItemType[];
  selected: number | number[];
  selectedString?: any;
  placeholder?: string;
  type?: SelectMenuType;
  autoComplete?: boolean;
  onChange: (value: number | number[] | any[] | boolean) => void;
  isSearch?: boolean;
  notRounded?: boolean;
  name?: string;
  allowClear?: boolean;
  allowAddItem?: boolean;
  disabled?: boolean;
}

const SelectMenu: React.FC<SelectMenuProps> = (props) => {
  const { label } = props;

  const [open, setOpen] = useState<boolean>(false);
  const [search, setSearch] = useState<string>();

  const wrapperRef = useOutsideClickDetector<HTMLDivElement>(() =>
    setOpen(false)
  );

  const selectedItem = useMemo(() => {
    const selectedItems = props.selected;
    if (isArray(selectedItems)) {
      return (props.items || []).filter((item: AutoCompleteItemType) =>
        selectedItems.includes(item.value as any)
      );
    }
    return (props.items || []).find(
      (item: AutoCompleteItemType) => item.value === selectedItems
    );
  }, [props.selected, props.items]);

  const renderSelectedItem = () => {
    const selected = selectedItem;
    if (selected && isArray(selected) && selected.length) {
      const items = selected
        .map((item: AutoCompleteItemType) => item.label)
        .join(", ");
      return (
        <span className="block truncate text-gray-800">
          {items.slice(0, 12).concat("...")}
        </span>
      );
    }
    if (selected && !isArray(selected)) {
      return (
        <>
          {/*{selected?.avatar && (*/}
          {/*  <ImageDefaultPreview*/}
          {/*    imageProps={{*/}
          {/*      id: 1,*/}
          {/*      className: "w-6 h-6 flex-shrink-0 rounded-full ",*/}
          {/*    }}*/}
          {/*    imageSrc={selected.avatar || ""}*/}
          {/*    defaultImageSrc={"/ProfilePlaceholder.jpg"}*/}
          {/*  />*/}
          {/*)}*/}
          <span className="block truncate text-gray-500">
            {selected?.label}
          </span>
        </>
      );
    }
    if (props.selectedString) {
      return (
        <>
          <span className="block truncate text-gray-800">
            {props.selectedString}
          </span>
        </>
      );
    }
    return (
      <span className="block truncate opacity-50 text-brandPlaceholder">
        {props.placeholder || "Select..."}
      </span>
    );
  };

  const handleChange = useCallback(
    async (value: number | string) => {
      setOpen(false);

      setSearch("");

      if (props.type === "multiple") {
        const previousSelected = props.selected;
        if (isArray(previousSelected)) {
          props.onChange([...previousSelected, value]);
          return;
        }
        props.onChange([previousSelected, value]);
        return;
      }

      props.onChange(value as any);
      return;
    },
    [props.type, props.selected]
  );

  const filteredList: AutoCompleteItemType[] = useMemo(() => {
    if (!search) return props.items;
    return props.items.filter((item: AutoCompleteItemType) =>
      item.label.toLowerCase().includes((search || "").toLowerCase().trim())
    );
  }, [props.items, search]);

  return (
    <div className="w-full">
      {label && (
        <InputLabel label={label} className={` text-sm  text-gray-800 mb-2`} />
      )}
      <div
        className={`w-full border border-gray-300  bg-transparent ${
          !props.notRounded ? "rounded-md " : "rounded-none"
        }`}
      >
        <div
          className={`flex flex-row items-center justify-between `}
          onKeyPress={(): void => setOpen(!open)}
          onClick={(): void => setOpen(!open)}
          onKeyDown={(): void => setOpen(!open)}
        >
          <div className="w-11/12">
            <button
              type="button"
              name={props.name}
              aria-haspopup="listbox"
              aria-expanded="true"
              aria-labelledby="listbox-label"
              className={`px-3 py-2  cursor-default text-left bg-transparent w-full  transition ease-in-out duration-150 ${
                !props.notRounded ? "rounded-md " : "rounded-none"
              }`}
            >
              <div className="flex items-center space-x-3">
                {renderSelectedItem()}
              </div>
            </button>
          </div>
          <div className="flex items-center mr-6 ">
            {props.isSearch ? (
              <Icon value={FaSearch} />
            ) : (
              <div className={"flex flex-col gap-y-1 flex-shrink-0 "}>
                <Icon value={FaAngleDown} />
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className="relative flex flex-row shadow-sm rounded-md "
        ref={wrapperRef}
      >
        {open && (
          <div
            className="absolute mt-0.5 w-full bg-white rounded-md shadow-lg"
            style={{ zIndex: 101 }}
          >
            <ul
              tabIndex={-1}
              role="listbox"
              aria-labelledby="listbox-label"
              aria-activedescendant="listbox-item-3"
              className={` bg-brandBGNew text-gray-800 font-medium focus:outline-none focus:ring-blue focus:border-blue-300 rounded-md py-1 overflow-auto `}
              style={{ maxHeight: "252px" }}
            >
              {props.autoComplete && (
                <li
                  key={-1}
                  role="option"
                  className={classNames(
                    "text-gray-800 select-none py-2 pl-8 pr-4"
                  )}
                >
                  <input
                    type="text"
                    value={search}
                    placeholder={"Search.."}
                    onChange={(e) => setSearch(e?.target?.value)}
                    className={`w-full px-3 py-2 border border-brandBorderNew bg-brandBGNew ${
                      !props.notRounded ? "rounded-md " : "rounded-none"
                    }`}
                  />
                </li>
              )}
              {props.allowClear && (
                <li
                  key={-2}
                  role="option"
                  onClick={() => handleChange("clear")}
                  className={classNames(
                    "text-gray-800 select-none relative hover:text-white py-2 pl-8 pr-4 hover:bg-brandSignupPrimary"
                  )}
                >
                  Clear
                </li>
              )}

              {props.allowAddItem &&
                search &&
                !(filteredList || [])
                  .map((item: AutoCompleteItemType) =>
                    item.label.toLowerCase().trim()
                  )
                  .includes(search.toLowerCase().trim()) && (
                  <li
                    key={-2}
                    role="option"
                    onClick={() => handleChange(search)}
                    className={classNames(
                      "text-gray-800 select-none relative hover:text-white py-2 pl-8 pr-4 hover:bg-brandPrimaryBlue"
                    )}
                  >
                    {`Add ${search}`}
                  </li>
                )}
              {(filteredList || []).map((item, index) => {
                return (
                  <li
                    key={index}
                    onClick={() =>
                      item.value === "groupHeading"
                        ? null
                        : handleChange(item.value as any)
                    }
                    role="option"
                    className={classNames(
                      "text-gray-800 select-none relative hover:text-white py-2 pl-8 pr-4",
                      {
                        "bg-brandBGNew": item.value === ("groupHeading" as any),
                      },
                      {
                        "hover:bg-brandPrimaryBlue":
                          item.value !== ("groupHeading" as any),
                      }
                    )}
                  >
                    {/*{item.avatar && (*/}
                    {/*  <ImageDefaultPreview*/}
                    {/*    imageProps={{*/}
                    {/*      id: 1,*/}
                    {/*      className: "w-6 h-6 flex-shrink-0 rounded-full ",*/}
                    {/*    }}*/}
                    {/*    imageSrc={item.avatar || ""}*/}
                    {/*    defaultImageSrc={"/ProfilePlaceholder.jpg"}*/}
                    {/*  />*/}
                    {/*)}*/}
                    {item.value === "groupHeading" ? (
                      <span className="font-medium opacity-50 text-bannerDarkBlue">
                        {item.label}
                      </span>
                    ) : (
                      item.label
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectMenu;
