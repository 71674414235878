import React, { useCallback, useEffect, useState } from "react";
import Modal from "../../../shared/Modal/Modal";

import { FaEdit, FaTrashAlt } from "react-icons/fa";
import Table from "../../../shared/Table/Table";
import GenericHeader from "../../../shared/Headers/GenericHeader";
import AddTripsDocumentModal from "./AddTripsDocumentModal";
import { AppContext } from "../../../App.context";
import { observer } from "mobx-react";
import IconButton from "../../../shared/IconButton/IconButton";
import { ImageModel } from "../../../store/models/ImageModel";
import { EntityIdentifier } from "@devslane/mobx-entity-manager";
import { ERROR_WENT_WRONG } from "../../../constants/message";
import { InputWithoutFormik } from "../../../shared/Input/Input";
import Loader from "react-loader-spinner";
import { debounce } from "lodash";
import CustomCheckbox from "../../../shared/CustomCheckBox/CustomCheckbox";

const TripsDocumentSection: React.FC = () => {
  const { store, toast } = AppContext;
  const [isPriavte, setIsPriavte] = useState(false);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const [editData, setEditData] = useState<ImageModel | undefined>();
  const currentTripId = new URLSearchParams(location.search).get("id");

  const documentList = store.imageStore.documentList.items;

  const [filterDocument, setFilterDocument] =
    useState<ImageModel[]>(documentList);

  const staffTable = {
    headingsArray: ["Document", "Actions"]
  };
  const businessId = store.userStore.loggedInUser?.memberships?.items?.[0]?.id;

  const handleDelete = async (id: EntityIdentifier) => {
    try {
      if (confirm("Are you sure you want to delete this Document ?")) {
        await store.imageStore.deleteDocument(
          currentTripId as any,
          businessId!,
          id
        );
        toast.showSuccess("Document Deleted Successfully");
      }
    } catch (e: any) {
      toast.showError(e.message || ERROR_WENT_WRONG);
    }
  };

  const getUploadedFileName = (url: string): string | undefined => {
    const _chunk1 = url.split(".com/");
    const _chunks = _chunk1?.[1]?.split("/");
    return _chunks?.filter((item: string, index: number) => !!index).join("/");
  };

  const handleFileOpen = useCallback((url: string) => {
    const a = document.createElement("a");
    a.href = url;
    a.target = "_blank";
    a.rel = "noreferrer noopener";
    a.download = getUploadedFileName(url) || "newFile.pdf";
    a.click();
    return;
  }, []);
  const dataArray: any = (filterDocument || [])?.map((image) => {
    return [
      <div
        className="text-17px text-brandSkyBlue cursor-pointer mt-6"
        onClick={() => handleFileOpen(image.url || "")}
      >
        {
          image.private ? <p>{image.name}<span className="px-1">(private)</span></p> : <p>{image.name}</p>
        }
      </div>,
      <div className={"flex space-x-3 items-center"}>
          {/* {image.private && <div style={{marginTop: '-20px'}} ><CustomCheckbox
            name={"checkbox"}
            value={true}
            disabled={true}
            onChange={(e: any) => {
              setIsPriavte(true)              
            }}
          /></div>} */}

        <IconButton
          child={FaEdit}
          className="w-4 h-4"
          onClick={() => setEditData(image)}
          buttonClassName={"text-white rounded-full p-2 bg-success-500"}
        />

        <div className="">
          <IconButton
            child={FaTrashAlt}
            onClick={() => handleDelete(image.id)}
            className="w-4 h-4"
            buttonClassName={" text-white rounded-full p-2 bg-error-500"}
          />
        </div>
      </div>,
    ];
  });

  const closeModal = () => {
    setEditData(undefined);
    setOpenModal(false);
  };

  const ifSearchIncludes = (data: ImageModel, searchKey: string) => {
    const searchCriteriaArray: Array<any> = [data.name?.trim()?.toLowerCase()];
    if (
      searchCriteriaArray?.some((val) =>
        val?.includes(searchKey?.trim()?.toLowerCase())
      )
    )
      return true;

    return false;
  };

  const handleSearch = debounce((searchKey: string): void => {
    const filtered = documentList.filter((data) => {
      return ifSearchIncludes(data, searchKey);
    });

    setFilterDocument(filtered);
  }, 200);

  useEffect(() => {
    store.imageStore.loadDocuments(currentTripId as any, businessId!);
  }, []);

  useEffect(() => {
    setFilterDocument(documentList);
  }, [documentList]);

  return (
    <>
      <Modal
        modalStyle={{ width: "50rem", maxHeight: "95%", overflowY: "auto" }}
        open={openModal || !!editData}
        onOutsideClick={closeModal}
      >
        <AddTripsDocumentModal modalHandler={closeModal} editData={editData} />
      </Modal>

      <div className="bg-white">
        <div className="pb-8">
          <GenericHeader
            heading={"Trip Documents"}
            subHeading={
              "Add Documents that can be used in your Trips and Activities"
            }
            buttonOnClick={() => setOpenModal(true)}
            buttonText={"Add Documents"}
          />
          <hr className="h-0.5 mt-2 bg-gray-50 mx-auto w-full" />

          <div className={"px-8 mt-4"}>
            <InputWithoutFormik
              type="text"
              placeholder="Search Keyword"
              onChange={(e) => handleSearch(e.target.value || "")}
            />
          </div>
          <div className="mt-6 w-full px-8">
            {store.imageStore.documentList.loading ? (
              <div className={"flex justify-center mx-auto"}>
                <Loader type="BallTriangle" height={30} width={30} />
              </div>
            ) : (
              <Table headers={staffTable.headingsArray} data={dataArray} />
            )}
          </div>
          <hr className="h-0.5 mt-10 bg-gray-50 mx-auto w-full" />
        </div>
      </div>
    </>
  );
};

export default observer(TripsDocumentSection);
