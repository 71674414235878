import React from "react";
import { useFormikContext } from "formik";
// import {InputProps} from "./Input/InputProps";

// TODO: Below HOC take proptype as an argument

const withFormikContext = (WrappedComponent: React.ComponentType<any>) => {
  const wrapper: React.FC<{ name: string } & Partial<any>> = ({
    name,
    ...rest
  }) => {
    const context: any = useFormikContext();

    const wrappedProps = {
      name,
      value: context.values[name],
      onChange: context.handleChange,
      onBlur: context.handleBlur,
      error: context.touched[name] && context.errors[name],
      ...rest,
    };
    return <WrappedComponent {...wrappedProps} />;
  };

  return wrapper;
};

export default withFormikContext;
