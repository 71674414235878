import {
  BaseModel,
  EntityBaseModel,
  EntityIdentifier,
} from "@devslane/mobx-entity-manager";
import { observable } from "mobx";
import { ArticleStore } from "store/entities/ArticleStore";
import { Utils } from "../../Utils/Utils";

export class ContactModel extends BaseModel {
  @observable
  _id!: EntityIdentifier;
  @observable
  name!: string;
  @observable
  number!: string;
  @observable
  location?: string;
  @observable
  email?: string;
  @observable
  hours?: string;
  toJSON() {
    return Utils.sanitizeObject({
      id: this.id,
      name: this.name,
      number: this.number,
      location: this.location,
      email: this.email,
      hours: this.hours,
    });
  }
}
