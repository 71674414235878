import React, { useEffect, useState } from "react";
import TripItineraryActivityAdd from "./TripItineraryActivityAdd";
import TripCalender from "./TripCalender";
import { AppContext } from "../../../../App.context";
import { observer } from "mobx-react";
import { EventClickArg } from "@fullcalendar/common";
import { ActivityModal } from "../../../../store/models/ActivityModal";
import GenericHeader from "shared/Headers/GenericHeader";
import moment from "moment";
const TripItinerary: React.FC = () => {
  const { store } = AppContext;

  const [selectedEvent, setSelectedEvent] = useState<any>();
  const currentTripId = new URLSearchParams(location.search).get("id");

  const [eventForEdit, setEventForEdit] = useState<ActivityModal>();

  const businessId = store.userStore.loggedInUser?.memberships?.items?.[0]?.id;
  const trip = store.tripStore.trip.item;
  const tripLoading = store.tripStore.trip.loading;
  const tripStartDate = trip?.overview?.item.start_date;
  const tripEndDate = trip?.overview?.item.end_date;

  const handleDateClick = (arg: any) => {
    setSelectedEvent(arg);
  };

  const handleOnEventClick = (arg: EventClickArg) => {
    const selectedEventForEdit =
      store.tripStore.currentTripActivities.items?.filter(
        (activity) => activity.id === arg.event?.id
      );

    setEventForEdit(selectedEventForEdit?.[0]);
  };

  const handleClose = () => {
    setSelectedEvent(undefined);
    setEventForEdit(undefined);
  };

  useEffect(() => {
    store.tripStore.loadCurrentTripActivities(
      currentTripId as any,
      businessId!
    );
  }, [businessId]);

  const events = store.tripStore.currentTripActivities.items?.map(
    (activity) => ({
      start: activity.start,
      title: activity.title,
      end: activity.end,
      id: activity.id,
    })
  );

  return (
    <div className="">
      {selectedEvent || eventForEdit ? (
        <>
          <TripItineraryActivityAdd
            handleClose={handleClose}
            selectedEvent={selectedEvent}
            eventForEdit={eventForEdit}
          />
        </>
      ) : (
        <TripCalender
          handleDateClick={handleDateClick}
          events={events}
          onEventClick={handleOnEventClick}
          startDate={tripStartDate}
          endDate={tripEndDate}
          loading={tripLoading}
        />
      )}
    </div>
  );
};

export default observer(TripItinerary);
