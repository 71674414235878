import React, { FunctionComponent, ReactNode } from "react";
import { nanoid } from "nanoid";
// import NoEntries from "assets/no_data.svg";

interface OwnProps {
  headers: string[];
  headerIsLink?: boolean;
  OnHeaderClick?: (e?: any) => void;
  data: ReactNode[][];
}

type Props = OwnProps;

const Table: FunctionComponent<Props> = ({
  headers,
  data,
  OnHeaderClick,
  headerIsLink,
}) => (
  <table className="table p-4 bg-white min-h-48 w-full">
    <thead className="sticky top-0 bg-white  w-full z-10">
      <tr className="text-left">
        {headers.map((header) => (
          <th
            key={header}
            className="p-4 dark:border-dark-5 whitespace-nowrap font-bold uppercase text-gray-700 text-sm"
          >
            {header}
          </th>
        ))}
      </tr>
    </thead>
    <tbody className="relative">
      {data.length === 0 && (
        <tr className="block absolute top-4 left-1/2 transform -translate-x-1/2">
          <td>
            {/*<img alt="no data found" src={NoEntries} className="h-96" />*/}
            <p className="text-center mt-4">
              Oops😥, there is no information to display. Add new entries using
              add (<strong className="text-lg text-sucess-600">+</strong>)
              button.
            </p>
          </td>
        </tr>
      )}
      {data.map((row, index) => (
        <tr
          className={`text-gray-700 ${headerIsLink ? "cursor-pointer" : ""}`}
          onClick={() => OnHeaderClick && OnHeaderClick(index)}
          key={nanoid()}
        >
          {row.map((column) => (
            <td className={`border-b-2 p-4`} key={nanoid()}>
              {column}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  </table>
);

export default Table;
