import { ChildStore } from "../ChildStore";
import { Context } from "../Context";
import { RootStore } from "../RootStore";
import { ImageModel } from "../models/ImageModel";
import { action, observable } from "mobx";
import {
  EntityIdentifier,
  ModelJson,
  ModelList,
} from "@devslane/mobx-entity-manager";
import { imageService } from "../services/api-services/ImageService";
import { map } from "lodash";
import { AppContext } from "App.context";

export class ImageStore extends ChildStore<ImageModel> {
  constructor(rootStore: RootStore) {
    super(rootStore);
    ImageModel._store = this;
  }

  @observable imagesList: ModelList<ImageModel> = new ModelList<ImageModel>(
    ImageModel
  );
  @observable documentList: ModelList<ImageModel> = new ModelList<ImageModel>(
    ImageModel
  );
  @action
  async updateImage(
    business_id: EntityIdentifier,
    data: {
      _id: EntityIdentifier;
      url: string;
      name: string;
      tags: Array<string>;
    }
  ): Promise<any> {
    return await imageService.updateImage(business_id, data).then((res) => {
      this.imagesList.deserialize(
        map(res.data, (image) => image.toJSON()) as ModelJson[]
      );
      return res.message;
    });
  }



  @action
  async updateDocument(
    trip_id: EntityIdentifier,
    business_id: EntityIdentifier,
    data: {
      private: boolean;
      _id: EntityIdentifier;
      url: string;
      name: string;
      private_guests?: any[];
    }
  ): Promise<any> {
    return await imageService
      .updateDocument(trip_id, business_id, data)
      .then((res) => {
        this.documentList.deserialize(
          map(res.data, (image) => image.toJSON()) as ModelJson[]
        );
        return res.message;
      });
  }
  @action
  async deleteDocument(
    trip_id: EntityIdentifier,
    business_id: EntityIdentifier,
    document_id: EntityIdentifier
  ): Promise<any> {
    return await imageService
      .deleteDocument(trip_id, business_id, document_id)
      .then((res) => {
        this.documentList.deserialize(
          map(res.data, (image) => image.toJSON()) as ModelJson[]
        );
        return res.message;
      });
  }
  @action
  async deleteImage(data: {
    business_id: EntityIdentifier;
    image_id: EntityIdentifier;
  }): Promise<any> {
    try {
      return await imageService.deleteImage(data).then((res) => {
        const allItems = this.imagesList.items.map((image: ImageModel) =>
          image.toJSON()
        );
        const imagesFiltered = allItems.filter(
          (image) => data.image_id !== image.id
        );
        this.imagesList.deserialize(imagesFiltered as ModelJson[]);

        return res.message;
      });
    } catch (e) {
      throw e;
    }
  }

  @action
  async addImage(data: {
    business_id: EntityIdentifier;
    name: string;
    url: string;
    tags: Array<string>;
  }): Promise<any> {
    try {
      return await imageService.addImage(data).then((res) => {
        const imageResponse = res.data?.[res.data.length - 1];
        //appending new Image
        const image = {
          id: imageResponse?.id,
          name: imageResponse?.name,
          url: imageResponse?.url,
          tags: imageResponse?.tags,
        };

        const imageData = ImageModel.fromJson(image);
        this.imagesList.appendItem(imageData as ImageModel);
        return res.data;
      });
    } catch (e) {
      throw e;
    }
  }

  @action
  async addDocument(data: {
    private: boolean,
    trip_id: EntityIdentifier;
    business_id: EntityIdentifier;
    name: string;
    url: string;
    private_guests?: any[];
  }): Promise<any> {
    try {
      debugger
      return await imageService.addDocument(data).then((res) => {
        const imageResponse = res.data?.[res.data.length - 1];
        //appending new Image
        const image = {
          id: imageResponse?.id,
          name: imageResponse?.name,
          url: imageResponse?.url,
        };

        const imageData = ImageModel.fromJson(image);
        this.documentList.appendItem(imageData as ImageModel);
        return res.data;
      });
    } catch (e) {
      throw e;
    }
  }

  @action
  async loadImages(business_id: EntityIdentifier, params?: any) {
    try {
      this.imagesList.load(
        `business/images?business_id=${business_id}`,
        params
      );
    } catch (e) {
      this.imagesList = new ModelList<ImageModel>(ImageModel);
      throw e;
    }
  }

  @action
  async loadDocuments(
    trip_id: EntityIdentifier,
    business_id: EntityIdentifier
  ): Promise<any> {
    try {
      this.documentList.setLoading(true);
      return await imageService
        .loadDocuments(trip_id, business_id)
        .then((res) => {
          this.documentList.deserialize(
            map(res.data, (staff) => staff.toJSON()) as ModelJson[]
          );
          this.documentList.setLoading(false);
          return res;
        });
    } catch (e) {
      this.documentList.setLoading(false);
      throw e;
    }
  }
  reset() {
    this.entities.clear();
    Context.storage.reset();
  }
}
