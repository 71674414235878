import React, { FC, useEffect, useMemo, useState } from "react";
import GenericHeader from "../../../../shared/Headers/GenericHeader";
import { EntityIdentifier } from "@devslane/mobx-entity-manager";
import Modal from "../../../../shared/Modal/Modal";
import AddImageModal from "../../ImageGallery/AddImageModal";
import Loader from "react-loader-spinner";
import Table from "../../../../shared/Table/Table";
import CustomCheckbox from "../../../../shared/CustomCheckBox/CustomCheckbox";
import { AppContext } from "App.context";
import { ImageModel } from "store/models/ImageModel";
import { InputWithoutFormik } from "shared/Input/Input";
import { BrandButton } from "shared/Button/BrandButton";
import debounce from "debounce-promise";

interface Props {
  selectedImages: Array<{ id: EntityIdentifier; url: string }>;
  images: Array<{ id: EntityIdentifier; url: string }>;
  handleAddRemoveImage: (
    value: { id: EntityIdentifier; url: string },
    type: "add" | "remove"
  ) => void;
  loading?: boolean;
}
const staffTable = {
  headingsArray: ["Image", "Image Name", "Image Tags", "Select Image"],
};
const tableHeadings = ["Image", "Selected"];
const TripItineraryImages: FC<Props> = ({
  selectedImages,
  handleAddRemoveImage,
  images,
  loading,
}) => {
  console.log(selectedImages);
  const { store } = AppContext;
  const businessId = store.userStore.loggedInUser?.memberships?.items?.[0]?.id;
  const imageList = store.imageStore.imagesList.items;
  console.log(imageList);
  const [filterImages, setFilterImages] = useState<ImageModel[]>(imageList);
  const selectedImagesId = selectedImages?.map((image) => image.id);

  const [openModal, setOpenModal] = useState<boolean>(false);
  // const [filterImages, setFilterImages] = useState<
  //   { id: EntityIdentifier; url: string }[]
  // >([]);

  useEffect(() => {
    const allImages = selectedImages.slice();
    images.forEach((image) => {
      if (!allImages.find((img) => img.url === image.url)) {
        allImages.push(image);
      }
    });
    setFilterImages(imageList);
  }, [selectedImages, imageList]);

  const isImageSelected = (value: {
    id: EntityIdentifier;
    url: string;
  }): boolean => {
    return !!selectedImages.some((image) => image.url === value.url);
  };
  const dataArray: any = (filterImages || [])?.map((image) => {
    return [
      <img alt="img" src={image.url || ""} className=" h-20 w-20 " />,
      image.name,
      <span>
        <div className={"flex flex-wrap "}>
          {image?.tags?.map((val: any, index: number) => (
            <span>
              {val}
              {(image?.tags?.length || 0) - 1 !== index && ","}
            </span>
          ))}
        </div>
      </span>,

      <CustomCheckbox
        name="selectedImage"
        value={isImageSelected(image)}
        onChange={() => {
          console.log("image selected", isImageSelected(image));

          if (isImageSelected(image)) {
            handleAddRemoveImage(image, "remove");
          } else {
            handleAddRemoveImage(image, "add");
          }
        }}
      />,
    ];
  });

  const ifSearchIncludes = (data: ImageModel, searchKey: string) => {
    const searchCriteriaArray: Array<any> = [
      data.name?.trim()?.toLowerCase(),
      ...(data.tags || []).map((tag) => tag?.trim()?.toLowerCase()),
    ];
    return !!searchCriteriaArray?.some((val) =>
      val?.includes(searchKey?.trim()?.toLowerCase())
    );
  };

  const handleSearch = debounce((searchKey: string): void => {
    const filtered = imageList.filter((data) => {
      return ifSearchIncludes(data, searchKey);
    });

    setFilterImages(filtered);
  }, 200);

  useEffect(() => {
    store.imageStore.loadImages(businessId!);
  }, []);

  return (
    <div className="bg-white">
      <div className="pb-8">
        <div className="flex items-center justify-end px-8">
          <Modal
            modalStyle={{ width: "50rem", maxHeight: "95%", overflowY: "auto" }}
            open={openModal}
            onOutsideClick={() => setOpenModal(false)}
          >
            <AddImageModal modalHandler={() => setOpenModal(false)} />
          </Modal>
          <BrandButton
            className=" text-base font-semibold "
            type="button"
            onClick={() => setOpenModal(true)}
            theme="primary"
            size={"lg"}
          >
            Add New Image
          </BrandButton>
        </div>
        <div className={"px-8 mt-4"}>
          <InputWithoutFormik
            type="text"
            placeholder="Search Keyword"
            onChange={(e) => handleSearch(e.target.value || "")}
          />
        </div>
        <div className="mt-6 w-full px-8">
          {store.imageStore.imagesList.loading ? (
            <div className={"flex justify-center mx-auto"}>
              <Loader type="BallTriangle" height={30} width={30} />
            </div>
          ) : (
            <Table headers={staffTable.headingsArray} data={dataArray} />
          )}
        </div>
        <hr className="h-0.5 mt-10 bg-gray-50 mx-auto w-full" />
      </div>
    </div>
  );
};
export default TripItineraryImages;
