import { FormikProps, FormikValues, Formik } from "formik";
import React, { FC, useEffect, useState } from "react";
import * as yup from "yup";
import BrandTextAreaInput from "../../../shared/Input/BrandTextAreaInput";
import Input from "../../../shared/Input/Input";
import { AppContext } from "../../../App.context";
import { BrandButton } from "../../../shared/Button/BrandButton";
import { observer } from "mobx-react";
import { ERROR_WENT_WRONG } from "../../../constants/message";
import Loader from "react-loader-spinner";
import moment from "moment";
import FilePicker from "../../../shared/FilePicker/FilePicker";
import { UploadStatus } from "../../../constants/enums/UploadStatus";
import { FileService } from "../../../store/services/api-services/FileService";
import GenericHeader from "shared/Headers/GenericHeader";

const Profile: FC = () => {
  const { store, toast } = AppContext;

  const loggedInUser = store.userStore.loggedInUser;
  console.log("loggedinUSer ", loggedInUser?.firstName);

  const [uploadedUrls, setUploadedUrls] = useState<{
    url: string;
    uploading: UploadStatus;
  }>({
    url: "",
    uploading: UploadStatus.NOT_STARTED,
  });

  const [image, setImage] = useState<{ preview: string }>({
    preview: loggedInUser?.avatar || "",
  });

  const handleUploadProfilePic = async (file: File, formikProps: any) => {
    if (file) {
      try {
        toast.showInfo("Avatar Upload Start");
        setUploadedUrls((prev) => ({
          ...prev,
          uploading: UploadStatus.IN_PROGRESS,
        }));
        const res = await FileService.uploadFile(file);
        setUploadedUrls((prev) => ({
          ...prev,

          url: res.url || "",
        }));
        formikProps.setFieldValue("avatar", res.url);
        setUploadedUrls((prev) => ({ ...prev, uploading: UploadStatus.DONE }));
        toast.showSuccess("Avatar Uploaded Successfully");
      } catch (e: any) {
        toast.showError("Avatar Upload Fail");
        setUploadedUrls((prev) => ({
          ...prev,
          uploading: UploadStatus.NOT_STARTED,
        }));
      }
    } else {
      setUploadedUrls((prev) => ({ url: "", uploading: UploadStatus.DONE }));
    }
  };

  const handleImagePreview = (file: File | undefined, formikProps: any) => {
    setImage({
      preview: file ? URL.createObjectURL(file) : "",
    });

    if (file) {
      handleUploadProfilePic(file, formikProps);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        firstName: loggedInUser?.firstName || "",
        lastName: loggedInUser?.lastName || "",
        email: loggedInUser?.email || "",
        avatar: loggedInUser?.avatar || "",
        date_of_birth: loggedInUser?.date_of_birth
          ? moment(loggedInUser?.date_of_birth).format("YYYY-MM-DD")
          : "",
      }}
      validationSchema={yup.object().shape({
        firstName: yup.string().trim().required("First Name Is Required"),
        lastName: yup.string().trim().required("First Name Is Required"),
        email: yup.string().required().email("Please enter a valid email"),
      })}
      onSubmit={async (data, formikHelpers) => {
        formikHelpers.setSubmitting(true);
        try {
          await store.userStore.updateUser(data);
          toast.showSuccess("User Updated Successfully!");
          formikHelpers.setSubmitting(false);
        } catch (e: any) {
          formikHelpers.setSubmitting(false);
          toast.showError(e.message || ERROR_WENT_WRONG);
        }
      }}
    >
      {(formikProps: FormikProps<FormikValues>) => (
        <form onSubmit={formikProps.handleSubmit}>
          <div className="bg-white px-8 pb-8">
            <div className="container mx-auto bg-white  rounded">
              <div className="w-full border-b border-gray-300 dark:border-gray-700 py-5 bg-white">
                <GenericHeader
                  heading={"Profile"}
                  subHeading={
                    "To update all of your profile information, please use the app."
                  }
                />
              </div>
              <div className="mx-10">
                <div className="w-9/12 flex flex-col space-y-8 mt-12">
                  <div className={"flex justify-center"}>
                    <FilePicker
                      handleFileUpdate={(file) =>
                        handleImagePreview(file, formikProps)
                      }
                      btnText="Avatar"
                      fileTypes={"image/*"}
                      btnType="secondary"
                      uploading={
                        uploadedUrls.uploading === UploadStatus.IN_PROGRESS
                      }
                      disabled={formikProps.isSubmitting}
                      src={image.preview || loggedInUser?.avatar || ""}
                      name={"avatar"}
                    />
                  </div>
                  <div className={"flex items-center space-x-8"}>
                    <div className="w-1/3">
                      <Input
                        name={"firstName"}
                        label="First Name"
                        onChange={formikProps.handleChange}
                      />
                    </div>
                    <div className="w-1/3">
                      <Input
                        name={"lastName"}
                        label="Last Name"
                        onChange={formikProps.handleChange}
                      />
                    </div>

                    <div className="w-1/3">
                      <Input
                        name={"date_of_birth"}
                        label="Date Of Birth"
                        type={"date"}
                        onChange={formikProps.handleChange}
                      />
                    </div>
                  </div>
                  <div>
                    <Input
                      name={"email"}
                      type="email"
                      label="Email Address*"
                      disabled={true}
                      onChange={formikProps.handleChange}
                    />
                  </div>

                  <div className={"mt-10 flex items-center justify-start"}>
                    <BrandButton
                      className=" text-base font-semibold "
                      disabled={formikProps.isSubmitting}
                      theme="primary"
                      size={"xl"}
                    >
                      Update Details
                    </BrandButton>
                    {formikProps.isSubmitting && (
                      <span className="ml-3">
                        <Loader type="BallTriangle" height={30} width={30} />
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};
export default observer(Profile);
