import React, { FC, useState } from "react";
/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
// import { MenuIcon, XIcon } from '@heroicons/react/outline'
import {
  AnnotationIcon,
  GlobeIcon,
  LightningBoltIcon,
  ScaleIcon,
  SpeakerphoneIcon,
  XIcon,
  MenuIcon,
} from "@heroicons/react/outline";
import Modal from "../../../shared/Modal/Modal";
import logo from "../../../assets/safetravels-logo.png";
import hawaii from "../../../assets/hawaii.png";
interface Props {
  modalHandler: () => void;
}

const Hawaii: FC<Props> = ({ modalHandler }) => {
  const [menu, setMenu] = useState(false);
  const [show, setShow] = useState(false);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const openHawaii = () => {
    window.open("https://travel.hawaii.gov/#/", "_blank");
    openNewLink();
    //         var win = window.open(url, '_blank');
    //   win.focus();
  };
  const openNewLink = () => {
    window.open(
      "https://www.viator.com/Hawaii/d278-ttd?pid=P00038104&mcid=42383&medium=link&campaign=SafeTravels-Website",
      "_self"
    );
    console.log("open viator");
  };
  return (
    <div className="relative bg-white overflow-hidden">
      <div className="max-w-7xl mx-auto">
        <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
          <svg
            className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
            fill="currentColor"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            aria-hidden="true"
          >
            <polygon points="50,0 100,0 50,100 0,100" />
          </svg>

          <main className="pt-10 mx-auto max-w-7xl px-4 sm:pt-12 sm:px-6 md:pt-16 lg:pt-20 lg:px-8 xl:pt-28">
            <div className="sm:text-center lg:text-left">
              <h1 className="text-4xl tracking-tight font-bold text-gray-800 sm:text-5xl md:text-6xl">
                <span className="block xl:inline">Travelling to</span>{" "}
                <span className="block text-indigo-600 xl:inline">Hawaii</span>{" "}
                soon?
              </h1>
              <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                Our site and app is not related to the Hawaiian Government Safe
                Travels health form that you need to complete. <br />
                <br />
                So while our team in Australia are jealous you are travelling to
                Hawaii, click the button below to head to the official Hawaiian
                government site.
              </p>
              <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                <div className="rounded-md shadow">
                  <a
                    href="javascript:;"
                    onClick={() => openHawaii()}
                    className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10"
                  >
                    Go to Hawaiian Government Site
                  </a>
                </div>
                <div className="mt-3 sm:mt-0 sm:ml-3"></div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 hidden md:block">
        <img
          className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
          src={hawaii}
          alt=""
        />
      </div>
    </div>
  );
};
export default Hawaii;
