import classNames from "classnames";
import React from "react";
import InputHelper from "../Input/Inputhelper/InputHelper";
import withFormikContext from "../withFormikContext";
import styles from "./CustomCheckbox.module.scss";
interface Props {
  name: string;
  error?: string;
  onChange: (event: any) => void;
  value?: boolean;
  disabled?: boolean;
  children?: React.ReactElement;
}

export const CustomCheckbox: React.FC<Props> = ({
  name,
  onChange,
  error,
  value,
  disabled,
  children,
}) => {
  return (
      <div>
        <label className={`${styles.container} border-2 border-transparent`}>
          <input
            name={name}
            disabled={disabled}
            onChange={onChange}
            type="checkbox"
            checked={value}
            className={classNames({
              "border-error-300 text-error-900 placeholder-error-300 focus:border-error-300 focus:ring-red":
                !!error,
            })}
          />
          <span className={styles.checkmark}></span>
          <div className={""}>{children}</div>
        </label>
        <InputHelper type={"error"} text={error} />
      </div>
  );
};

export default CustomCheckbox;

export const FormikCheck = withFormikContext(CustomCheckbox);
