import React, { FunctionComponent, useState } from "react";
import Loader from "react-loader-spinner";

import { useHistory } from "react-router";
import * as yup from "yup";
import { Formik, FormikProps, FormikValues } from "formik";
import Input from "../../shared/Input/Input";
import { BrandButton } from "../../shared/Button/BrandButton";
import { AppContext } from "../../App.context";
import { UserRoles } from "../../constants/enums/UserRoles";
import { ERROR_WENT_WRONG } from "../../constants/message";
import { CheckInput, CheckType } from "../../shared/CheckInput/CheckInput";
import { useLocation } from "react-router-dom";
import { RouteConfig } from "../../constants/RouteConfig";
import logo from "../../assets/safetravels-logo.png";
import hawaii from "../../assets/hawaii.png";
const Register: FunctionComponent = () => {
  const { store, toast } = AppContext;
  const prefilledEmail = new URLSearchParams(location.search).get("email");
  const prefilledFirstName = new URLSearchParams(location.search).get(
    "first_name"
  );
  const prefilledLastName = new URLSearchParams(location.search).get(
    "last_name"
  );
  const prefilledBusinessName = new URLSearchParams(location.search).get(
    "business"
  );

  const pathName = useLocation().pathname;

  const [isTermsAccepted, setIsTermsAccepted] = useState(false);

  const history = useHistory();

  return (
    <div className="relative bg-white overflow-hidden">
      <div className="max-w-7xl mx-auto">
        <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
          <svg
            className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
            fill="currentColor"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            aria-hidden="true"
          >
            <polygon points="50,0 100,0 50,100 0,100" />
          </svg>

          <main className="pt-10 mx-auto max-w-7xl px-4 sm:pt-12 sm:px-6 md:pt-16 lg:pt-20 lg:px-8 xl:pt-28 h-auto">
            <a href="/home">
              <img className=" w-80 sm:h- mb-10" src={logo} />
            </a>
            {/* <h1 className="text-4xl tracking-tight font-bold text-gray-800 sm:text-5xl md:text-6xl">
              <span className="block xl:inline">Coming Soon</span>{" "}
              <span className="block text-indigo-600 xl:inline">
                Business account
              </span>
            </h1> */}
            <div className="sm:text-center lg:text-left">
              <h1 className="text-4xl tracking-tight font-bold text-gray-800 sm:text-5xl md:text-6xl">
                <span className="block xl:inline">Register a</span>{" "}
                <span className="block text-indigo-600 xl:inline">
                  Business account
                </span>
              </h1>
              <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                IMPORTANT - This is the sign-up page for tour and travel
                companies wishing to use the SafeTravels app platform for their
                travel business.
                <br />
                All other users please use the SafeTravels app.
              </p>
              <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                <div className="mt-8 w-3/4">
                  <Formik
                    initialValues={{
                      email: prefilledEmail || "",
                      password: "",
                      first_name: prefilledFirstName || "",
                      last_name: prefilledLastName || "",
                      travel_business_name: prefilledBusinessName || "",
                      confirm_password: "",
                      source: "dashboard",
                    }}
                    validationSchema={yup.object().shape({
                      email: yup
                        .string()
                        .required()
                        .email("Please enter a valid email"),
                      password: yup.string().required().min(6),
                      first_name: yup
                        .string()
                        .required("Please enter your first name"),
                      last_name: yup
                        .string()
                        .required("Please enter your last name"),
                      travel_business_name: yup
                        .string()
                        .required(
                          "Please enter the name of your travel business"
                        ),
                      confirm_password: yup
                        .string()
                        .oneOf(
                          [yup.ref("password"), null],
                          "Passwords must match"
                        ),
                    })}
                    onSubmit={async (data, formikHelpers) => {
                      if (!isTermsAccepted) {
                        toast.showError(
                          "You need to accept terms and conditions to continue"
                        );

                        return;
                      }

                      formikHelpers.setSubmitting(true);
                      try {
                        const signUpResponse = await store.userStore.signUp({
                          firstName: data.first_name,
                          lastName: data.last_name,
                          name: data.travel_business_name,
                          acceptTerms: true,
                          email: data.email,
                          password: data.password,
                          confirmPassword: data.confirm_password,
                          source: data.source,
                        });

                        {
                          pathName !== RouteConfig.signUp.staffSignup &&
                            (await store.businessStore.createBusiness({
                              name: data.travel_business_name,
                              staff: [
                                {
                                  account_id: signUpResponse.id,
                                  email: signUpResponse.email || "",
                                  first_name: signUpResponse.firstName,
                                  last_name: signUpResponse.lastName,
                                  role: UserRoles.ADMIN,
                                },
                              ],
                            }));
                        }

                        toast.showInfo("Registered Successfully");
                        history.push("/login");
                        formikHelpers.setSubmitting(false);
                      } catch (e: any) {
                        toast.showError(e.message || ERROR_WENT_WRONG);
                        formikHelpers.setSubmitting(false);
                      }
                    }}
                  >
                    {(formikProps: FormikProps<FormikValues>) => (
                      <form onSubmit={formikProps.handleSubmit}>
                        <div className={"flex flex-col space-y-4"}>
                          <div>
                            <Input
                              label="Travel Business Name"
                              placeholder="Travel Business Name"
                              name={"travel_business_name"}
                              theme="secondary"
                              onChange={formikProps.handleChange}
                            />
                          </div>
                          <div>
                            <Input
                              label="First name"
                              placeholder="First name"
                              name={"first_name"}
                              theme="secondary"
                              onChange={formikProps.handleChange}
                            />
                          </div>
                          <div>
                            <Input
                              label="Last name"
                              placeholder="Last name"
                              name={"last_name"}
                              theme="secondary"
                              onChange={formikProps.handleChange}
                            />
                          </div>
                          <div>
                            <Input
                              label="Email"
                              placeholder="Email"
                              type="email"
                              name={"email"}
                              theme="secondary"
                              onChange={formikProps.handleChange}
                            />
                          </div>

                          <div>
                            <Input
                              label="Password"
                              placeholder="Password"
                              type="password"
                              name={"password"}
                              theme="secondary"
                              onChange={formikProps.handleChange}
                            />
                          </div>
                          <div>
                            <Input
                              label="Confirm Password"
                              placeholder="Password"
                              type="password"
                              name={"confirm_password"}
                              theme="secondary"
                              onChange={formikProps.handleChange}
                            />
                          </div>
                        </div>
                        <div className="mb-4 mt-2 flex items-center space-x-2 ">
                          <div className={"font-medium text-gray-800"}>
                            I Agree the{" "}
                            <a
                              className="text-blue-400 hover:text-blue-600"
                              href="/terms-and-conditions"
                              target="_blank"
                            >
                              terms and conditions.
                            </a>
                            {" and "}{" "}
                            <a
                              className="text-blue-400 hover:text-blue-600"
                              href="/privacy-policy"
                              target="_blank"
                            >
                              privacy policy.
                            </a>{" "}
                          </div>
                          <div
                            onClick={() => setIsTermsAccepted(!isTermsAccepted)}
                          >
                            <CheckInput
                              type={CheckType.Radio}
                              selectedOption={isTermsAccepted}
                              className="w-5 h-5"
                            />
                          </div>
                        </div>
                        <div className="flex space-x-3 w-full">
                          <BrandButton
                            className=" text-base font-semibold bg-gray-200 hover:bg-gray-400"
                            disabled={formikProps.isSubmitting}
                            type="button"
                            theme="basic"
                            size={"xl"}
                          >
                            Cancel
                          </BrandButton>
                          <BrandButton
                            className=" text-base font-semibold"
                            disabled={formikProps.isSubmitting}
                            theme="primary"
                            size={"xl"}
                          >
                            Submit
                          </BrandButton>
                          {formikProps.isSubmitting && (
                            <span className="ml-3">
                              <Loader
                                type="BallTriangle"
                                height={30}
                                width={30}
                              />
                            </span>
                          )}
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>

                <div className="mt-3 sm:mt-0 sm:ml-3"></div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 hidden md:block">
        <img
          className="h-56 w-full sm:h-72 md:h-96 lg:w-full lg:h-full object-cover"
          src={hawaii}
          alt=""
        />
      </div>
    </div>
  );
};

export default Register;
