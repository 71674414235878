import React, { FunctionComponent } from "react";
import classNames from "classnames";

export interface Props {
  type?: "error" | "helper";
  text?: string;
}

const InputHelper: FunctionComponent<Props> = ({ text, type }) => {
  return !!text ? (
    <p
      className={classNames(" text-xs font-thin ", {
        "text-error-600": type === "error",
        "text-gray-700": type === "helper",
      })}
      id="input-error"
    >
      {text}
    </p>
  ) : null;
};

export default InputHelper;

InputHelper.defaultProps = {
  type: "helper",
};
