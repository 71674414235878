import React from "react";
import { IconType, IconContext } from "react-icons";

interface Props {
  className?: string;
  value: IconType;
  iconColor?: string;
  onClick?: (e?: any) => void;
}

const Icon: React.FC<Props> = ({ value, className }) => {
  const IconValue = value as React.FC;

  return (
    <>
      <IconContext.Provider value={{ className: className }}>
        <IconValue />
      </IconContext.Provider>
    </>
  );
};

export default React.memo(Icon);
