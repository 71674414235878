import { AppContext } from "App.context";
import { GuestRoles } from "constants/enums/GuestRoles";
import { ERROR_WENT_WRONG } from "constants/message";
import { FormikProps, FormikValues, useFormik, Formik } from "formik";
import React, { FC, useState } from "react";
import { UserModel } from "store/models/UserModel";
import * as yup from "yup";
import { BrandButton } from "../../../../shared/Button/BrandButton";
import {
  CheckInput,
  CheckType,
} from "../../../../shared/CheckInput/CheckInput";
import Input from "../../../../shared/Input/Input";
import Loader from "react-loader-spinner";
import GenericHeader from "shared/Headers/GenericHeader";
interface Props {
  modalHandler: () => void;
  editData?: UserModel;
}
const AddTripGuest: FC<Props> = ({ modalHandler, editData }) => {
  const { store, toast } = AppContext;
  const [selectedCheckBox, setSelectedCheckBox] = useState(
    editData?.role || GuestRoles.GUEST
  );
  const currentTripId = new URLSearchParams(location.search).get("id");
  const isUpdate = !!editData;
  const businessDetails = store.userStore.loggedInUser?.memberships?.items?.[0];

  console.log("editdata", editData);

  return (
    <Formik
      initialValues={{
        first_name: editData?.firstName || "",
        last_name: editData?.lastName || "",
        email: editData?.email || "",
      }}
      validationSchema={yup.object().shape({
        email: yup
          .string()
          .required("Email is Required")
          .trim()
          .email("Please Enter a Valid Email"),
        first_name: yup.string().trim().required("First Name is Required"),
        last_name: yup.string(),
      })}
      onSubmit={async (data, formikHelpers) => {
        formikHelpers.setSubmitting(true);

        const mappedValues = {
          ...data,
          trip_id: currentTripId as any,
          business_id: businessDetails!.business_id!,
          business_name: businessDetails?.name || "",

          role: GuestRoles.GUEST,
          // selectedCheckBox === GuestRoles.GUEST
          //   ? GuestRoles.GUEST
          //   : GuestRoles.PARENT_GUARDIAN,
        };

        try {
          if (isUpdate) {
            await store.businessStore.updateGuest({
              _id: editData?.id,
              ...mappedValues,
            });
          } else {
            await store.businessStore.addGuest(mappedValues);
          }

          formikHelpers.setSubmitting(false);
          modalHandler();
        } catch (e: any) {
          formikHelpers.setSubmitting(false);
          toast.showError(e.message || ERROR_WENT_WRONG);
        }

        formikHelpers.setSubmitting(false);
      }}
    >
      {(formikProps: FormikProps<FormikValues>) => (
        <form onSubmit={formikProps.handleSubmit}>
          <div className="bg-white ">
            <div className="mx-auto bg-white  rounded ">
              <div className="border-b border-gray-300 dark:border-gray-700 py-5 bg-white ">
                <div className="w-11/12 mx-auto items-center pb-4">
                  <GenericHeader
                    heading={"Trip Guest"}
                    subHeading={
                      "Add a Guest to the trip. You can add them here or, simply provide them with the Trip Code that they can add in the app"
                    }
                  />
                </div>
              </div>
              <div className="w-2/3 mx-auto flex flex-col space-y-4 mt-4">
                <div className="flex w-full space-x-6">
                  <div className="w-1/2">
                    <Input
                      className="w-10/12"
                      label="First Name"
                      helperText="Please enter the first name of the Member."
                      name={"first_name"}
                    />
                  </div>
                  <div className="w-1/2">
                    <Input
                      label="Last Name"
                      helperText="Please enter the last name of the Member."
                      name={"last_name"}
                    />
                  </div>
                </div>
                <div className="flex w-full space-x-6">
                  <div className={"w-1/2"}>
                    <Input
                      className=""
                      type="email"
                      label="Email Address*"
                      helperText="Please enter the email of the Member."
                      name={"email"}
                    />
                  </div>
                  {/* <div className={"w-1/2"}>
                    <label
                      htmlFor="username"
                      className="pb-2 text-sm text-gray-800 dark:text-gray-100"
                    >
                      {"Member Type*"}
                    </label>

                    <div className="flex space-x-2 py-1">
                      <div
                        className="flex items-center"
                        onClick={(e) => setSelectedCheckBox(GuestRoles.GUEST)}
                      >
                        <CheckInput
                          type={CheckType.Radio}
                          name={"Guest"}
                          selectedOption={selectedCheckBox}
                          className="w-5 h-5"
                        />
                        <span className="mt-1 text-sm text-gray-800 dark:text-gray-100">
                          Guest
                        </span>
                      </div>

                      <div
                        className="flex items-center"
                        onClick={(e) =>
                          setSelectedCheckBox(GuestRoles.PARENT_GUARDIAN)
                        }
                      >
                        <CheckInput
                          type={CheckType.Radio}
                          name={"Parent / Guardian"}
                          selectedOption={selectedCheckBox}
                          className="w-5 h-5"
                        />
                        <span className="mt-1 text-sm text-gray-800 dark:text-gray-100">
                          Partent/Guardian
                        </span>
                      </div>
                    </div>
                    <div className="text-xs font-thin text-gray-700">
                      {
                        "If enabled, a parent / guardian user will have access to a Parent Group Chat for the trip."
                      }
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            <hr className="h-0.5 mt-6 bg-gray-400 mx-auto" />

            <div className="w-11/12 mx-auto border-gray-300 flex justify-end dark:border-gray-700 py-5 bg-white ">
              {formikProps.isSubmitting && (
                <span className="mr-3">
                  <Loader type="BallTriangle" height={30} width={30} />
                </span>
              )}
              <div className="w-52 items-center justify-between flex pb-4">
                <BrandButton
                  className=" text-base font-semibold bg-gray-200 hover:bg-gray-400"
                  disabled={formikProps.isSubmitting}
                  type="button"
                  theme="basic"
                  onClick={modalHandler}
                  size={"xl"}
                >
                  Cancel
                </BrandButton>
                <BrandButton
                  className=" text-base font-semibold"
                  disabled={formikProps.isSubmitting}
                  type="submit"
                  theme="primary"
                  size={"xl"}
                >
                  Save
                </BrandButton>
              </div>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};
export default AddTripGuest;
