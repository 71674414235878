import React, { FC, useEffect, useState } from "react";
import Modal from "../../../shared/Modal/Modal";
import AddStaffMember from "./AddStaffMember";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import Table from "../../../shared/Table/Table";
import GenericHeader from "../../../shared/Headers/GenericHeader";
import { AppContext } from "../../../App.context";
import { observer } from "mobx-react-lite";
import IconButton from "../../../shared/IconButton/IconButton";
import { EntityIdentifier } from "@devslane/mobx-entity-manager";
import { UserModel } from "../../../store/models/UserModel";
import { ERROR_WENT_WRONG } from "../../../constants/message";
import { InputWithoutFormik } from "../../../shared/Input/Input";
import Loader from "react-loader-spinner";
import { debounce } from "lodash";

const AddStaffMembers: FC = () => {
  const { store, toast } = AppContext;
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [editData, setEditData] = useState<UserModel | undefined>();

  const businessId = store.userStore.loggedInUser?.memberships?.items?.[0]?.id;

  const staffList = store.businessStore.staffList.items;
  const [filterStaffs, setFilterStaffs] = useState<UserModel[]>(staffList);

  const staffTable = {
    headingsArray: ["First Name", "Last Name", "Email", "Role", "Actions"],
  };

  const closeModal = () => {
    setEditData(undefined);
    setOpenModal(false);
  };

  const handleDelete = async (id: EntityIdentifier) => {
    try {
      if (confirm("Are you sure you want to delete this staff user?")) {
        await store.businessStore.deleteStaff({
          staff_id: id,
          business_id: businessId!,
        });
        toast.showSuccess("Staff User Deleted Successfully");
      }
    } catch (e: any) {
      toast.showError(e.message || ERROR_WENT_WRONG);
    }
  };

  const dataArray: any = (filterStaffs || [])?.map((staff) => {
    return [
      staff.firstName,
      staff.lastName,
      staff.email,
      staff.role,

      <div className={"flex space-x-3 items-center"}>
        <IconButton
          child={FaEdit}
          className="w-4 h-4"
          onClick={() => setEditData(staff)}
          buttonClassName={"text-white rounded-full p-2 bg-indigo-500"}
        />

        <div className="">
          <IconButton
            child={FaTrashAlt}
            onClick={() => handleDelete(staff.id)}
            className="w-4 h-4"
            buttonClassName={" text-white rounded-full p-2 bg-error-500"}
          />
        </div>
      </div>,
    ];
  });
  const ifSearchIncludes = (data: UserModel, searchKey: string) => {
    const searchCriteriaArray: Array<any> = [
      data.firstName?.trim()?.toLowerCase(),
      data.lastName?.trim()?.toLowerCase(),
      data.email?.trim()?.toLowerCase(),
    ];
    if (
      searchCriteriaArray?.some((val) =>
        val?.includes(searchKey?.trim()?.toLowerCase())
      )
    )
      return true;

    return false;
  };

  const handleSearch = debounce((searchKey: string): void => {
    const filtered = staffList.filter((data) => {
      return ifSearchIncludes(data, searchKey);
    });

    setFilterStaffs(filtered);
  }, 200);

  useEffect(() => {
    setFilterStaffs(staffList);
  }, [staffList]);

  useEffect(() => {
    store.businessStore.loadStaff(businessId!);
  }, [businessId]);
  return (
    <>
      <Modal
        modalStyle={{ width: "50rem", maxHeight: "95%", overflowY: "auto" }}
        open={openModal || !!editData}
        onOutsideClick={closeModal}
      >
        <AddStaffMember modalHandler={closeModal} editData={editData} />
      </Modal>

      <div className="bg-white">
        <div className="pb-8">
          <GenericHeader
            heading={"Business Staff"}
            subHeading={"Add and Manage Staff access to SafeTravels"}
            buttonOnClick={() => setOpenModal(true)}
            buttonText={"Add Staff Member"}
          />
          <hr className="h-0.5 mt-2 bg-gray-50 mx-auto w-full" />

          <div className={"px-8 mt-4"}>
            <InputWithoutFormik
              type="text"
              placeholder="Search for Staff Member"
              onChange={(e) => handleSearch(e.target.value || "")}
            />
          </div>
        </div>
        <div className="mt-6 w-full px-8">
          {store.businessStore.staffList.loading ? (
            <div className={"flex justify-center mx-auto"}>
              <Loader type="BallTriangle" height={30} width={30} />
            </div>
          ) : (
            <Table headers={staffTable.headingsArray} data={dataArray} />
          )}
        </div>
        <hr className="h-0.5 mt-10 bg-gray-50 mx-auto w-full" />
      </div>
    </>
  );
};
export default observer(AddStaffMembers);
