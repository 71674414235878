import React, { FC, useState } from "react";
import GenericHeader from "../../../../shared/Headers/GenericHeader";
import { Toggles } from "../../../../shared/Button/BrandToggle";
interface Props {
  initialValue?: boolean;
  label?: string;
  helper?: string;
  setData: (v: boolean) => void;
}

const ChatSetting: FC<Props> = ({ label, helper, setData, initialValue }) => {
  return (
    <>
      <div className="flex justify-between">
        <p className="text-md w-1/3 text-gray-800 pb-2">{label}</p>
        {helper && (
          <div className="text-gray-400 w-1/3 text-sm font-thin">{helper}</div>
        )}
        <Toggles
          initialState={initialValue}
          onChange={(booleanCondition) => setData(booleanCondition)}
          iconEnable={initialValue}
        />
      </div>
    </>
  );
};
export default ChatSetting;
