import { useField } from "formik";
import React from "react";
import InputHelper from "../Input/Inputhelper/InputHelper";
import BrandTextEditor from "./BrandTextEditor";

interface FormikBrandPhoneInputProps {
  name: string;
  label?: string;
  disabled?: boolean;
  placeholder?: string;
  height: string;
  labelClassName?: string;
}

const FormikBrandTextEditor: React.FC<FormikBrandPhoneInputProps> = (
  props: FormikBrandPhoneInputProps
) => {
  const [inputProps, meta, helpers] = useField(props.name);

  const { value: selection, error, touched } = meta;
  const { setValue: setSelection, setTouched } = helpers;

  return (
    <>
      <BrandTextEditor
        name={props.name}
        label={props.label}
        disabled={props.disabled}
        value={selection}
        placeholder={props.placeholder}
        handleTextEditorData={(data: string) => setSelection(data)}
        height={props.height}
        labelClassName={props.labelClassName}
      />
      {error && touched && <InputHelper type={"error"} text={error} />}
    </>
  );
};

export default FormikBrandTextEditor;
