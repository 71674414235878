import { action, observable } from "mobx";
import { ChildStore } from "../ChildStore";
import { Context } from "../Context";
import { RootStore } from "../RootStore";
import { BusinessModel } from "../models/BusinessModel";
import { businessService } from "../services/api-services/BusinessService";
import {
  EntityIdentifier,
  ModelItem,
  ModelJson,
  ModelList,
} from "@devslane/mobx-entity-manager";
import { UserRoles } from "../../constants/enums/UserRoles";
import { map } from "lodash";
import { UserModel } from "../models/UserModel";
import { GuestRoles } from "constants/enums/GuestRoles";

export class BusinessStore extends ChildStore<BusinessModel> {
  constructor(rootStore: RootStore) {
    super(rootStore);
    BusinessModel._store = this;
  }

  @observable currentBusiness: ModelItem<BusinessModel> =
    new ModelItem<BusinessModel>(BusinessModel);

  @observable staffList: ModelList<UserModel> = new ModelList<UserModel>(
    UserModel
  );

  @observable guestList: ModelList<UserModel> = new ModelList<UserModel>(
    UserModel
  );

  @observable tripStaffIdList: ModelList<any> = new ModelList<any>([] as any);

  @action
  setCurrentBusiness(data: ModelJson): void {
    this.currentBusiness.deserialize(data);
    this.currentBusiness.setLoading(false);
  }

  @action
  async loadStaff(business_id: EntityIdentifier): Promise<any> {
    this.staffList.setLoading(true);
    try {
      return await businessService.getStaffs(business_id).then((res) => {
        this.staffList.deserialize(
          map(res.data, (staff) => staff.toJSON()) as ModelJson[]
        );
        this.staffList.setLoading(false);
        return res;
      });
    } catch (e) {
      this.staffList.setLoading(false);
      throw e;
    }
  }

  @action
  async loadGuests(
    trip_id: EntityIdentifier,
    business_id: EntityIdentifier
  ): Promise<any> {
    this.guestList.setLoading(true);
    try {
      return await businessService
        .getGuests(trip_id, business_id)
        .then((res) => {
          this.guestList.deserialize(
            map(res.data, (staff) => staff.toJSON()) as ModelJson[]
          );
          this.guestList.setLoading(false);
          return res;
        });
    } catch (e) {
      this.guestList.setLoading(false);
      throw e;
    }
  }

  @action
  async loadTripStaff(
    business_id: EntityIdentifier,
    trip_id: EntityIdentifier
  ): Promise<any> {
    try {
      return await businessService
        .getTripStaffs(business_id, trip_id)
        .then((res) => {
          this.tripStaffIdList.setItems(res.data.map((staff: any) => staff.id));
          return res;
        });
    } catch (e) {
      throw e;
    }
  }

  @action
  async addStaffToTrip(
    business_id: EntityIdentifier,
    trip_id: EntityIdentifier,
    staff: any
  ): Promise<any> {
    try {
      this.tripStaffIdList.appendItem(staff.id);

      return await businessService
        .addStaffToTrip(business_id, trip_id, staff)
        .then((res) => {
          return res;
        });
    } catch (e) {
      this.tripStaffIdList.setItems(
        this.tripStaffIdList.items?.filter((id) => id !== staff.id)
      );
      throw e;
    }
  }

  @action
  async removeStaffFromTrip(
    business_id: EntityIdentifier,
    trip_id: EntityIdentifier,
    staff_id: EntityIdentifier
  ): Promise<any> {
    try {
      this.tripStaffIdList.setItems(
        this.tripStaffIdList.items?.filter((id) => id !== staff_id)
      );

      return await businessService
        .removeStaffFromTrip(business_id, trip_id, staff_id)
        .then((res) => {
          return res;
        });
    } catch (e) {
      this.tripStaffIdList.appendItem(staff_id);
      throw e;
    }
  }

  @action
  async updateBusiness(data: {
    id: EntityIdentifier;
    email: string;
    logo: string;
    name: string;
    send_emails_from: string;
    description: string;
  }): Promise<any> {
    return await businessService.updateBusiness(data).then((res) => {
      const businessData = BusinessModel.fromJson(data) as BusinessModel;
      this.setCurrentBusiness(businessData.toJSON() as ModelJson);
      return res;
    });
  }

  @action
  async updateStaff(data: {
    business_id: EntityIdentifier;
    business_name: string;
    email: string;
    first_name: string;
    last_name: string;
    role: UserRoles;
    _id: EntityIdentifier;
  }): Promise<any> {
    return await businessService.updateStaff(data).then((res) => {
      // #TODO need edit staff respone from BE

      const updatedStaffData = UserModel.fromJson({
        id: data._id,
        lastName: data.last_name,
        firstName: data.first_name,
        email: data.email,
      }) as UserModel;

      return res;
    });
  }

  @action
  async deleteStaff(data: {
    business_id: EntityIdentifier;
    staff_id: EntityIdentifier;
  }): Promise<any> {
    try {
      return await businessService.deleteStaff(data).then((res) => {
        const allItems = this.staffList.items.map((staff: UserModel) =>
          staff.toJSON()
        );
        const staffs = allItems.filter((staff) => data.staff_id !== staff.id);
        this.staffList.deserialize(staffs as ModelJson[]);

        return res.message;
      });
    } catch (e) {
      throw e;
    }
  }

  @action
  async getBusinessFromId(id: EntityIdentifier): Promise<BusinessModel> {
    return await businessService.getBusinessFromId(id).then((res) => {
      this.setCurrentBusiness(res.toJSON() as ModelJson);
      return res;
    });
  }

  @action
  async createBusiness(data: {
    name: string;
    staff: Array<{
      account_id: EntityIdentifier;
      email: string;
      first_name: string;
      last_name: string;
      role: UserRoles;
    }>;
  }): Promise<any> {
    return await businessService
      .createBusiness(data)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err;
      });
  }

  @action
  async addStaff(data: {
    business_id: EntityIdentifier;
    business_name: string;
    first_name: string;
    last_name?: string;
    email: string;
    role: UserRoles;
    status: string;
  }): Promise<any> {
    try {
      return await businessService.createStaff(data).then((res) => {
        const staffResponse = res.data?.staff?.[res.data.staff.length - 1];
        //appending new Staff
        const staff = {
          id: staffResponse?._id,
          firstName: staffResponse?.first_name,
          lastName: staffResponse?.last_name,
          email: staffResponse?.email,
          role: staffResponse?.role,
        };

        const staffData: any = UserModel.fromJson(staff);
        this.staffList.appendItem(staffData);
        return res.data;
      });
    } catch (e) {
      throw e;
    }
  }

  @action
  async addGuest(data: {
    trip_id: EntityIdentifier;
    business_id: EntityIdentifier;
    first_name: string;
    last_name?: string;
    email: string;
    role: GuestRoles;
  }): Promise<any> {
    try {
      return await businessService.createGuest(data).then((res) => {
        this.guestList.deserialize(
          map(res.data, (staff) => staff.toJSON()) as ModelJson[]
        );
        this.guestList.setLoading(false);
        return res;
      });
    } catch (e) {
      this.guestList.setLoading(false);
      throw e;
    }
  }
  @action
  async updateGuest(data: {
    trip_id: EntityIdentifier;
    business_id: EntityIdentifier;
    _id: EntityIdentifier;
    first_name: string;
    last_name?: string;
    email: string;
    role: GuestRoles;
  }): Promise<any> {
    try {
      return await businessService.updateGuest(data).then((res) => {
        this.guestList.deserialize(
          map(res.data, (staff) => staff.toJSON()) as ModelJson[]
        );
        this.guestList.setLoading(false);
        return res;
      });
    } catch (e) {
      this.guestList.setLoading(false);
      throw e;
    }
  }
  @action
  async deleteGuest(
    trip_id: EntityIdentifier,
    business_id: EntityIdentifier,
    _id: EntityIdentifier
  ): Promise<any> {
    try {
      return await businessService
        .deleteGuest(trip_id, business_id, _id)
        .then((res) => {
          this.guestList.deserialize(
            map(res.data, (staff) => staff.toJSON()) as ModelJson[]
          );
          this.guestList.setLoading(false);
          return res;
        });
    } catch (e) {
      this.guestList.setLoading(false);
      throw e;
    }
  }
  reset() {
    this.entities.clear();
    Context.storage.reset();
  }
}
