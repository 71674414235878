import { ContextStatic, EntityIdentifier } from "@devslane/mobx-entity-manager";
import { UserRoles } from "../../../constants/enums/UserRoles";
import { BusinessModel } from "../../models/BusinessModel";
import { map } from "lodash";
import { UserModel } from "../../models/UserModel";
import { SectionModel } from "store/models/SectionModal";
import queryString from "query-string";
import { AppContext } from "App.context";
import { GuestRoles } from "constants/enums/GuestRoles";

class BusinessService {
  static getInstance(): BusinessService {
    return new BusinessService();
  }

  async getBusinessFromId(id: EntityIdentifier): Promise<BusinessModel> {
    return await ContextStatic.default.apiService
      .get(`business?business_id=${id}`)
      .then((res: any) => {
        return BusinessModel.fromJson({
          ...(res.data || {}),
        }) as BusinessModel;
      });
  }

  async updateBusiness(data: {
    id: EntityIdentifier;
    email: string;
    logo: string;
    name: string;
    send_emails_from: string;
    description: string;
  }): Promise<any> {
    return await ContextStatic.default.apiService
      .put(`business?business_id=${data.id}`, data)
      .then((res: any) => {
        return BusinessModel.fromJson({
          ...(res.data || {}),
        }) as BusinessModel;
      });
  }

  async addStaffToTrip(
    business_id: EntityIdentifier,
    trip_id: EntityIdentifier,
    staff: any
  ): Promise<any> {
    const staffPayload = {
      ...staff,
      first_name: staff.firstName,
      last_name: staff.lastName,
      account_id: staff.account_id,
    };
    return await ContextStatic.default.apiService
      .post(
        `trips/staff/add?business_id=${business_id}&trip_id=${trip_id}`,
        staffPayload
      )
      .then((res: any) => {
        const staffs = res?.data || [];
        return {
          data: map(staffs, (staff) => {
            staff = {
              ...staff,
              id: staff._id,
              account_id: staff.account_id,
              firstName: staff.first_name,
              lastName: staff.last_name,
            };
            return UserModel.fromJson(staff);
          }),
        };
      });
  }

  async removeStaffFromTrip(
    business_id: EntityIdentifier,
    trip_id: EntityIdentifier,
    staff_id: EntityIdentifier
  ): Promise<any> {
    return await ContextStatic.default.apiService
      .delete(
        `trips/staff?business_id=${business_id}&trip_id=${trip_id}&staff_id=${staff_id}`
      )
      .then((res: any) => {
        const staffs = res?.data || [];
        return {
          data: map(staffs, (staff) => {
            staff = {
              ...staff,
              id: staff._id,
              account_id: staff.account_id,
              firstName: staff.first_name,
              lastName: staff.last_name,
            };
            return UserModel.fromJson(staff);
          }),
        };
      });
  }

  async createBusiness(data: {
    name: string;
    staff: Array<{
      email: string;
      first_name: string;
      last_name: string;
      role: UserRoles;
    }>;
  }): Promise<{ data: any }> {
    return await ContextStatic.default.apiService
      .post("business/create", data, {
        extras: { useAuth: false },
      })
      .then((res: any) => {
        return {
          data: res || [],
        };
      });
  }

  async createStaff(data: {
    business_id: EntityIdentifier;
    business_name: string;
    first_name: string;
    last_name?: string;
    email: string;
    role: UserRoles;
    status: string;
  }): Promise<{ data: any }> {
    return await ContextStatic.default.apiService
      .post(`business/staff/invite?business_id=${data.business_id}`, data)
      .then((res: any) => {
        return {
          data: res.data || [],
        };
      });
  }

  async createGuest(data: {
    trip_id: EntityIdentifier;
    business_id: EntityIdentifier;
    first_name: string;
    last_name?: string;
    email: string;
    role: GuestRoles;
  }): Promise<{ data: any }> {
    const { store } = AppContext;
    return await ContextStatic.default.apiService
      .post(
        `trips/guests/add?business_id=${data.business_id}&trip_id=${data.trip_id}`,
        data
      )
      .then((res: any) => {
        const staffs = res?.data || [];
        return {
          data: map(staffs, (staff) => {
            staff = {
              ...staff,
              id: staff._id,
              firstName: staff.first_name,
              lastName: staff.last_name,
            };
            return UserModel.fromJson(staff);
          }),
        };
      });
  }

  async updateGuest(data: {
    trip_id: EntityIdentifier;
    business_id: EntityIdentifier;
    _id: EntityIdentifier;
    first_name: string;
    last_name?: string;
    email: string;
    role: GuestRoles;
  }): Promise<{ data: any }> {
    return await ContextStatic.default.apiService
      .post(
        `trips/guests?business_id=${data.business_id}&trip_id=${data.trip_id}`,
        data
      )
      .then((res: any) => {
        const staffs = res?.data || [];
        return {
          data: map(staffs, (staff) => {
            staff = {
              ...staff,
              id: staff._id,
              firstName: staff.first_name,
              lastName: staff.last_name,
            };
            return UserModel.fromJson(staff);
          }),
        };
      });
  }

  async deleteGuest(
    trip_id: EntityIdentifier,
    business_id: EntityIdentifier,
    _id: EntityIdentifier
  ): Promise<{ data: any }> {
    return await ContextStatic.default.apiService
      .delete(
        `trips/guests?business_id=${business_id}&trip_id=${trip_id}&guest_id=${_id}`
      )
      .then((res: any) => {
        const staffs = res?.data || [];
        return {
          data: map(staffs, (staff) => {
            staff = {
              ...staff,
              id: staff._id,
              firstName: staff.first_name,
              lastName: staff.last_name,
            };
            return UserModel.fromJson(staff);
          }),
        };
      });
  }

  async deleteStaff(data: {
    business_id: EntityIdentifier;
    staff_id: EntityIdentifier;
  }): Promise<any> {
    const query = queryString.stringify(data);

    return await ContextStatic.default.apiService.delete(
      `business/staff?${query}`
    );
  }

  async deleteSection(data: {
    business_id: EntityIdentifier;
    section_id: EntityIdentifier;
  }): Promise<any> {
    const query = queryString.stringify(data);

    return await ContextStatic.default.apiService.delete(
      `business/info?${query}`
    );
  }

  async deleteArticle(data: {
    business_id: EntityIdentifier;
    section_id: EntityIdentifier;
    article_id: EntityIdentifier;
  }): Promise<any> {
    const query = queryString.stringify(data);

    return await ContextStatic.default.apiService
      .delete(`business/info/section/article?${query}`)
      .then((res: any) => {
        const article = res?.data || [];
        return {
          data: map(article, (information) => {
            return SectionModel.fromJson(information);
          }),
        };
      });
  }

  async createSection(data: {
    business_id: EntityIdentifier;
    name: string;
    order?: number;
  }): Promise<{ data: any }> {
    return await ContextStatic.default.apiService
      .post(`business/info/add?business_id=${data.business_id}`, data)
      .then((res: any) => {
        return {
          data: res || [],
        };
      });
  }

  async createArticle(data: {
    business_id: EntityIdentifier;
    section_id: EntityIdentifier;
    name: string;
    description: string;
    order?: number;
  }): Promise<{ data: any }> {
    return await ContextStatic.default.apiService
      .post(
        `business/info/section/article/add?business_id=${data.business_id}&section_id=${data.section_id}`,
        data
      )
      .then((res: any) => {
        const article = res?.data || [];
        return {
          data: map(article, (information) => {
            return SectionModel.fromJson(information);
          }),
        };
      });
  }

  async getStaffs(business_id: EntityIdentifier): Promise<any> {
    return await ContextStatic.default.apiService
      .get(`business/staff?business_id=${business_id}`)
      .then((res: any) => {
        const staffs = res?.data?.staff || [];
        return {
          data: map(staffs, (staff) => {
            staff = {
              ...staff,
              id: staff._id,
              firstName: staff.first_name,
              lastName: staff.last_name,
            };
            return UserModel.fromJson(staff);
          }),
        };
      });
  }

  async getGuests(
    trip_id: EntityIdentifier,
    business_id: EntityIdentifier
  ): Promise<any> {
    return await ContextStatic.default.apiService
      .get(`trips/guests?business_id=${business_id}&trip_id=${trip_id}`)
      .then((res: any) => {
        const staffs = res?.data?.guests || [];
        return {
          data: map(staffs, (staff) => {
            staff = {
              ...staff,
              id: staff._id,
              firstName: staff.first_name,
              lastName: staff.last_name,
            };
            return UserModel.fromJson(staff);
          }),
        };
      });
  }

  async getTripStaffs(
    business_id: EntityIdentifier,
    trip_id: EntityIdentifier
  ): Promise<any> {
    return await ContextStatic.default.apiService
      .get(`trips/staff?business_id=${business_id}&trip_id=${trip_id}`)
      .then((res: any) => {
        const staffs = res?.data?.staff || [];
        return {
          data: map(staffs, (staff) => {
            staff = {
              ...staff,
              id: staff._id,
              account_id: staff.account_id,
              firstName: staff.first_name,
              lastName: staff.last_name,
            };
            return UserModel.fromJson(staff);
          }),
        };
      });
  }

  async updateStaff(data: {
    business_id: EntityIdentifier;
    business_name: string;
    email: string;
    first_name: string;
    last_name: string;
    role: UserRoles;
    _id: EntityIdentifier;
  }): Promise<any> {
    return await ContextStatic.default.apiService.post(
      `business/staff?business_id=${data.business_id}`,
      data
    );
  }

  async updateSection(data: {
    business_id: EntityIdentifier;
    section_id: EntityIdentifier;
    name: string;
  }): Promise<any> {
    return await ContextStatic.default.apiService
      .post(
        `business/info?business_id=${data.business_id}&section_id=${data.section_id}`,
        data
      )
      .then((res: any) => {
        const section = res?.data || [];
        return {
          data: map(section, (information) => {
            return SectionModel.fromJson(information);
          }),
        };
      });
  }
  async updateArticle(data: {
    business_id: EntityIdentifier;
    section_id: EntityIdentifier;
    _id: EntityIdentifier;
    name: string;
    description: string;
  }): Promise<any> {
    return await ContextStatic.default.apiService
      .post(
        `business/info/section/article?business_id=${data.business_id}&section_id=${data.section_id}`,
        data
      )
      .then((res: any) => {
        const section = res?.data || [];
        return {
          data: map(section, (information) => {
            return SectionModel.fromJson(information);
          }),
        };
      });
  }

  async getSection(business_id: EntityIdentifier): Promise<any> {
    return await ContextStatic.default.apiService
      .get(`business/info?business_id=${business_id}`)
      .then((res: any) => {
        const section = res?.data || [];
        return {
          data: map(section, (information) => {
            return SectionModel.fromJson(information);
          }),
        };
      });
  }

  async reorderSection(
    payload: any,
    business_id: EntityIdentifier
  ): Promise<any> {
    return await ContextStatic.default.apiService
      .post(
        `/business/info/reorder-sections?business_id=${business_id}`,
        payload
      )
      .then((res: any) => {
        const section = res?.data || [];
        return {
          data: section,
        };
      });
  }
  async reorderQuestion(
    payload: any,
    business_id: EntityIdentifier,
    section_id: EntityIdentifier
  ): Promise<any> {
    return await ContextStatic.default.apiService
      .post(
        `/business/info/section/article/reorder?business_id=${business_id}&section_id=${section_id}`,
        payload
      )
      .then((res: any) => {
        const section = res?.data || [];
        return {
          data: section,
        };
      });
  }
}

export const businessService = BusinessService.getInstance();
