import React, { FC } from "react";
import BrandTextEditor from "../../../../shared/Input/BrandTextEditor";
import GenericHeader from "../../../../shared/Headers/GenericHeader";
import { FormikProps, FormikValues, useField } from "formik";
import FormikBrandTextEditor from "../../../../shared/Input/FormikBrandTextEditor";

interface Props {
  formikProps: FormikProps<FormikValues>;
}

const TripItineraryDescription: FC<Props> = ({ formikProps }) => {
  return (
    <div className="bg-white ">
      <div className="container mx-auto  rounded">
        <div className="w-full  ">
          <GenericHeader
            heading={"Activity Description"}
            subHeading={"Add Detailed overview of the activity"}
          />
        </div>
        <hr className="h-0.5 mt-6 bg-gray-200 mx-auto w-full" />

        <div className="mx-10">
          <div className=" mx-auto xl:mx-0 flex flex-col space-y-10 mt-12">
            <div>
              <FormikBrandTextEditor
                placeholder="Description"
                name={"description"}
                height={"500px"}
                labelClassName={"text-sm  text-gray-800 mb-2"}
              />
            </div>
          </div>
        </div>

        <hr className="h-0.5 mt-6 bg-gray-200 mx-auto w-full" />
      </div>
    </div>
  );
};
export default TripItineraryDescription;
