import React from "react";
import styles from "./filepicker.module.scss";

interface AvatarPreviewProps {
  src: string;
  btnText: string;
  uploading?: boolean;
  disabled?: boolean;
  desktopHeightWidth?: string;
}

const AvatarPreview: React.FC<AvatarPreviewProps> = ({
  src,
  uploading,
  disabled,
  desktopHeightWidth,
  btnText,
}) => {
  return (
    <div
      className={`${
        (uploading || disabled) && "opacity-50"
      } relative flex items-center  ${desktopHeightWidth} justify-center  text-gray-400 ${
        !src && "border border-gray-400 border-dashed"
      }  rounded-full flex-shrink-0`}
    >
      {src ? (
        <div className="items-center">
          {uploading && <div className={styles.spinner} />}
          <img
            src={src}
            alt="Picture"
            className={`cursor-pointer object-cover border border-success-300   ${desktopHeightWidth} `}
          />
        </div>
      ) : (
        <>
          <span className="text-center cursor-pointer">{btnText}</span>
        </>
      )}
    </div>
  );
};

AvatarPreview.defaultProps = {
  desktopHeightWidth: "w-28 h-28",
};

export default React.memo(AvatarPreview);
