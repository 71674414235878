import { BaseModel, ModelList } from "@devslane/mobx-entity-manager";
import { observable } from "mobx";
import { Utils } from "../../Utils/Utils";
import { ImageModel } from "./ImageModel";

export class ActivityModal extends BaseModel {
  @observable
  start_location?: {
    name: string;
    address: string;
    latitude: string;
    longitude: string;
    notes: string;
  };
  @observable
  end_location?: {
    name: string;
    address: string;
    latitude: string;
    longitude: string;
    notes: string;
  };
  @observable
  title!: string;

  @observable
  start?: string;

  @observable
  end?: string;

  @observable
  images?: Array<any>;

  @observable
  description?: string;

  @observable
  staff_notes?: string;

  @observable
  button?: {
    button_text: string;
    button_url: string;
  };

  @observable
  library?: boolean;

  @observable
  private?: boolean;

  @observable
  private_guests?: [{
    guest_id: string;
    first_name: string;
    last_name: string;
    email: string;
  }];
  toJSON() {
    return Utils.sanitizeObject({
      id: this.id,
      title: this.title,
      start: this.start,
      end: this.end,
      images: this.images,
      description: this.description,
      staff_notes: this.staff_notes,
      start_location: this.start_location,
      end_location: this.end_location,
      button: this.button,
      library: this.library,
      private: this.private,
      private_guests: this.private_guests,
    });
  }
}
