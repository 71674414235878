import axios from "axios";
import { nanoid } from "nanoid";
import envVars from "../../../Env.config";

const baseUrl = envVars.API_BASE_URL;

export class FileService {
  static getInstance(): FileService {
    return new FileService();
  }

  static async uploadFile(file: File) {
    const formData = new FormData();
    const fileName = `${nanoid()}_${file.name.split(" ").join("_")}`;
    formData.append(
      "image",
      new File([file], fileName, {
        ...file,
        lastModified: new Date().getTime(),
        type: file.type,
      })
    );

    return axios
      .post(`${baseUrl}/image`, formData, {
        headers: {
          "Content-Disposition": `filename=${fileName}`,
          "Content-Type": `${file.type}`,
        },
      })
      .then((res: any) => {
        return {
          url: res.data?.image || "",
        };
      });

    throw new Error("Something Went Wrong");
  }
}
