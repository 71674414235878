import React, { FC } from "react";
import MainLayout from "../../MainLayout/MainLayout";

const Dashboard: FC = () => {
  return <MainLayout />;
};

export default Dashboard;

/* Install pure-react-carousel using -> npm i pure-react-carousel */
