import {
  EntityBaseModel,
  EntityIdentifier,
} from "@devslane/mobx-entity-manager";
import { observable } from "mobx";
import { Utils } from "../../Utils/Utils";
import { ImageStore } from "../entities/ImageStore";

export class ImageModel extends EntityBaseModel {
  static _store: ImageStore;

  @observable
  private!: boolean;

  @observable
  name!: string;

  @observable
  _id!: EntityIdentifier;

  @observable
  url!: string;

  @observable
  image_id!: EntityIdentifier;

  @observable
  cover?: boolean;

  @observable
  tags?: Array<string>;

  @observable
  private_guests?: [{
    guest_id: string;
    first_name: string;
    last_name: string;
    email: string;
  }];
  toJSON() {
    return Utils.sanitizeObject({
      id: this.id,
      name: this.name,
      image_id: this.image_id,
      url: this.url,
      tags: this.tags,
      cover: this.cover,
      private_guests: this.private_guests
    });
  }
}
