import { BaseModel } from "@devslane/mobx-entity-manager";
import { observable } from "mobx";
import { TripStore } from "store/entities/TripStore";
import { Utils } from "../../Utils/Utils";

export class OverviewModal extends BaseModel {
  @observable
  chat_types!: {
    group_chat: boolean;
    customer_service: boolean;
    news_updates: boolean;
    staff_chat: boolean;
    parent_chat: boolean;
  };
  @observable
  name!: string;
  @observable
  location!: string;
  @observable
  description!: string;
  @observable
  start_date!: string;
  @observable
  end_date!: string;
  @observable
  trip_code!: string;
  @observable
  parent_code!: string;
  @observable
  cover_image!: string;

  toJSON() {
    return Utils.sanitizeObject({
      id: this.id,
      chat_types: this.chat_types,
      name: this.name,
      location: this.location,
      description: this.description,
      start_date: this.start_date,
      end_date: this.end_date,
      trip_code: this.trip_code,
      parent_code: this.parent_code,
      cover_image: this.cover_image,
    });
  }
}
