import React from "react";
import { Route, RouteProps } from "react-router-dom";

import { observer } from "mobx-react";
import { AppContext } from "../App.context";
import { LoginStatus } from "../store";
import { Redirect } from "react-router";

const PublicRoute: React.FC<RouteProps> = (routeProps) => {
  const {
    store: {
      userStore: { loginStatus },
    },
  } = AppContext;
  if (loginStatus === LoginStatus.LOGGED_IN) {
    return (
      <Route
        render={() => (
          <Redirect
            to={{
              pathname: "/trips",
            }}
          />
        )}
      />
    );
  }
  return (
    <>
      <div className="h-screen w-screen justify-center bg-white-100">
        {/* <div className="h-screen w-screen flex items-center justify-center bg-white-100"> */}
        {/* <div
          className="w-1/3 h-screen items-center justify-center bg-no-repeat bg-center bg-cover"
          style={{ backgroundImage: `url(${background})` }}
        >
          <div className={"flex flex-col items-center mt-24"}>
            <img
              className="object-contain opacity-90 max-h-20"
              src={logo}
              alt=""
            />{" "}
            <span className="text-white pl-2 opacity-90 text-xl lg:text-3xl">
              Mobile App for Tour Operators
            </span>
          </div>
        </div> */}
        <Route {...routeProps} />
      </div>
    </>
  );
};

export default observer(PublicRoute);
