export const AUTH_TOKEN = "auth_token";

export class StorageService {
  private static _instance: StorageService;

  private constructor() {
    console.log("[SILLY] StorageService Constructor");
  }

  static getInstance(): StorageService {
    if (!this._instance) {
      this._instance = new StorageService();
    }

    return this._instance;
  }

  setValue(key: string, value: string): void {
    localStorage.setItem(key, value);
  }

  getValue(key: string): string | null {
    return localStorage.getItem(key);
  }

  removeValue(key: string): void {
    if (typeof localStorage !== "undefined") {
      localStorage?.removeItem(key);
    }
  }

  async setAuthToken(token: string): Promise<void> {
    this.setValue(AUTH_TOKEN, token);
  }

  async getAuthToken(): Promise<string | null> {
    return this.getValue(AUTH_TOKEN);
  }

  async removeAuthToken(): Promise<void> {
    this.removeValue(AUTH_TOKEN);
  }

  async reset(): Promise<void> {
    this.removeValue(AUTH_TOKEN);
  }
}
