import { useField } from "formik";
import React from "react";

import InputHelper from "../Input/Inputhelper/InputHelper";
import SelectMenu, { SelectMenuType } from "./SelectMenu";
import { AutoCompleteItemType } from "../../types/AutoComplete.type";

interface FormikSelectMenuProps {
  name: string;
  label?: string;
  placeholder?: string;
  type?: SelectMenuType;
  autoComplete?: boolean;
  items: AutoCompleteItemType[];
  allowClear?: boolean;
  onChange?: (value: any) => void;
  allowAddItem?: boolean;
  disabled?: boolean;
}

const FormikSelectMenu: React.FC<FormikSelectMenuProps> = (props) => {
  const [inputProps, meta, helpers] = useField(props.name);

  const { value: selection, error } = meta;
  const { setValue: setSelection } = helpers;

  return (
    <>
      <SelectMenu
        name={props.name}
        selected={selection}
        label={props.label}
        items={props.items}
        type={props.type}
        allowClear={props.allowClear}
        autoComplete={props.autoComplete}
        placeholder={props.placeholder}
        onChange={(value): void =>
          props.onChange ? props.onChange(value) : setSelection(value)
        }
        allowAddItem={props.allowAddItem}
        disabled={props.disabled}
      />
      {error && <InputHelper type={"error"} text={error} />}
    </>
  );
};

export default FormikSelectMenu;
