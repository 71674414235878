import React, { FC, useEffect, useState } from "react";
import Modal from "../../../shared/Modal/Modal";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import Table from "../../../shared/Table/Table";
import GenericHeader from "../../../shared/Headers/GenericHeader";
import AddImageModal from "./AddImageModal";
import { AppContext } from "../../../App.context";
import { observer } from "mobx-react";
import IconButton from "../../../shared/IconButton/IconButton";

import { ImageModel } from "../../../store/models/ImageModel";
import { EntityIdentifier } from "@devslane/mobx-entity-manager";
import { ERROR_WENT_WRONG } from "../../../constants/message";
import { InputWithoutFormik } from "../../../shared/Input/Input";
import Loader from "react-loader-spinner";
import { debounce } from "lodash";

const ImageGallery: FC = () => {
  const { store, toast } = AppContext;
  const [openModal, setOpenModal] = useState<boolean>(false);

  const [editData, setEditData] = useState<ImageModel | undefined>();

  const imageList = store.imageStore.imagesList.items;

  const [filterImages, setFilterImages] = useState<ImageModel[]>(imageList);

  const staffTable = {
    headingsArray: ["Image", "Image Name", "Image Tags", "Actions"],
  };
  const businessId = store.userStore.loggedInUser?.memberships?.items?.[0]?.id;

  const handleDelete = async (id: EntityIdentifier) => {
    try {
      if (confirm("Are you sure you want to delete this Image ?")) {
        await store.imageStore.deleteImage({
          image_id: id,
          business_id: businessId!,
        });
        toast.showSuccess("Image Deleted Successfully");
      }
    } catch (e: any) {
      toast.showError(e.message || ERROR_WENT_WRONG);
    }
  };

  const dataArray: any = (filterImages || [])?.map((image) => {
    return [
      <img alt="img" src={image.url || ""} className=" h-20 w-20 " />,
      image.name,
      <span>
        <div className={"flex flex-wrap "}>
          {image?.tags?.map((val: any, index: number) => (
            <div className="bg-indigo-500 h-8 w-24 mb-4 mr-2 md:mb-0 rounded-md flex items-center justify-center">
              <div className="flex items-center">
                <span className="text-md text-white font-normal">{val}</span>
              </div>
            </div>
          ))}
        </div>
      </span>,
      <div className={"flex space-x-3 items-center"}>
        <IconButton
          child={FaEdit}
          className="w-4 h-4"
          onClick={() => setEditData(image)}
          buttonClassName={"text-white rounded-full p-2 bg-indigo-500"}
        />

        <div className="">
          <IconButton
            child={FaTrashAlt}
            onClick={() => handleDelete(image.id)}
            className="w-4 h-4"
            buttonClassName={" text-white rounded-full p-2 bg-error-500"}
          />
        </div>
      </div>,
    ];
  });

  const ifSearchIncludes = (data: ImageModel, searchKey: string) => {
    const searchCriteriaArray: Array<any> = [
      data.name?.trim()?.toLowerCase(),
      ...(data.tags || []).map((tag) => tag?.trim()?.toLowerCase()),
    ];
    if (
      searchCriteriaArray?.some((val) =>
        val?.includes(searchKey?.trim()?.toLowerCase())
      )
    )
      return true;

    return false;
  };

  const handleSearch = debounce((searchKey: string): void => {
    const filtered = imageList.filter((data) => {
      return ifSearchIncludes(data, searchKey);
    });

    setFilterImages(filtered);
  }, 200);

  const closeModal = () => {
    setEditData(undefined);
    setOpenModal(false);
  };

  useEffect(() => {
    setFilterImages(imageList);
  }, [imageList]);

  useEffect(() => {
    store.imageStore.loadImages(businessId!);
  }, []);

  return (
    <>
      <Modal
        modalStyle={{ width: "50rem", maxHeight: "95%", overflowY: "auto" }}
        open={openModal || !!editData}
        onOutsideClick={closeModal}
      >
        <AddImageModal modalHandler={closeModal} editData={editData} />
      </Modal>

      <div className="bg-white">
        <div className="pb-8">
          <GenericHeader
            heading={"Image Gallery"}
            subHeading={
              "Add Images that can be used in your Trips and Activities"
            }
            buttonOnClick={() => setOpenModal(true)}
            buttonText={"Add Image"}
          />
          <hr className="h-0.5 mt-2 bg-gray-50 mx-auto w-full" />

          <div className={"px-8 mt-4"}>
            <InputWithoutFormik
              type="text"
              placeholder="Search Keyword"
              onChange={(e) => handleSearch(e.target.value || "")}
            />
          </div>
          <div className="mt-6 w-full px-8">
            {store.imageStore.imagesList.loading ? (
              <div className={"flex justify-center mx-auto"}>
                <Loader type="BallTriangle" height={30} width={30} />
              </div>
            ) : (
              <Table headers={staffTable.headingsArray} data={dataArray} />
            )}
          </div>
          <hr className="h-0.5 mt-10 bg-gray-50 mx-auto w-full" />
        </div>
      </div>
    </>
  );
};
export default observer(ImageGallery);
