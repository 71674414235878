import {
  EntityBaseModel,
  EntityIdentifier,
} from "@devslane/mobx-entity-manager";
import { observable } from "mobx";
import { Utils } from "../../Utils/Utils";
import { BusinessStore } from "../entities/BusinessStore";
import { UserRoles } from "../../constants/enums/UserRoles";

export class BusinessModel extends EntityBaseModel {
  static _store: BusinessStore;

  @observable
  business_id!: EntityIdentifier;

  @observable
  email!: string;

  @observable
  send_emails_from!: string;

  @observable
  name?: string;

  @observable
  business?: string;

  @observable
  role!: UserRoles;

  @observable
  logo?: string;

  @observable
  website?: string;

  @observable
  description?: string;

  toJSON() {
    return Utils.sanitizeObject({
      id: this.business_id || this.id,
      business_id: this.business_id,
      name: this.name || this.business,
      role: this.role,
      description: this.description,
      website: this.website,
      logo: this.logo,
      email: this.email,
      send_emails_from: this.send_emails_from,
    });
  }
}
