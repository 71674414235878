import React, { FC } from "react";

const TermsAndConditions: FC = () => {
  return (
    <>
      <div className="flex flex-col  w-2/3 px-20 bg-white rounded-lg sm:px-6 md:px-20 lg:px-40">
        <p className="lg:text-5xl md:text-4xl text-2xl font-bold leading-10 text-gray-800 mt-10 mb-10 leading-loose">
          Terms and Conditions{" "}
        </p>
        <p>
          This app is provided by SafeTravels Pty Ltd{" "}
          <a href="https://safetravels.com"></a>. You therefore agree to
          SafeTravel's Terms and Conditions below.
        </p>

        <p>
          www.safetravels.com and the SafeTravels mobile application is operated
          by SafeTravels Pty Ltd (A.C.N. 166 391 993) trading as SafeTravels
          (“SafeTravels”). Access to and use of the website, or any of its
          associated products, including the SafeTravels web application and
          mobile application and any other web applications or mobile
          applications incorporating the Trip Management Platform (collectively
          and severally “the Services”), is provided by SafeTravels.
        </p>
        <p>
          By clicking “I ACCEPT”, the Travel Company acknowledge that the Travel
          Company has read, understood and agree to be bound by these Travel
          Company Terms of Use (“Travel Company Terms”) and the SafeTravels
          Privacy Policy. The Travel Company may not use the Services if the
          Travel Company does not agree to these Travel Company Terms. If the
          Travel Company are accepting these Travel Company Terms on behalf of a
          Travel Company, the Travel Company warrant that the Travel Company are
          authorised to enter into these Travel Company Terms on behalf of the
          Travel Company and in doing so, the Travel Company consents to enter
          into these Travel Company Terms.
        </p>
        <p>
          SafeTravels reserves the right to review and change any of the Travel
          Company Terms by updating this page at any time in SafeTravel’s sole
          discretion. Any changes to the Travel Company Terms take immediate
          effect from the date of their publication on this page. The Travel
          Company is required to review these Travel Company Terms frequently
          and familiarise itself with any changes. SafeTravels will endeavor to,
          but will be under no obligation to advise the Travel Company when
          these Travel Company Terms are varied.
        </p>
        <p>
          The Travel Company acknowledge and agree that although we will use all
          reasonable efforts to ensure that the Services are available,
          temporary interruptions of the Services may occur. SafeTravels shall
          not be liable to any person or entity for loss or damage incurred by
          such downtimes. All content and services provided on or through this
          Services are provided “AS IS” and “AS AVAILABLE” for the Travel
          Company’s use. The content is provided without warranties or
          conditions of any kind either express or implied, including but not
          limited to implied warranties and conditions of merchantability,
          fitness for a particular purpose or non-infringement. The Travel
          Company’s use of this Site is solely at the Travel Company’s risk.
          SafeTravels reserve the right to withdraw or amend the Services
          without notice. SafeTravels may restrict access to some parts of or
          the entire Services, from time to time.
        </p>
        <p>TRAVEL COMPANY ACCOUNT</p>
        <p>
          In order to use the Services, the Travel Company may be required to
          provide information about itself (such as identification or contact
          details) to become a registered Travel Company.
        </p>
        <p>
          In becoming a registered Travel Company, the Travel Company may be
          issued with a Travel Company ID and/or password (“Account
          Information”). The Travel Company agrees to keep the Travel Company’s
          Account Information confidential at all times and must not disclose it
          to any third parties. The Travel Company agrees to be fully
          responsible for activities that relate to the Travel Company’s Account
          Information. If the Travel Company has reason to believe that the
          Travel Company’s Account Information has been obtained by someone else
          without the Travel Company’s consent, the Travel Company must inform
          us immediately to disable the Travel Company’s Travel Company account.
        </p>
        <p>
          The Travel Company Travel Company Account Information will provide the
          Travel Company access to the internet platform provided by SafeTravels
          which allows the Travel Company to manage tours and Trip Member
          information (“Trip Management Platform”). The Travel Company agrees
          that any information the Travel Company provide to SafeTravels will
          always be accurate, correct and up to date. SafeTravels may suspend or
          terminate the Travel Company’s access to the Services if any
          information provided is inaccurate, outdated or incomplete.
        </p>
        <p>
          Any personal data or information provided by the Travel Company is
          processed by us in accordance with our Privacy Policy. By registering
          to the Site and providing any such data or other personal information:
        </p>
        <p>
          the Travel Company agree that all information provided during the
          registration process is true and accurate and the Travel Company will
          update this information in order to keep it current, complete and
          accurate; and
        </p>
        <p>
          the Travel Company agree to the Travel Company Terms of our Privacy
          Policy.
        </p>
        <p>
          In creating a Travel Company Account, the Travel Company understand
          and agree that access and use of the Services is limited,
          non-transferable and allows for the sole use of the Services by the
          Travel Company.
        </p>
        <p>
          The Travel Company acknowledge and agree that any requests, reminders,
          invitations or related correspondence that the Travel Company cause to
          be sent to other Travel Company Account’s and/or third parties through
          the Services will be sent directly to that person from the Travel
          Company’s email address.
        </p>
        <p>
          In creating a Travel Company Account, the Travel Company agrees to
          receive messages from SafeTravels. To stop receiving these messages,
          the Travel Company must cancel the Travel Company’s Travel Company
          Account by contacting us at hello@safetravels.com.
        </p>
        <p>SCOPE OF SERVICES</p>
        <p>
          The Services are intended to provide an online and mobile service to
          store, manage, organise, view, access and share trip information with
          Trip Members (your Trip Members, customers, members), as well as
          provide a mechanism for Trip Members to provide required information
          through their SafeTravels profile account.
        </p>
        <p>
          In order to add Your Customers to a Trip on the Trip Management
          Platform, the Travel Company may purchase pre-paid credits (“Trip
          Credits”). Payments for Trip Credits are non-refundable and Trip
          Credits are non-transferrable.
          <br />
          <br />
          By utilizing the Trip Management Platform, the Travel Company may:
        </p>
        <p>create, configure and outline Trip Content (see 6. Trip Content);</p>
        <p>
          Add Trip Members (Your Customers) to a Trip allowing them access to
          the Trip Content through the mobile application (see 5. Trip Members)
          ;
        </p>
        <p>
          Send messages and communicate with Trip Members for a period before,
          during and after a Trip (see 6.5)
        </p>
        <p>
          access Emergency Profile information of Trip Members for a specific
          Trip from the trip start until its end date.
        </p>
        <p>USE OF SERVICES</p>
        <p>
          In using the Services, the Travel Company undertake, warrant and agree
          as follows:
        </p>
        <p>
          the Services will not be used in connection with any commercial
          endeavors, except those that are specifically endorsed or approved by
          SafeTravels;
        </p>
        <p>
          posting of commercial advertisements, affiliate links, and other forms
          of solicitation may result in suspension or termination of
          Registration;
        </p>
        <p>
          the Travel Company will immediately notify SafeTravels of any
          unauthorised use of the Travel Company Account Information or any
          breach of security within the Services of which the Travel Company
          have become aware;
        </p>
        <p>
          the Travel Company has sole responsibility for protecting the
          confidentiality of the Travel Company’s Account Information. Use of
          the Travel Company’s Account Information by any other person may
          result in the immediate cancellation of the Travel Company’s Account;
        </p>
        <p>
          the Travel Company is solely responsible for the Travel Company’s
          interactions with its Trip Members on Trips;
        </p>
        <p>
          although SafeTravels does not have any obligation to monitor the
          Services, SafeTravels reserves the right (but is not obliged) to
          pre-screen, review, flag, filter, modify, refuse or remove any or all
          Trip Content from the Services. SafeTravels may also provide tools to
          filter out explicit Trip Content;
        </p>
        <p>
          SafeTravels, subject to local applicable laws, reserves the right to
          suspend, discontinue or cancel the Travel Company’s Account at any
          time and may suspend or deny, in our sole discretion, the Travel
          Company’s access to all or any portion of the Services without notice
          if the Travel Company breach any of these Travel Company Terms or any
          applicable law or if we find the Travel Company’s conduct adversely
          impacts our name or reputation or violates the rights of those of
          another party.
        </p>
        <p>TRIP MEMBERS</p>
        <p>
          Adding a Trip Member to a Trip by a Travel Company creates a Link
          between the SafeTravels user account of the Trip Member and the Trip,
          allowing the Trip Member access to Trip Content that the Travel
          Company provides.
          <br />
          <br />
          In the event that a Trip Member is added to a Trip and the Trip Member
          does not have an existing SafeTravels user account, a new SafeTravels
          user account will be created for the Trip Member and they will be sent
          a communication via email notifying them of the action and that they
          must Activate their SafeTravels user account in order to gain access
          to the Trip Content for a Trip.
        </p>
        <p>
          The SafeTravels user account data of a Trip Member is only made
          available to the Travel Company for the purpose of SafeTravels
          providing the Services for the Trip the Trip Member is linked to.
        </p>
        <p>
          Additionally, in the event that a Travel Company elects to use the
          SafeTravels Emergency Profile functionality for a Trip, the Travel
          Company shall receive access to the Emergency Profile information of a
          Trip Member’s SafeTravels account for the duration of the Trip period.
        </p>
        <p>
          The data incorporated into any SafeTravels user account as well as any
          aggregated data during the course of the Trip incorporated into the
          Trip Member’s SafeTravels user account is the sole ownership of
          SafeTravels.
        </p>
        <p>
          Any data collected through the addition of Custom Fields (through
          customised development for a Travel Company) is the ownership of the
          Travel Company.
        </p>
        <p>
          The Travel Company must not modify the physical or digital copies of
          any Trip Member data the Travel Company prints off or downloads in any
          way, and the Travel Company must not use any illustrations,
          photographs, video or audio, or any graphics separately from any
          accompanying text.
        </p>
        <p>TRIP CONTENT</p>
        <p>
          The Travel Company understand that the Services may provide the Travel
          Company with the ability to upload, post, transmit, share, store,
          distribute, disseminate or otherwise make available (“post”)
          information such as data files, written text, computer software,
          music, audio files, photographs, or words, sounds, images or videos
          (“Trip Content”). The Travel Company warrant that the Travel Company
          own or have the right to use and post such Trip Content through the
          Services and the Travel Company possess the records and evidence to
          support this. The Travel Company agree that the Travel Company will
          not post inaccurate, misleading or false Trip Content on the Services
          and if, subsequently, this Trip Content becomes obsolete, inaccurate
          or false, the Travel Company will promptly update or remove the Trip
          Content.
        </p>
        <p>
          The Travel Company agree that the Travel Company are solely
          responsible for any Trip Content that the Travel Company post while
          using the Services and are solely responsible for any damage the
          Travel Company incur or cause any other person or entity as a result
          of that Trip Content and the Travel Company agree to keep SafeTravels
          indemnified with respect to same.
        </p>
        <p>
          If the Travel Company are posting information about another person,
          such as Trip Members on a specific Trip, the Travel Company warrant to
          SafeTravels that the Travel Company have that person’s prior consent
          to post their information on the Services.
        </p>
        <p>
          Any opinions, advice, statements, services, offers, or other
          information or content expressed or made available by Trip Companies
          are those of the respective author(s) or distributor(s) and not of
          SafeTravels.
        </p>
        <p>
          Trip Content will remain available to the Travel Company and Trip
          Members for a period of 12 months past the conclusion of the Trip. All
          documents (pdf’s) uploaded to the Trip Management Platform as part of
          the Trip Content will be removed from the Trip Management Platform for
          a period of 3 months past the conclusion of the Trip.
        </p>
        <p>PROHIBITED USE</p>
        <p>
          In using the Services, the Travel Company agree that the Travel
          Company will not:
        </p>
        <p>
          post any inappropriate, profane, defamatory, obscene, indecent or
          unlawful topic, name, material or information;
        </p>
        <p>
          post any information that promotes discrimination, bigotry, racism,
          hatred, harassment, harm or violence against any individual or group;
        </p>
        <p>
          use the Services in connection with advertising and promotions,
          surveys, contests, pyramid schemes, chain email, junk email, spamming
          or any duplicative or unsolicited messages;
        </p>
        <p>
          attempt to probe, test or penetrate the vulnerability of any Services
          systems;
        </p>
        <p>
          post or otherwise make available information that contain images,
          photographs, software or other material protected by intellectual
          property laws, including, but not limited to, copyright or trade mark
          laws unless the Travel Company own or control the rights thereto or
          have received all necessary authorisation and consent to do same;
        </p>
        <p>
          use any material or information, including but not limited to, words,
          images, sounds and videos, which are made available through the
          Services in any manner that infringes any copyright, trade mark,
          patent, trade secret, or other proprietary right of any party;
        </p>
        <p>
          upload files that contain viruses, Trojan horses, worms or any other
          similar harmful software or programs that may damage the operation of
          another person’s computer or electronic device;
        </p>
        <p>
          download any data or information posted by another Member that the
          Travel Company know, or reasonably should know, cannot be legally
          reproduced, displayed, performed, and/or distributed in any such
          manner;
        </p>
        <p>
          modify, copy, distribute, transmit, display, perform, reproduce,
          publish, license, create derivative works from, sublicense, transfer,
          assign, rent, sell or otherwise convey any information, software,
          products or services obtained from the Services without the prior
          written consent from SafeTravels;
        </p>
        <p>
          disassemble, decompile or otherwise reverse engineer all or any
          portion of the Services or Trip Content;
        </p>
        <p>
          impose or attempt to impose an unreasonable disproportionately large
          load, as determined by SafeTravels in its sole discretion, on the
          Services’ infrastructure;
        </p>
        <p>
          interfere or attempt to interfere with the normal course and process
          of the Services;
        </p>
        <p>
          bypass any computer systems or secured networks connected to the
          Services;
        </p>
        <p>
          violate any applicable laws or regulations or other guidelines which
          may be applicable;
        </p>
        <p>harvest or otherwise collect information about others;</p>
        <p>create a false identity for the purpose of misleading others;</p>
        <p>
          use, download or otherwise copy, or provide (whether or not for a fee)
          to a person or entity any directory of Members or usage information;
          or
        </p>
        <p>
          do any other act that may constitute an improper use of the Services,
          as determined by SafeTravels in its sole discretion.
        </p>
        <p>AFFILIATE LINKS</p>
        <p>
          The Services may link to SafeTravels’ affiliate booking partners. The
          Travel Company acknowledge that SafeTravels is not responsible for any
          third-party websites or resources linked to the Services, in
          particular with respect to its availability or accuracy of the website
          and the quality of Trip Content, products or services on the website.
          Links to these websites do not constitute endorsement by SafeTravels.
        </p>
        <p>
          The Travel Company acknowledges sole responsibility and bears all
          risks arising from the Travel Company’s use of such websites and
          resources. SafeTravels make no representation about any other website
          the Travel Company access through this one. Please understand other
          websites are independent from our sites so we do not accept
          responsibility for such websites.
        </p>
        <p>
          The Travel Company acknowledges and agrees that it does not have any
          claim to any revenue generated by SafeTravels for the provision of
          links to SafeTravels’ affiliate booking partners.
        </p>
        <p>SUPPORT</p>
        <p>
          SafeTravels will serve as the point of contact for all inquiries and
          support requests from the Travel Company’s Trip Members. Support is
          offered through an in-mobile application functionality and via email.
        </p>
        <p>
          SafeTravels aims to respond to support requests within 24 hours from
          the lodgment of the support request during Business Days.
        </p>
        <p>
          SafeTravels will use all commercially reasonable efforts to provide
          technical support, including:
        </p>
        <p>
          provision of instructions and held in emergency cases relating to the
          Services;
        </p>
        <p>help resolve blocking issues; and</p>
        <p>help identify the nature of issues and propose solutions.</p>
        <p>INTELLECTUAL PROPERTY</p>
        <p>
          All rights (including copyright) in the content and compilation of the
          Services (including text, graphics, logos, button icons, video images,
          audio clips and software) as well as Trip Member data are reserved by
          SafeTravels.
        </p>
        <p>
          SafeTravels retains all rights, title and interest in and to the
          Services. Nothing the Travel Company do on or in relation to the
          Services will transfer any:
        </p>
        <p>
          business name, trading name, domain name, trademark, industrial
          design, patent, registered design or copyright;
        </p>
        <p>
          right to use or exploit a business name, trading name, domain name,
          trade mark or industrial design; or
        </p>
        <p>
          thing, system or process that is the subject of a patent, registered
          design or copyright (or an adaptation or modification of such a thing,
          system or process),
        </p>
        <p>to the Travel Company.</p>
        <p>
          The Travel Company acknowledges and agrees that the Trip Content the
          Travel Company post on the Services is owned and controlled by the
          Travel Company. The Travel Company grants SafeTravels a non-exclusive
          license to use the Trip Content for the purpose of running the
          Services.
        </p>
        <p>
          The Travel Company may not, without the prior written permission of
          SafeTravels and the permission of any other relevant rights owners,
          broadcast, republish, up-load to a third party, transmit, post,
          distribute, show or play in public, adapt or change in any way the
          Trip Content of, or create a derivative work from the Services for any
          purpose. This prohibition does not extend to materials on the Services
          which are freely available for re-use or are in the public domain.
        </p>
        <p>
          SafeTravel’s Intellectual Property must not be used in connection with
          a product or service that is not affiliated with SafeTravels or in any
          way brings SafeTravels in disrepute.
        </p>
        <p>
          SafeTravels has the right to include a reference to the Travel Company
          and its logo on the SafeTravels website, and in marketing literature,
          labeling, and in promotional materials, including any press releases
          relating to the Travel Company’s use of the Services. However, the
          Travel Company may not include any references to SafeTravels, its
          logo, or issue any press releases regarding its use of the Services
          without the prior written consent of SafeTravels. Either party may
          re-publish information contained in a previously approved press
          release, including on a party’s website or marketing materials.
        </p>
        <p>PRIVACY</p>
        <p>
          The Travel Company’s use of the Services is governed by SafeTravel’s
          Privacy Policy.
        </p>
        <p>GENERAL DISCLAIMER</p>
        <p>
          Use of the Services is at the Travel Company’s own risk. The Services
          are provided to the Travel Company “as is” and “as available” without
          warranty or condition of any kind. None of SafeTravel’s affiliates or
          directors, officers, employees, agents, contributors, third party
          content providers or licensors makes any express or implied
          representation or warranty about, or shall be liable, in contract,
          tort (including negligence) or otherwise, for any direct, indirect,
          special or consequential loss, damages or reliance in connection with
          the Services, its use or the Trip Content. This includes (but is not
          restricted to) loss or damage the Travel Company might suffer as a
          result of any of the following:
        </p>
        <p>
          failure of performance, error, omission, interruption, deletion,
          defect, failure to correct defects, delay in operation or
          transmission, computer virus or other harmful component, loss of data,
          communication line failure, unlawful third party conduct, or theft,
          destruction, alteration or unauthorised access to records;
        </p>
        <p>
          the accuracy, suitability or currency of any information on the
          Services, or any of its Trip Content (including third party material
          and advertisements on the Services);
        </p>
        <p>
          costs incurred as a result of the Travel Company using the Services;
        </p>
        <p>
          of any failure to complete a transaction, or any loss arising from
          e-commerce transacted on the Services; and/or
        </p>
        <p>
          for any defamatory, threatening, offensive or unlawful conduct of
          third parties or publication of any materials relating to or
          constituting such conduct.
        </p>
        <p>LIMITATION OF LIABILITY</p>
        <p>
          SafeTravel’s total liability to the Travel Company for loss, damage or
          reliance shall be limited to the re-supply of the information or
          Services or payment of the amount paid by the Travel Company for
          accessing the Services.
        </p>
        <p>
          The Travel Company expressly understand and agree that SafeTravels and
          its affiliates, employees, agents, contributors, third party content
          providers and licensors shall not be liable to the Travel Company for
          any direct, indirect, incidental, special consequential or exemplary
          damages which may be incurred by the Travel Company, however caused
          and under any theory of liability. This shall include, but is not
          limited to, any personal, commercial, reputational or any other
          tangible or intangible loss (whether incurred directly or indirectly).
        </p>
        <p>
          SafeTravels does not control and is not responsible for Trip Content
          that the Travel Company posted through the Services and is not
          responsible for any offensive, inappropriate, obscene or unlawful Trip
          Content the Travel Company may encounter on the Services. SafeTravels
          do not endorse the any of the Trip Content, and expressly disclaim any
          and all liability in connection with them. In no event shall we be
          liable for any claims by a third party in tort or contract, or
          pursuant to the Competition and Consumer Act 2010 (Cth) including, but
          not limited, to any misleading statements made and/or incorporated
          into any Trip Content posted on the Site or Services. It is the Travel
          Company’s sole responsibility to ensure the accuracy of the data
          inputted.
        </p>
        <p>
          By agreeing to receive access privileges to Trip Member data and
          Emergency Profiles the Travel Company acknowledges and agrees that
          such information may include information governed by the Privacy Act
          1988 (Cth). SafeTravels is not liable for any loss the Travel Company
          may suffer as a result of or in consequence of that Travel Company
          being granted access to the Trip Member data and Emergency Profiles.
        </p>
        <p>
          The Travel Company acknowledges that SafeTravels is not providing
          medical information or substituting the advice of health care
          professionals. All Trip Content is designed to supplement, and not
          replace, the advice of health care professionals.
        </p>
        <p>
          Trip Member data and Emergency Profiles (including but not limited to
          the Trip Members’ personal information, health information or
          sensitive information as defined under the Privacy Act 1988 (Cth)),
          may be transmitted to, stored in and retrieved from single or multiple
          computer servers located inside or outside of Australia. While
          SafeTravels exercises caution and discretion in selecting reputable
          servers and will make reasonable attempts to securely transfer data,
          the Travel Company agrees that SafeTravels will not be held liable at
          all for any loss the Travel Company or anyone else may suffer as a
          result of such data transmission, storage or retrieval or the failure
          of servers. The Travel Company acknowledges and agrees this is
          necessary for the purpose of providing Services to the Travel Company.
        </p>
        <p>
          The Travel Company have sole responsibility for adequate security
          protection and backup of data and/or equipment used in connection with
          the Travel Company’s usage of the Services and will not make a claim
          against for lost data, re-run time, inaccurate instruction, work
          delays or lost profits resulting from the use of the Services. The
          Travel Company must not assign or otherwise dispose of Travel Company
          account to any other person.
        </p>
        <p>INDEMNITY</p>
        <p>
          As a condition of the Travel Company’s access to and use of the
          Services, the Travel Company agree to indemnify SafeTravels, its
          affiliates, employees, agents, contributors, third party content
          providers and licensors from and against:
        </p>
        <p>
          all actions, suits, claims, demands, liabilities, costs, expenses,
          loss and damage (including legal fees on a full indemnity basis)
          incurred, suffered or arising out of or in connection with any Trip
          Content the Travel Company post through the Services;
        </p>
        <p>
          all actions, suits, claims, demands, liabilities, costs, expenses,
          loss and damage (including legal fees on a full indemnity basis)
          incurred, suffered or arising out of or in connection with any Trip
          Content posted about the Travel Company through the Services with the
          Travel Company’s consent;
        </p>
        <p>
          any inaccurate, misleading or false Trip Content the Travel Company
          post of the Services about itself or any other person; and/or
        </p>
        <p>
          any direct or indirect consequences of the Travel Company accessing,
          using or transacting on the Services or attempts to do so and any
          breach by the Travel Company or the Travel Company’s agents of these
          Travel Company Terms.
        </p>
        <p>
          This indemnification section survives the expiration of the Travel
          Company’s registration, and applies to claims arising both before and
          after the registration ends.
        </p>
        <p>
          DISPUTE RESOLUTION
          <br />
          <br />
          If a dispute arises out of or relates to the Services, except where
          urgent interlocutory relief is sought, neither party may commence
          legal proceedings unless:
        </p>
        <p>
          the party claiming a dispute has given written notice of the dispute
          to the other; and
        </p>
        <p>
          both parties endeavor, in good faith, to resolve the dispute
          expeditiously by any means upon which they mutually agree.
        </p>
        <p>
          If the dispute remains unresolved within 30 days after notice has been
          given under 15.1, the parties agree that a mediator will be appointed
          by the President of the Law Institute of Victoria or his/her nominee
          and the parties will bear the costs associated with the mediation
          equally. If the dispute is not resolved via mediation, either party
          may institute legal proceedings concerning the subject matter of the
          dispute.
        </p>
        <p>
          During any mediation or other legal proceedings, both parties may be
          represented by a duly qualified legal practitioner.
        </p>
        <p>TERMINATION OF CONTRACT</p>
        <p>
          The Travel Company Terms will continue to apply until terminated by
          expiry pursuant to a SafeTravels Service Agreement or as set out
          below.
        </p>
        <p>
          A “zero balance” of Tour Credits does not mean that these Travel
          Company Terms are terminated. If the Travel Company wants to terminate
          the Travel Company’s legal agreement with SafeTravels, the Travel
          Company may do so by terminating the Travel Company’s Registration by
          using the “close my account” function within the Services.
        </p>
        <p>
          The Travel Company’s Registration may be automatically renewed at the
          end of each period unless the Travel Company cancels the Travel
          Company’s Registration before the end of the current period. Where the
          Travel Company’s Registration is renewed, the Travel Company
          authorises SafeTravels and its third party payment processors to
          charge the Travel Company’s credit card for the fees associated with
          the Registration (if any) for the renewed period.
        </p>
        <p>
          SafeTravels may terminate its legal agreement with the Travel Company
          at any time if:
        </p>
        <p>
          the Travel Company have breached any provision of the Travel Company
          Terms or indicated that the Travel Company intend to breach any
          provision of the Travel Company Terms;
        </p>
        <p>it is required to do so by law;</p>
        <p>
          the Services are no longer provided in the country in which the Travel
          Company are resident or from which the Travel Company use the
          Services; or
        </p>
        <p>
          the provision of the Services to the Travel Company is, in SafeTravels
          sole opinion, no longer commercially viable.
        </p>
        <p>
          Clauses relating to indemnification, warranty, and limitation of
          liability will survive termination for any reason whatsoever.
        </p>
        <p>NO WAIVER</p>
        <p>
          If we fail, at any time, to insist upon strict performance of the
          Travel Company’s obligations under these Travel Company Terms, or if
          we fail to exercise any of the rights and remedies we are entitled to
          under these Travel Company Terms, this will not constitute a waiver of
          such rights or remedies and it will not relieve the Travel Company
          from compliance with the Travel Company’s obligations.
        </p>
        <p>
          If we waive a default, it does not constitute a waiver of any
          subsequent defaults.
        </p>
        <p>FORCE MAJEURE</p>
        <p>
          SafeTravels shall be under no liability to the Travel Company in
          respect of anything that, if not for this provision, would or might
          constitute a breach of these Travel Company Terms, where this arises
          out of circumstances beyond our control, including but not limited to:
        </p>
        <p>acts of god;</p>
        <p>natural disasters;</p>
        <p>sabotage;</p>
        <p>accident;</p>
        <p>riot;</p>
        <p>shortage of supplies, equipment, and materials;</p>
        <p>strikes and lockouts;</p>
        <p>civil unrest;</p>
        <p>computer hacking; or</p>
        <p>malicious damage.</p>
        <p>SEVERABILITY</p>
        <p>
          If any court decides that any of the provisions of these Travel
          Company Terms are invalid, unlawful or unenforceable to any extent,
          that term will, to that extent only, be severed from the remaining
          Travel Company Terms. The rest of these Travel Company Terms will
          continue to be valid.
        </p>
        <p>ENTIRE AGREEMENT</p>
        <p>
          These Travel Company Terms, and the documents expressly referred to in
          them, constitute the entire agreement between the Travel Company and
          SafeTravels, and supersede all previous discussions, correspondence,
          negotiations, previous arrangements, understanding or agreement
          between us relating to the Services.
        </p>
        <p>
          SafeTravels each acknowledge that, in entering into these Travel
          Company Terms, neither of us relies on, and subsequently will have any
          remedies for, any representation or warranty that is not set out in
          these Travel Company Terms.
        </p>
        <p>GOVERNING LAW AND JURISDICTION</p>
        <p>
          The Services offered by SafeTravels are intended to be viewed by
          residents of Australia. In the event of any dispute arising out of or
          in relation to the Services, the Travel Company agree that these
          Travel Company Terms will be governed by the laws of Victoria,
          Australia and the exclusive venue for resolving any dispute shall be
          in the Courts of Victoria.
        </p>
        <p>
          If any part of these Travel Company Terms is found to be invalid or
          unenforceable, it shall be severed to the extent possible without
          affecting the remainder.
        </p>
        <p>UPDATES TO THESE TRAVEL COMPANY TERMS</p>
        <p>
          SafeTravels reserve the right, in our discretion, to correct any
          errors or omissions in any part of the Services. SafeTravels may
          restrict access to parts or the entire Services at any time,
          including, but not limited to, Trip Content, certain features and
          Services, hours of availability, and equipment needed for access or
          use, without notice or liability.
        </p>
        <p>
          Any material on the Services may be out of date at any given time and
          we are under no obligation to update such material.
        </p>
        <p>
          SafeTravels reserve the right, in our sole discretion, to change,
          modify, add or remove any part of these Travel Company Terms, in whole
          or in part, at any time. Notification of the changes to these Travel
          Company Terms will be posted on the Services and will be effective
          immediately, unless expressed otherwise.
        </p>
        <p>
          It is the Travel Company’s sole responsibility to periodically check
          these Travel Company Terms for any changes. If the Travel Company do
          not agree with any of the changes to these Travel Company Terms, it is
          the Travel Company’s sole responsibility to deregister from the
          Services. The Travel Company’s continued use of the Services will be
          deemed as the Travel Company’s acceptance thereof.
        </p>
        <p>
          SafeTravels may assign or sublicense any of our rights or obligations
          under these Travel Company Terms at any time, without obtaining the
          Travel Company’s consent.
        </p>
        <p>INDEPENDENT LEGAL ADVICE</p>
        <p>
          The Travel Company agrees that the Travel Company have had the
          opportunity to obtain independent legal advice for any provisions of
          these Travel Company Terms which the Travel Company do not fully
          understand.
        </p>
        <p>
          SafeTravels contact information:
          <br />
          <br />
          SafeTravels Administration
          <br />
          <br />
          10 Stonycroft St
          <br />
          <br />
          Aspley Qld
          <br />
          <br />
          Australia
          <br />
          <br />
          Email: hello@safetravels.com
        </p>
        <p>Last updated on: 13 July 2021</p>
      </div>
    </>
  );
};
export default TermsAndConditions;
