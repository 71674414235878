import React, { useState } from "react";
import classNames from "classnames";

export interface Props {
  initialState?: boolean;
  iconEnable?: boolean;
  onChange: (value: boolean) => void;
}

export const Toggles: React.FC<Props> = ({
  onChange,
  initialState,
  iconEnable,
}) => {
  const [on, setOn] = useState(initialState);
  // I think this in not the way. Because of this waring message displayed below:
  // Warning: A component is changing a controlled input of type checkbox to be uncontrolled. Input elements should not switch from controlled to uncontrolled (or vice versa). Decide between using a controlled or uncontrolled input element for the lifetime of the component.
  // Pushing it for now
  React.useEffect(() => setOn(initialState), [initialState]);

  return (
    // <!-- On: "bg-indigo-600", Off: "bg-gray-200" -->
    <span
      role="checkbox"
      tabIndex={0}
      aria-checked="false"
      onClick={() => {
        setOn(!on);
        onChange(!on);
      }}
      className={classNames(
        !on ? "bg-gray-200" : "bg-indigo-500",
        "relative",
        "inline-flex",
        "flex-shrink-0",
        "h-6",
        "w-11",
        "border-2",
        "border-transparent",
        "rounded-full",
        "cursor-pointer",
        "transition-colors",
        "ease-in-out",
        "duration-200",
        "focus:outline-none"
      )}
    >
      {/* <!-- On: "translate-x-5", Off: "translate-x-0" --> */}
      <span
        aria-hidden="true"
        onClick={() => {
          setOn(!on);
          onChange(!on);
        }}
        className={classNames(
          !on ? "translate-x-0" : "translate-x-5",
          "inline-block",
          "h-5",
          "w-5",
          "rounded-full",
          "bg-white",
          "shadow",
          "transform",
          "transition",
          "ease-in-out duration-200"
        )}
      >
        {/* <!-- On: "opacity-0 ease-out duration-100", Off: "opacity-100 ease-in duration-200" --> */}
        {iconEnable && (
          <>
            <span
              className={classNames(
                !on ? "opacity-100" : "opacity-0",
                !on ? "ease-in" : "ease-out",
                !on ? "duration-200" : "duration-100",
                "absolute",
                "inset-0",
                "h-full",
                "w-full",
                "flex",
                "items-center",
                "justify-center",
                "transition-opacity"
              )}
            ></span>
            {/* <!-- On: "opacity-100 ease-in duration-200", Off: "opacity-0 ease-out duration-100" --> */}
            <span
              className={classNames(
                !on ? "opacity-0" : "opacity-100",
                !on ? "ease-out" : "ease-in",
                !on ? "duration-100" : "duration-200",
                "absolute",
                "inset-0",
                "h-full",
                "w-full",
                "flex",
                "items-center",
                "justify-center",
                "transition-opacity"
              )}
            ></span>
          </>
        )}
      </span>
    </span>
  );
};

Toggles.defaultProps = {
  initialState: false,
};

export default React.memo(Toggles);
