import { FormikProps, FormikValues, Formik } from "formik";
import React, { FC, useEffect, useState } from "react";
import * as yup from "yup";
import BrandTextAreaInput from "../../../shared/Input/BrandTextAreaInput";
import Input from "../../../shared/Input/Input";
import FilePicker from "../../../shared/FilePicker/FilePicker";
import { AppContext } from "../../../App.context";
import { BrandButton } from "../../../shared/Button/BrandButton";
import { FileService } from "../../../store/services/api-services/FileService";
import { observer } from "mobx-react";
import { ERROR_WENT_WRONG } from "../../../constants/message";
import Loader from "react-loader-spinner";
import { UploadStatus } from "../../../constants/enums/UploadStatus";
const BusinessDetails: FC = () => {
  const { store, toast } = AppContext;

  const businessDetails = store.businessStore.currentBusiness.item;

  const [uploadedUrls, setUploadedUrls] = useState<{
    url: string;
    uploading: UploadStatus;
  }>({
    url: "",
    uploading: UploadStatus.NOT_STARTED,
  });

  const [image, setImage] = useState<{ preview: string }>({
    preview: businessDetails?.logo || "",
  });

  useEffect(() => {
    const businessId = store.userStore.loggedInUser?.memberships?.items[0]?.id;
    if (businessId) store.businessStore.getBusinessFromId(businessId);
  }, []);

  const handleUploadProfilePic = async (file: File, formikProps: any) => {
    if (file) {
      try {
        toast.showInfo("Logo Upload Start");
        setUploadedUrls((prev) => ({
          ...prev,
          uploading: UploadStatus.IN_PROGRESS,
        }));
        const res = await FileService.uploadFile(file);
        setUploadedUrls((prev) => ({
          ...prev,

          url: res.url || "",
        }));
        formikProps.setFieldValue("logo", res.url);
        setUploadedUrls((prev) => ({ ...prev, uploading: UploadStatus.DONE }));
        toast.showSuccess("Logo Uploaded Successfully");
      } catch (e: any) {
        toast.showError("Logo Upload Fail");
        setUploadedUrls((prev) => ({
          ...prev,
          uploading: UploadStatus.NOT_STARTED,
        }));
      }
    } else {
      setUploadedUrls((prev) => ({ url: "", uploading: UploadStatus.DONE }));
    }
  };

  const handleImagePreview = (file: File | undefined, formikProps: any) => {
    setImage({
      preview: file ? URL.createObjectURL(file) : "",
    });

    if (file) {
      handleUploadProfilePic(file, formikProps);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        name: businessDetails?.name || "",
        email: businessDetails?.email || "",
        description: businessDetails?.description || "",
        send_emails_from: businessDetails?.send_emails_from || "",
        logo: businessDetails?.logo || "",
      }}
      validationSchema={yup.object().shape({
        email: yup
          .string()
          .required("Email is Required")
          .trim()
          .email("Please Enter a Valid Email"),
        description: yup.string(),
        name: yup.string().required("Business Name is Required").trim(),
        send_emails_from: yup
          .string()
          .required("Email is Required")
          .email("Please Enter a Valid Email")
          .trim(),
        logo: yup.string(),
      })}
      onSubmit={async (data, formikHelpers) => {
        formikHelpers.setSubmitting(true);
        try {
          const mappedValues = { id: businessDetails.id, ...data };

          await store.businessStore.updateBusiness(mappedValues);
          formikHelpers.setSubmitting(false);
          toast.showSuccess("Business Updated Successfully");
        } catch (e: any) {
          formikHelpers.setSubmitting(false);
          toast.showError(e.message || ERROR_WENT_WRONG);
        }
      }}
    >
      {(formikProps: FormikProps<FormikValues>) => (
        <form onSubmit={formikProps.handleSubmit}>
          <div className="bg-white px-8 pb-8">
            <div className="container mx-auto bg-white  rounded">
              <div className=" items-start  flex flex-col">
                <h1 className="text-5xl tracking-tight font-bold text-gray-800 sm:text-5xl md:text-5xl py-10">
                  <span className="block xl:inline">Manage your </span>
                  <span className="block text-indigo-600 xl:inline">
                    Business Details
                  </span>
                </h1>
                <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                  Update your Business Details
                </p>
              </div>
              <div className="mx-10">
                <div className="w-9/12 flex flex-col space-y-8 mt-12">
                  <div>
                    <Input
                      name={"name"}
                      label="Business Name"
                      helperText="Please enter the name of your business"
                      onChange={formikProps.handleChange}
                    />
                  </div>
                  <div className={""}>
                    <BrandTextAreaInput
                      name={"description"}
                      label="Business Description"
                      helperText="Please provide a description about your business"
                      labelClassName={"text-sm  text-gray-800 mb-2"}
                      onChange={formikProps.handleChange}
                      row={4}
                    />
                  </div>
                  <div>
                    <Input
                      name={"email"}
                      type="email"
                      label="Email Address*"
                      helperText="Please enter your business email address"
                      onChange={formikProps.handleChange}
                    />
                  </div>
                  <div>
                    <Input
                      name={"send_emails_from"}
                      type=""
                      label="Send Emails From*"
                      helperText="Emails to your members from SafeTravels will be sent from this email address"
                      onChange={formikProps.handleChange}
                    />
                  </div>
                  <FilePicker
                    handleFileUpdate={(file) =>
                      handleImagePreview(file, formikProps)
                    }
                    btnText="Upload Logo"
                    fileTypes={"image/*"}
                    btnType="secondary"
                    uploading={
                      uploadedUrls.uploading === UploadStatus.IN_PROGRESS
                    }
                    disabled={formikProps.isSubmitting}
                    src={image.preview || businessDetails?.logo || ""}
                    name={"logo"}
                  />

                  <div className={"mt-10 flex items-center justify-start"}>
                    <BrandButton
                      className=" text-base font-semibold "
                      disabled={formikProps.isSubmitting}
                      theme="primary"
                      size={"xl"}
                    >
                      Update Details
                    </BrandButton>
                    {formikProps.isSubmitting && (
                      <span className="ml-3">
                        <Loader type="BallTriangle" height={30} width={30} />
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};
export default observer(BusinessDetails);
