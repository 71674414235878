import { AppContext } from "App.context";
import { observer } from "mobx-react";
import React from "react";
import { Redirect, Route, RouteProps } from "react-router";
import { LoginStatus } from "store";
import Loader from "react-loader-spinner";

const AuthRoute: React.FC<RouteProps> = (routeProps) => {
  const {
    store: {
      userStore: { loginStatus },
    },
  } = AppContext;

  switch (loginStatus) {
    case LoginStatus.LOGGED_IN:
      return <Route {...routeProps} />;

    case LoginStatus.LOGGED_OUT:
      return (
        <Route
          render={(props) => (
            <Redirect
              to={{
                pathname: "/home",
                state: { from: props.location },
              }}
            />
          )}
        />
      );

    case LoginStatus.PENDING:
      return (
        <div className="h-screen w-screen flex items-center justify-center">
          <Loader type="BallTriangle" height={30} width={30} />
        </div>
      );
    default:
      return (
        <Route
          render={(props) => (
            <Redirect
              to={{
                pathname: "/home",
                state: { from: props.location },
              }}
            />
          )}
        />
      );
  }
};

export default observer(AuthRoute);
