import React from "react";
import { AppContext } from "../../../../App.context";

import "@fullcalendar/react/dist/vdom";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import momentPlugin from "@fullcalendar/moment";
import interactionPlugin from "@fullcalendar/interaction";
import { EventClickArg } from "@fullcalendar/common";
import GenericHeader from "shared/Headers/GenericHeader";
import timeGridPlugin from "@fullcalendar/timegrid";
import moment from "moment";
import Loader from "react-loader-spinner";
import { Utils } from "../../../../Utils/Utils";

const { store } = AppContext;

interface Props {
  handleDateClick: (arg: any) => void;
  events: Array<any>;
  startDate?: string;
  endDate?: string;
  onEventClick: (arg: EventClickArg) => void;
  loading?: boolean;
}

const TripCalender: React.FC<Props> = ({
  handleDateClick,
  events,
  startDate,
  endDate,
  onEventClick,
  loading,
}) => {
  const initialDate = moment(startDate ?? new Date()).format("YYYY-MM-DD");
  const dateRange =
    startDate && endDate
      ? Utils.getDateRange(startDate, endDate).map((value) =>
          moment(value).format("YYYY-MM-DD")
        )
      : [];
  return (
    <div className="bg-white px-8 pb-8 py-5">
      <GenericHeader
        heading={"Trip Itinerary"}
        subHeading={
          "Add Itinerary activities for each event in your Trip. i.e Flight, Hotel, Transfer, Day Tour, Sports event"
        }
      />
      <hr className="h-0.5 mt-2 bg-gray-50 mx-auto w-full" />
      {loading ? (
        <div className={"flex justify-center mx-auto my-20"}>
          <Loader type="BallTriangle" height={30} width={30} />
        </div>
      ) : (
        <FullCalendar
          initialView="dayGridMonth"
          dayCellClassNames={(d) =>
            dateRange.includes(moment(d.date).format("YYYY-MM-DD"))
              ? "bg-blue-100 cursor-pointer"
              : "cursor-pointer"
          }
          events={events}
          rerenderDelay={20}
          select={handleDateClick}
          selectable={true}
          timeZone="UTC"
          plugins={[
            dayGridPlugin,
            interactionPlugin,
            timeGridPlugin,
            momentPlugin,
          ]}
          eventTimeFormat={"h:mma"}
          eventClick={onEventClick}
          headerToolbar={{
            left: "prev,next today",
            center: "title",
            right: "dayGridMonth,timeGridWeek,timeGridDay",
          }}
          initialDate={initialDate}
        />
      )}
    </div>
  );
};

export default TripCalender;
