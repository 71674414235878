import React, { Dispatch, SetStateAction } from "react";
import { IconType } from "react-icons";
import { useLocation } from "react-router-dom";
import Icon from "../../../shared/Icon/Icon";

interface Props {
  handleChange: Dispatch<SetStateAction<string>> | any;
  currentSelectedStep: string;
  sideBarItems: TripSideBarItem[];
  heading?: string;
  subHeading?: string;
}

const TripSideBar: React.FC<Props> = ({
  handleChange,
  currentSelectedStep,
  sideBarItems,
  heading,
  subHeading,
}) => {
  return (
    <div
      className={
        "flex flex-col justify-start flex-shrink-0 py-8 px-4 space-y-0 cursor-pointer bg-white h-screen"
      }
    >
      {heading && (
        <span className={"text-gray-800  font-bold"} style={{ maxWidth: 180 }}>
          {heading}
        </span>
      )}
      {subHeading && (
        <span className={"text-gray-400 text-sm"}>{subHeading}</span>
      )}
      {sideBarItems.map((item: TripSideBarItem, index) => (
        <div
          key={item.value + index}
          className={`p-3 
           flex items-center space-x-2 rounded-md text-gray-500`}
          onClick={() => handleChange(item.value)}
        >
          <Icon
            value={item.icon}
            className={
              currentSelectedStep === item.value ? "text-indigo-600" : ""
            }
          />
          <span className={"text-sm"}> {item.label}</span>
        </div>
      ))}
    </div>
  );
};

export default React.memo(TripSideBar);

export interface TripSideBarItem {
  label: string;
  value: string;
  icon: IconType;
}
