import { FaImage, FaStackExchange, FaUserAlt } from "react-icons/fa";
import BusinessDetails from "../container/Dashboard/BusinessDetails/BusinessDetails";
import AddStaffMembers from "../container/Dashboard/AddStaffMembers/AddStaffMembers";
import AddBusinessContents from "../container/Dashboard/AddBusinessContent/AddBusinessContents";
import { RouteConfig } from "./RouteConfig";
import ImageGallery from "../container/Dashboard/ImageGallery/ImageGallery";
import TripDashboard from "../container/Dashboard/Trips/TripDashboard";
import Profile from "../container/Dashboard/Profile/Profile";

export const DashboardRoutes = [
  {
    heading: "TRIP MANAGEMENT",
    items: [
      {
        name: "Trips",
        path: RouteConfig.tripsDashboard._ROOT,
        iconClassName: FaStackExchange,
        component: TripDashboard,
      },
    ],
  },
  {
    heading: "BUSINESS ACCOUNT",
    items: [
      {
        name: "Business Details",
        path: RouteConfig.business.details,
        iconClassName: FaStackExchange,
        component: BusinessDetails,
      },
      {
        name: "Business Content",
        path: RouteConfig.business.content,
        iconClassName: FaUserAlt,
        component: AddBusinessContents,
      },
      {
        name: "Staff",
        path: RouteConfig.staff._ROOT,
        iconClassName: FaUserAlt,
        component: AddStaffMembers,
      },
      {
        name: "Image Gallery",
        path: `${RouteConfig.imageGallery._ROOT}`,
        iconClassName: FaImage,
        component: ImageGallery,
      },
    ],
  },

  {
    heading: "PROFILE",
    items: [
      {
        name: "Profile",
        path: `${RouteConfig.profile._ROOT}`,
        component: Profile,
        iconClassName: FaImage,
      },
    ],
  },
];
