import React, { FunctionComponent } from "react";
import Loader from "react-loader-spinner";
import { useHistory } from "react-router";
import * as yup from "yup";
import { Formik, FormikProps, FormikValues } from "formik";
import { AppContext } from "../../../App.context";
import { RouteConfig } from "../../../constants/RouteConfig";
import { ERROR_WENT_WRONG } from "../../../constants/message";
import Input from "../../../shared/Input/Input";
import { BrandButton } from "../../../shared/Button/BrandButton";
import logo from "../../../assets/safetravels-logo.png";
import hawaii from "../../../assets/hawaii.png";
const ResetPassword: FunctionComponent = () => {
  const history = useHistory();

  const { store, toast } = AppContext;
  const token = new URLSearchParams(location.search).get("resetToken");

  return (
    <div className="relative bg-white overflow-hidden">
      <div className="max-w-7xl mx-auto">
        <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
          <svg
            className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
            fill="currentColor"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            aria-hidden="true"
          >
            <polygon points="50,0 100,0 50,100 0,100" />
          </svg>

          <main className="pt-10 mx-auto max-w-7xl px-4 sm:pt-12 sm:px-6 md:pt-16 lg:pt-20 lg:px-8 xl:pt-28 h-screen">
            <a href="/home">
              <img className=" w-80 sm:h- mb-10" src={logo} />
            </a>

            <div className="sm:text-center lg:text-left">
              <h1 className="text-4xl tracking-tight font-bold text-gray-800 sm:text-5xl md:text-6xl">
                <span className="block xl:inline">Reset your</span>{" "}
                <span className="block text-indigo-600 xl:inline">
                  Password
                </span>
              </h1>
              <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                Enter your new password. Once submitted, head back to the app
                and sign-in again. <br />
              </p>
              <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                <div className="mt-8 w-3/4">
                  <Formik
                    initialValues={{
                      password: "",
                      token: token || "",
                      confirmPassword: "",
                    }}
                    validationSchema={yup.object().shape({
                      password: yup.string().required().min(6),
                      confirmPassword: yup
                        .string()
                        .oneOf(
                          [yup.ref("password"), null],
                          "Passwords must match"
                        ),
                    })}
                    onSubmit={async (data, formikHelpers) => {
                      formikHelpers.setSubmitting(true);
                      try {
                        await store.userStore.resetPassword({ ...data });
                        toast.showSuccess("Password Reset Successfully");
                        // history.push(RouteConfig.login._ROOT);
                        formikHelpers.setSubmitting(false);
                      } catch (e: any) {
                        formikHelpers.setSubmitting(false);
                        toast.showError(e.message || ERROR_WENT_WRONG);
                      }
                    }}
                  >
                    {(formikProps: FormikProps<FormikValues>) => (
                      <form onSubmit={formikProps.handleSubmit}>
                        <div className={"flex flex-col space-y-4"}>
                          <div>
                            <Input
                              label="New Password"
                              placeholder="Password"
                              type="password"
                              name={"password"}
                              theme="secondary"
                              onChange={formikProps.handleChange}
                            />
                          </div>
                          <div>
                            <Input
                              label="Confirm New Password"
                              placeholder="Password"
                              type="password"
                              name={"confirmPassword"}
                              theme="secondary"
                              onChange={formikProps.handleChange}
                            />
                          </div>
                        </div>

                        <div className="flex space-x-3 w-full items-center mt-5">
                          <BrandButton
                            className=" text-base font-semibold"
                            disabled={formikProps.isSubmitting}
                            theme="primary"
                            size={"xl"}
                          >
                            Reset Password
                          </BrandButton>

                          {formikProps.isSubmitting && (
                            <span className="ml-3">
                              <Loader
                                type="BallTriangle"
                                height={30}
                                width={30}
                              />
                            </span>
                          )}
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>

                <div className="mt-3 sm:mt-0 sm:ml-3"></div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 hidden md:block">
        <img
          className="h-56 w-full sm:h-72 md:h-96 lg:w-full lg:h-full object-cover"
          src={hawaii}
          alt=""
        />
      </div>
    </div>
  );
};

export default ResetPassword;
