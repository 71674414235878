import React, { FC, useEffect, useState } from "react";
import GenericHeader from "../../../../shared/Headers/GenericHeader";
import { UserModel } from "store/models/UserModel";
import { AppContext } from "App.context";
import { EntityIdentifier } from "@devslane/mobx-entity-manager";
import Table from "shared/Table/Table";
import { observer } from "mobx-react";
import { InputWithoutFormik } from "../../../../shared/Input/Input";
import Loader from "react-loader-spinner";
import { debounce } from "lodash";
import { FormikProps, FormikValues } from "formik";
import CustomCheckbox from "../../../../shared/CustomCheckBox/CustomCheckbox";
import { ActivityModal } from "store/models/ActivityModal";

interface Props {
  formikProps: FormikProps<FormikValues>;
  eventForEdit: ActivityModal;
}

const TripActivityGuests: FC<Props> = ({ formikProps, eventForEdit }) => {
  const { store, toast } = AppContext;
  console.log("eventForEdit", eventForEdit)
  const businessId = store.userStore.loggedInUser?.memberships?.items?.[0].id;
  const [selectedGuests, setSelectedGuests] = useState<Guest[]>([]);

  const currentTripId = new URLSearchParams(location.search).get("id");

  const guestList = store.businessStore.guestList.items;

  const [filterGuests, setFilterGuests] = useState<UserModel[]>(guestList);

  const onClickButtonHandler = (value: Guest) => {
    const index = selectedGuests.findIndex(guest => JSON.stringify(guest) === JSON.stringify(value));
    if (index > -1) {
      // value already exists, remove it
      const newValues = [...selectedGuests];
      newValues.splice(index, 1);
      setSelectedGuests(newValues);
    } else {
      setSelectedGuests([...selectedGuests, value]);
    }
  }

  useEffect(() => {
    const a = formikProps;
    formikProps.setFieldValue("private_guests", selectedGuests)
    // debugger
  }, [selectedGuests])

  const staffTable = {
    headingsArray: [
      "First Name",
      "Last Name",
      "Email",
      "Member Type",
      "Private",
    ],
    selectChildren: <div className=""></div>,
  };

  interface Guest {
    guest_id?: EntityIdentifier,
    email?: string,
    first_name?: string,
    last_name?: string
  }

  const guest: Guest[] = [];

  useEffect(() => {
    if (eventForEdit !== undefined) {
      const newSelectedGuests = eventForEdit.private_guests?.map(guests => ({
        guest_id: guests.guest_id,
        email: guests.email,
        first_name: guests.first_name,
        last_name: guests.last_name,
      })) || [];
  
      setSelectedGuests(newSelectedGuests);
    }
  }, [eventForEdit]);

  const ifSearchIncludes = (data: UserModel, searchKey: string) => {
    const searchCriteriaArray: Array<any> = [
      data.firstName?.trim()?.toLowerCase(),
      data.lastName?.trim()?.toLowerCase(),
      data.email?.trim()?.toLowerCase(),
      data.role?.trim()?.toLowerCase(),
    ];
    if (
      searchCriteriaArray?.some((val) =>
        val?.includes(searchKey?.trim()?.toLowerCase())
      )
    )
      return true;

    return false;
  };

  const handleSearch = debounce((searchKey: string): void => {
    const filtered = guestList.filter((data) => {
      return ifSearchIncludes(data, searchKey);
    });

    setFilterGuests(filtered);
  }, 200);

  useEffect(() => {
    setFilterGuests(guestList);
  }, [guestList]);

  useEffect(() => {
    store.businessStore.loadGuests(currentTripId as any, businessId!);
  }, [businessId]);

  let value = false;
  const dataArray: any = (filterGuests || [])?.map((staff, key) => {
      guest.push({
        guest_id: staff.account_id,
        email: staff.email,
        first_name: staff.firstName,
        last_name: staff.lastName,
      })

      if(staff.email != undefined)
      if(selectedGuests.findIndex(selectedGuest => JSON.stringify(selectedGuest) === JSON.stringify(guest[key])) > -1) value = true;
      else value = false;

      return [
        staff.firstName,
        staff.lastName,
        staff.email,
        staff.role,
        <div className="flex items-center -mt-[13px]">
          <CustomCheckbox
            key = {key}
            name={"checkbox"}
            value={value}
            onChange={(e: any) => {
              onClickButtonHandler(guest[key])
            }}
          />
        </div>,
      ];
    });

  return (
    <>
      <div className="bg-white">
        <div className="pb-8">
          <GenericHeader
            heading={"Activity Guests"}
            subHeading={" Add Private Guests"}
            buttonText={"Add Contact"}
          />
          <hr className="h-0.5 mt-2 bg-gray-50 mx-auto w-full" />
          <div className={"px-8 mt-4"}>
            <InputWithoutFormik
              type="text"
              placeholder="Search Keyword"
              onChange={(e) => handleSearch(e.target.value || "")}
            />
            <div className="mt-6 w-full px-8">
              {store.businessStore.guestList.loading ? (
                <div className={"flex justify-center mx-auto"}>
                  <Loader type="BallTriangle" height={30} width={30} />
                </div>
              ) : (
                <Table headers={staffTable.headingsArray} data={dataArray} />
              )}
            </div>
          </div>
          <hr className="h-0.5 mt-10 bg-gray-50 mx-auto w-full" />
        </div>
      </div>
    </>
  );
};
export default observer(TripActivityGuests);
