import React, { FC } from "react";

const Pricing: FC = () => {
  return (
    <>
      <div className="flex flex-col justify-center w-2/3 px-20 bg-white rounded-lg dark:bg-gray-800 sm:px-6 md:px-20 lg:px-40">
        Pricing
      </div>
    </>
  );
};
export default Pricing;
