import { action, observable } from "mobx";
import { ChildStore } from "../ChildStore";
import { Context } from "../Context";
import { RootStore } from "../RootStore";
import { ModelList } from "@devslane/mobx-entity-manager";
import { ArticleModel } from "store/models/ArticleModal";

export class ArticleStore extends ChildStore<ArticleModel> {
  constructor(rootStore: RootStore) {
    super(rootStore);
    ArticleModel._store = this;
  }

  @observable articleList: ModelList<ArticleModel> =
    new ModelList<ArticleModel>(ArticleModel);

  // @action
  // async addSection(data: {
  //   business_id: EntityIdentifier;
  //   name: string;
  //   order?: number;
  // }): Promise<any> {
  //   try {
  //     return await businessService.createSection(data).then((res) => {
  //       //appending new Employee
  //       // const staffData: any = UserModel.fromJson(res.data);
  //       // this.staffList.appendItem(staffData);
  //       // return res.data;
  //     });
  //   } catch (e) {
  //     throw e;
  //   }
  // }

  // @action
  // async loadSection(business_id: EntityIdentifier): Promise<any> {
  //   this.sectionList.setLoading(true);
  //   try {
  //     return await businessService.getSection(business_id).then((res) => {
  //       console.log(res);
  //       console.log("res");
  //       this.sectionList.deserialize(
  //         map(res.data, (staff) => staff.toJSON()) as ModelJson[]
  //       );
  //       this.sectionList.setLoading(false);
  //       return res;
  //     });
  //   } catch (e) {
  //     this.sectionList.setLoading(false);
  //     throw e;
  //   }
  // }

  reset() {
    this.entities.clear();
    Context.storage.reset();
  }
}
