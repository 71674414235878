export const RouteConfig = {
  tripsDashboard: {
    _ROOT: "/trips",
    TAB: (tab: string): string => `/trips/${tab}`,
  },

  profile: {
    _ROOT: "/profile",
  },

  imageGallery: {
    _ROOT: "/image-gallery",
  },

  signUp: {
    _ROOT: "/register",
    staffSignup: "/staff-signup",
  },

  business: {
    details: "/business-details",
    content: "/business-content",
  },

  staff: {
    _ROOT: "/staff",
  },
  login: {
    _ROOT: "/login",
  },

  resetPassword: {
    _ROOT: "/reset-password",
    forgotPassword: "/forgot-password",
  },

  termsAndConditions: {
    _ROOT: "/terms-and-conditions",
  },
  privacyPolicy: {
    _ROOT: "/privacy-policy",
  },

  home: {
    _ROOT: "/home",
  },

  pricing: {
    _ROOT: "/pricing",
  },
  whiteLabel: {
    _ROOT: "/white-label",
  },
  affiliates: {
    _ROOT: "/affiliates",
  },

  verify: {
    _ROOT: "/verify",
  },
};
