import React, { FC, useEffect, useState } from "react";
import Modal from "../../../shared/Modal/Modal";
import { FaCreativeCommonsShare, FaEdit, FaTrashAlt } from "react-icons/fa";
import AddTrip from "./trip";
import TripClone from "./TripClone";
import Table from "../../../shared/Table/Table";
import GenericHeader from "../../../shared/Headers/GenericHeader";
import { AppContext } from "App.context";
import { observer } from "mobx-react";
import IconButton from "shared/IconButton/IconButton";
import { TripModel } from "store/models/TripModel";
import { ERROR_WENT_WRONG } from "constants/message";
import { EntityIdentifier } from "@devslane/mobx-entity-manager";
import moment from "moment";
import { useHistory } from "react-router";
import { RouteConfig } from "../../../constants/RouteConfig";
import Loader from "react-loader-spinner";

const TripsListing: FC = () => {
  const { store, toast } = AppContext;
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openCloneModal, setOpenCloneModal] = useState<boolean>(false);
  const [selectTab, setSelectTab] = useState<string>("upcomingTrips");
  const [selectedTemplate, setSelectedTemplate] = useState<any>({});
  const history = useHistory();
  const staffTable = {
    headingsArray: [
      "Name",
      "Location",
      "Start Date",
      "Tour code",
      // "Status",
      "Actions",
    ],
  };

  const tripList =
    selectTab !== "tripTemplates"
      ? store.tripStore.tripList.items
      : store.tripStore.templateTripList.items;

  const isLoading =
    store.tripStore.tripList.loading ||
    store.tripStore.templateTripList.loading;

  const [filterTrips, setFilterTrips] = useState<TripModel[]>(tripList);

  const businessId = store.userStore.loggedInUser?.memberships?.items?.[0]?.id;

  useEffect(() => {
    store.tripStore.loadTrip(businessId!);
    store.tripStore.loadTripTemplate(businessId!);
  }, [businessId]);

  const handleDelete = async (id: EntityIdentifier) => {
    try {
      if (confirm("Are you sure you want to delete this trip ?")) {
        await store.tripStore.deleteTrip({
          trip_id: id,
          business_id: businessId!,
        });
        toast.showSuccess("Trip Deleted Successfully");
      }
    } catch (e: any) {
      toast.showError(e.message || ERROR_WENT_WRONG);
    }
  };
  const makeTemplate = async (trip: TripModel) => {
    try {
      if (confirm("Are you sure you want to make this trip a template ?")) {
        await store.tripStore.makeTemplate(businessId!, trip);
        toast.showSuccess("Trip successfully added as a template");
      }
    } catch (e: any) {
      toast.showError(e.message || ERROR_WENT_WRONG);
    }
  };

  const dataArray: any = (filterTrips || [])?.map((trip) => {
    return [
      trip.name,
      trip.location,
      moment(trip.start_date).format("DD-MMM-YYYY"),

      <div className="bg-indigo-500 h-8 w-24 mb-4 md:mb-0 rounded-md flex items-center justify-center">
        <div className="flex items-center">
          <span className="text-md text-white font-normal">
            {trip.trip_code}
          </span>
        </div>
      </div>,
      <div className={"flex space-x-3 items-center"}>
        {selectTab !== "tripTemplates" && (
          <>
            <IconButton
              child={FaEdit}
              className="w-4 h-4"
              onClick={() => {
                history.push({
                  pathname: RouteConfig.tripsDashboard.TAB("overview"),
                  search: `?id=${trip.id}`,
                });
              }}
              buttonClassName={"text-white rounded-full p-2 bg-indigo-500"}
            />
            <IconButton
              child={FaCreativeCommonsShare}
              onClick={() => makeTemplate(trip)}
              className="w-4 h-4"
              buttonClassName={" text-white rounded-full p-2 bg-accent-600"}
            />
          </>
        )}
        {selectTab === "tripTemplates" && (
          <IconButton
            child={FaEdit}
            className="w-4 h-4"
            onClick={() => {
              setOpenCloneModal(true);
              setSelectedTemplate(trip);
            }}
            buttonClassName={"text-white rounded-full p-2 bg-indigo-500"}
          />
        )}
        <IconButton
          child={FaTrashAlt}
          onClick={() => handleDelete(trip.id)}
          className="w-4 h-4"
          buttonClassName={" text-white rounded-full p-2 bg-error-500"}
        />
      </div>,
    ];
  });

  useEffect(() => {
    if (selectTab === "upcomingTrips")
      setFilterTrips(
        tripList?.filter(
          (trip) => moment(trip.end_date).unix() >= moment().unix()
        )
      );
    else if (selectTab === "completedTrips") {
      setFilterTrips(
        tripList?.filter(
          (trip) => moment(trip.end_date).unix() < moment().unix()
        )
      );
    } else {
      setFilterTrips(tripList);
    }
  }, [tripList, selectTab]);

  return (
    <>
      <Modal
        modalStyle={{ width: "55rem", maxHeight: "95%", overflowY: "auto" }}
        open={openModal}
        onOutsideClick={() => setOpenModal(false)}
      >
        <AddTrip modalHandler={() => setOpenModal(false)} />
      </Modal>

      <Modal
        modalStyle={{ width: "55rem", maxHeight: "95%", overflowY: "auto" }}
        open={openCloneModal}
        onOutsideClick={() => setOpenCloneModal(false)}
      >
        <TripClone
          modalHandler={() => setOpenCloneModal(false)}
          editData={selectedTemplate}
        />
      </Modal>
      {/* <h1 className="text-5xl tracking-tight font-bold text-gray-800 sm:text-5xl md:text-5xl">
        <span className="block xl:inline">Manage your</span>{" "}
        <span className="block text-indigo-600 xl:inline">Trips and Tours</span>
      </h1>
      <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
        Create or select an existing tour to manage tour information.
      </p> */}
      <div className="flex gap-x-2 mt-4 border-b-2">
        <div
          className={
            "px-5 py-2 text-sm leading-5 font-medium inline-flex cursor-pointer justify-center items-center " +
            (selectTab == "upcomingTrips"
              ? "border-b-4 border-brandPrimaryBlue rounded-b-sm"
              : "")
          }
          onClick={(e) => {
            setSelectTab("upcomingTrips");
          }}
        >
          Upcoming Trips
        </div>
        <div
          className={
            "px-5 py-2 text-sm leading-5 font-medium inline-flex cursor-pointer justify-center items-center" +
            (selectTab == "completedTrips"
              ? " border-b-4 border-brandPrimaryBlue rounded-b-sm"
              : "")
          }
          onClick={(e) => {
            setSelectTab("completedTrips");
          }}
        >
          Completed Trips
        </div>
        <div
          className={
            "px-5 py-2 text-sm leading-5 font-medium inline-flex cursor-pointer justify-center items-center" +
            (selectTab == "tripTemplates"
              ? " border-b-4 border-brandPrimaryBlue rounded-b-sm"
              : "")
          }
          onClick={(e) => {
            setSelectTab("tripTemplates");
          }}
        >
          Trip Templates
        </div>
      </div>
      <div>
        <div className="bg-white">
          <div className="pb-8">
            <GenericHeader
              heading={"Tours & Trips"}
              subHeading={"Trips"}
              buttonText={" Add New Trip"}
              buttonOnClick={() => setOpenModal(true)}
            />
            <hr className="h-0.5 mt-2 bg-gray-50 mx-auto w-full" />

            <div className="mt-6 w-full px-8">
              {isLoading ? (
                <div className={"flex justify-center mx-auto"}>
                  <Loader type="BallTriangle" height={30} width={30} />
                </div>
              ) : (
                <Table headers={staffTable.headingsArray} data={dataArray} />
              )}
            </div>
            <hr className="h-0.5 mt-10 bg-gray-50 mx-auto w-full" />
          </div>
        </div>
      </div>
    </>
  );
};
export default observer(TripsListing);
