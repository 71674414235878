import {
  GAutoCompleteService,
  IGPlaceService,
} from "../../types/GoogleCloudAPI";
import envVars from "../../Env.config";

declare const google: any;

class GoogleAPIServices {
  private apiKey = envVars.GOOGLE_CLOUD_API_KEY;
  scriptSRC = `https://maps.googleapis.com/maps/api/js?key=${this.apiKey}&libraries=places`;

  autoCompleteService: GAutoCompleteService | null = null;

  placeService: IGPlaceService | null = null;

  static getInstance(): GoogleAPIServices {
    return new GoogleAPIServices();
  }

  public initializeAutoCompleteService() {
    if (!this.autoCompleteService && google?.maps?.places) {
      this.autoCompleteService = new google.maps.places.AutocompleteService();
      const myDiv = document.createElement("div");
      this.placeService = new google.maps.places.PlacesService(myDiv);
    }
  }
}

const googleAPIServices = GoogleAPIServices.getInstance();

export default googleAPIServices;
