import { AppContext } from "App.context";
import { ERROR_WENT_WRONG } from "constants/message";
import { Formik, FormikProps, FormikValues, useFormik } from "formik";
import React, { FC, useState } from "react";
import { SectionModel } from "store/models/SectionModal";
import * as yup from "yup";
import { BrandButton } from "../../../shared/Button/BrandButton";
import Input, { InputWithoutFormik } from "../../../shared/Input/Input";
import Loader from "react-loader-spinner";
interface Props {
  editData?: SectionModel;
  modalHandler: () => void;
}

const AddBusinessContentArticleName: FC<Props> = ({
  modalHandler,
  editData,
}) => {
  const { store, toast } = AppContext;
  const businessDetails = store.userStore.loggedInUser?.memberships?.items?.[0];
  const isUpdate = !!editData;

  return (
    <Formik
      initialValues={{
        name: editData?.name || "",
      }}
      validationSchema={yup.object().shape({
        name: yup.string().trim().required("Article name is required"),
      })}
      onSubmit={async (data, formikHelpers) => {
        formikHelpers.setSubmitting(true);

        const mappedValues = {
          ...data,
          business_id: businessDetails!.business_id!,
        };

        try {
          if (isUpdate) {
            await store.sectionStore.updateSection({
              section_id: editData?.id,
              ...mappedValues,
            });
          } else {
            await store.sectionStore.addSection(mappedValues);
          }
          formikHelpers.setSubmitting(false);
          modalHandler();
        } catch (e: any) {
          formikHelpers.setSubmitting(false);
          toast.showError(e.message || ERROR_WENT_WRONG);
        }

        formikHelpers.setSubmitting(false);
      }}
    >
      {(formikProps: FormikProps<FormikValues>) => (
        <form onSubmit={formikProps.handleSubmit}>
          <div className="bg-white dark:bg-gray-800">
            <div className="mx-auto bg-white dark:bg-gray-800 rounded ">
              <div className="border-b border-gray-300 dark:border-gray-700 py-5 bg-white ">
                <div className="w-11/12 mx-auto items-center pb-4">
                  <div className=" items-start  flex flex-col">
                    <h1 className="text-5xl tracking-tight font-bold text-gray-800 sm:text-5xl md:text-5xl py-10">
                      <span className="block xl:inline">Add / Edit </span>
                      <span className="block text-indigo-600 xl:inline">
                        Section Name{" "}
                      </span>
                    </h1>
                    {/* <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                      Add content sections for {store.sectionStore.sectionList.items[openArticleId!].name}.  Drag and drop the icon on the left to re-order the content sections and click the 'Re-order' button to Save the changes.
                    </p> */}
                  </div>
                  <div className="mb-8">
                    <Input
                      name="name"
                      label="Section Name"
                      className="appearance-none"
                      type="text"
                      placeholder="Section name"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="h-0.5 mt-6 bg-gray-400 mx-auto" />

          <div className="w-11/12 mx-auto border-gray-300 flex justify-end dark:border-gray-700 py-5 bg-white ">
            {formikProps.isSubmitting && (
              <span className="mr-3">
                <Loader type="BallTriangle" height={30} width={30} />
              </span>
            )}
            <div className="w-52 items-center justify-between flex pb-4">
              <BrandButton
                className=" text-base font-semibold bg-gray-200 hover:bg-gray-400"
                disabled={formikProps.isSubmitting}
                type="button"
                theme="basic"
                onClick={modalHandler}
                size={"xl"}
              >
                Cancel
              </BrandButton>
              <BrandButton
                className=" text-base font-semibold"
                disabled={formikProps.isSubmitting}
                type="submit"
                theme="primary"
                size={"xl"}
              >
                Save
              </BrandButton>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};
export default AddBusinessContentArticleName;
