import React, { FC, useEffect, useState } from "react";
import Modal from "../../../../shared/Modal/Modal";
import AddTripGuest from "./AddTripGuest";
import GenericHeader from "../../../../shared/Headers/GenericHeader";
import { UserModel } from "store/models/UserModel";
import { AppContext } from "App.context";
import IconButton from "shared/IconButton/IconButton";
import { EntityIdentifier } from "@devslane/mobx-entity-manager";
import { ERROR_WENT_WRONG } from "constants/message";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import Table from "shared/Table/Table";
import { observer } from "mobx-react";
import { InputWithoutFormik } from "../../../../shared/Input/Input";
import Loader from "react-loader-spinner";
import { debounce } from "lodash";

const TripGuests: FC = () => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [editData, setEditData] = useState<UserModel | undefined>();
  const { store, toast } = AppContext;
  const businessId = store.userStore.loggedInUser?.memberships?.items?.[0].id;
  const currentTripId = new URLSearchParams(location.search).get("id");

  const guestList = store.businessStore.guestList.items;

  const [filterGuests, setFilterGuests] = useState<UserModel[]>(guestList);

  const closeModal = () => {
    setEditData(undefined);
    setOpenModal(false);
  };
  const handleDelete = async (id: EntityIdentifier) => {
    try {
      if (confirm("Are you sure you want to delete this guest ?")) {
        await store.businessStore.deleteGuest(
          currentTripId as any,
          businessId!,
          id
        );
        toast.showSuccess("Guest Deleted Successfully");
      }
    } catch (e: any) {
      toast.showError(e.message || ERROR_WENT_WRONG);
    }
  };
  const staffTable = {
    headingsArray: [
      "First Name",
      "Last Name",
      "Email",
      "Member Type",
      "Actions",
    ],
    selectChildren: <div className=""></div>,
  };
  const dataArray: any = (filterGuests || [])?.map((staff) => {
    return [
      staff.firstName,
      staff.lastName,
      staff.email,
      staff.role,
      <div className={"flex space-x-3 items-center"}>
        <IconButton
          child={FaEdit}
          className="w-4 h-4"
          onClick={() => setEditData(staff)}
          buttonClassName={"text-white rounded-full p-2 bg-success-500"}
        />

        <div className="">
          <IconButton
            child={FaTrashAlt}
            onClick={() => handleDelete(staff.id)}
            className="w-4 h-4"
            buttonClassName={" text-white rounded-full p-2 bg-error-500"}
          />
        </div>
      </div>,
    ];
  });

  const ifSearchIncludes = (data: UserModel, searchKey: string) => {
    const searchCriteriaArray: Array<any> = [
      data.firstName?.trim()?.toLowerCase(),
      data.lastName?.trim()?.toLowerCase(),
      data.email?.trim()?.toLowerCase(),
      data.role?.trim()?.toLowerCase(),
    ];
    if (
      searchCriteriaArray?.some((val) =>
        val?.includes(searchKey?.trim()?.toLowerCase())
      )
    )
      return true;

    return false;
  };

  const handleSearch = debounce((searchKey: string): void => {
    const filtered = guestList.filter((data) => {
      return ifSearchIncludes(data, searchKey);
    });

    setFilterGuests(filtered);
  }, 200);

  useEffect(() => {
    setFilterGuests(guestList);
  }, [guestList]);

  useEffect(() => {
    store.businessStore.loadGuests(currentTripId as any, businessId!);
  }, [businessId]);
  return (
    <>
      <Modal
        modalStyle={{ width: "45rem", maxHeight: "95%", overflowY: "auto" }}
        open={openModal || !!editData}
        onOutsideClick={closeModal}
      >
        <AddTripGuest modalHandler={closeModal} editData={editData} />
      </Modal>
      <div className="bg-white">
        <div className="pb-8">
          <GenericHeader
            heading={"Trip Guests"}
            subHeading={" Add and Manage Guest on your trip"}
            buttonOnClick={() => setOpenModal(true)}
            buttonText={"Add Contact"}
          />
          <hr className="h-0.5 mt-2 bg-gray-50 mx-auto w-full" />
          <div className={"px-8 mt-4"}>
            <InputWithoutFormik
              type="text"
              placeholder="Search Keyword"
              onChange={(e) => handleSearch(e.target.value || "")}
            />
          </div>
          <div className="mt-6 w-full px-8">
            {store.businessStore.guestList.loading ? (
              <div className={"flex justify-center mx-auto"}>
                <Loader type="BallTriangle" height={30} width={30} />
              </div>
            ) : (
              <Table headers={staffTable.headingsArray} data={dataArray} />
            )}
          </div>
          <hr className="h-0.5 mt-10 bg-gray-50 mx-auto w-full" />
        </div>
      </div>
    </>
  );
};
export default observer(TripGuests);
