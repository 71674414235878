import React, { FC } from "react";

const PrivacyPolicy: FC = () => {
  return (
    <>
      <div className="flex flex-col w-2/3 px-20 bg-white rounded-lg sm:px-6 md:px-20 lg:px-40">
        <p className="lg:text-5xl md:text-4xl text-2xl font-bold leading-10 text-gray-800 mt-10 mb-10 leading-loose">
          Privacy Policy{" "}
        </p>
        <p>
          This app is provided by SafeTravels Pty Ltd{" "}
          <a href="https://safetravels.com"></a>. You therefore agree to
          SafeTravels Privacy Policy below.
        </p>

        <p>Updated: September, 2021</p>
        <p>
          Welcome to SafeTravels (“SafeTravels,” “we,” “us” or “our”).
          SafeTravels provides simple, easy and secure way for you to share your
          emergency contact and medical details with others through our
          platform. Just create a profile, add contacts and decide who you want
          to share your information with.
        </p>
        <p>
          Our Privacy Policy explains how we and some of the companies we work
          with collect, use, share and protect information in relation to our
          mobile services, web site, and any software provided on or in
          connection with SafeTravels services (collectively, the “Service“),
          and your choices about the collection and use of your information.
          <br />
          <br />
          By using our Service you understand and agree that we are providing a
          platform for you to post information, including emergency contact
          information, medical conditions, photos, and other materials (“User
          Content“), to the Service and to share User Content with other users.
          This means that other Users may search for, see, use, or share any of
          your User Content that you make publicly available through the
          Service, consistent with the terms and conditions of this Privacy
          Policy and our Terms of Use (which can be found at
          www.safetravels.com).
          <br />
          <br />
          Our Policy applies to all visitors, users, and others who access the
          Service (“Users“).
          <br />
          <br />
          1. INFORMATION WE COLLECT
        </p>
        <p>We collect the following types of information.</p>
        <p>Information you provide us directly:</p>
        <p>
          Your username, password and e-mail address when you register for a
          SafeTravels account.
          <br />
          <br />
          Profile information that you provide for your user profile (e.g.,
          first and last name, picture, phone number). This information allows
          us to help you or others be “found” on SafeTravels
          <br />
          <br />
          User Content (e.g., contact information, emergency contact
          information, travel insurance details.)
          <br />
          <br />
          Communications between you and SafeTravels. For example, we may send
          you Service-related emails (e.g., account verification,
          changes/updates to features of the Service, technical and security
          notices). Note that you may not opt out of Service-related e-mails.
          <br />
          <br />
          Finding your friends on SafeTravels:
        </p>
        <p>
          If you choose, you can use our “Find friends” feature to locate other
          people with SafeTravels accounts either through (i) your contacts
          list, (ii) third-party social media sites or (iii) through a search of
          names on SafeTravels.
          <br />
          <br />
          If you choose to find your friends through a (ii) third-party social
          media site, then you will be prompted to set up a link to the
          third-party service and you understand that any information that such
          service may provide to us will be governed by this Privacy Policy.
          <br />
          <br />
          If you choose to find your friends (iii) through a search of names or
          usernames on SafeTravels then simply type a name to search and we will
          perform a search on our Service.
          <br />
          <br />
          Note about “Invite Friends” feature: If you choose to invite someone
          to the Service through our “Invite friends” feature, you may select a
          person directly from the contacts list on your device and send a text
          or email from your personal account. You understand and agree that you
          are responsible for any charges that apply to communications sent from
          your device, and because this invitation is coming directly from your
          personal account, SafeTravels does not have access to or control this
          communication.
          <br />
          <br />
          Analytics information:
        </p>
        <p>
          We use third-party analytics tools to help us measure traffic and
          usage trends for the Service. These tools collect information sent by
          your device or our Service, including the web pages you visit,
          add-ons, and other information that assists us in improving the
          Service. We collect and use this analytics information with analytics
          information from other Users so that it cannot reasonably be used to
          identify any particular individual User.
          <br />
          <br />
          Cookies and similar technologies:
        </p>
        <p>
          When you visit the Service, we may use cookies and similar
          technologies like pixels, web beacons, and local storage to collect
          information about how you use SafeTravels and provide features to you.
          <br />
          <br />
          We may ask advertisers or other partners to serve ads or services to
          your devices, which may use cookies or similar technologies placed by
          us or the third party.
          <br />
          <br />
          If you don’t want to receive certain categories of cookies on the
          Services, you can opt-out of them. We will need to set a cookie so
          that we can remember your choices when you next visit the website from
          the same browser. At the present time it is not technically possible
          for us to allow you to carry your settings with you between your
          browsers and devices so you will need to change these settings from
          each browser you use.
          <br />
          <br />
          Please also be aware that we make every effort to respect your
          choices, however, there is the possibility that not all cookies will
          be captured. If this is a concern then we would recommend that you
          change your cookie settings via your browser; your browser help
          function will tell you how.
          <br />
          <br />
          Please note that you may lose some functionality if you choose to
          disable cookies. We use the following types of cookies:
          <br />
          <br />
          (a) Strictly necessary cookies: These cookies are strictly necessary
          to enable you to move about the site or to provide certain features
          you have requested.
        </p>
        <p>
          (b) Functionality cookies: These cookies enhance the functionality of
          website by storing your preferences.
        </p>
        <p>
          (c) Performance cookies: These cookies help to improve the performance
          of the website, providing a better user experience.
        </p>
        <p>
          (d) Online behavioural advertising cookies: These cookies are for the
          international version of the website which features advertising. They
          are used to tailor the advertising to each user.
        </p>
        <p>Log file information:</p>
        <p>
          Log file information is automatically reported by your browser each
          time you make a request to access (i.e., visit) a web page or app. It
          can also be provided when the content of the webpage or app is
          downloaded to your browser or device.
          <br />
          <br />
          When you use our Service, our servers automatically record certain log
          file information, including your web request, Internet Protocol (“IP”)
          address, browser type, referring / exit pages and URLs, number of
          clicks and how you interact with links on the Service, domain names,
          landing pages, pages viewed, and other such information. We may also
          collect similar information from emails sent to our Users which then
          help us track which emails are opened and which links are clicked by
          recipients. The information allows for more accurate reporting and
          improvement of the Service.
          <br />
          <br />
          Device identifiers:
        </p>
        <p>
          When you use a mobile device like a tablet or phone to access our
          Service, we may access, collect, monitor, store on your device, and/or
          remotely store one or more “device identifiers.” Device identifiers
          are small data files or similar data structures stored on or
          associated with your mobile device, which uniquely identify your
          mobile device. A device identifier may be data stored in connection
          with the device hardware, data stored in connection with the device’s
          operating system or other software, or data sent to the device by
          SafeTravels.
          <br />
          <br />
          A device identifier may deliver information to us or to a third party
          partner about how you browse and use the Service and may help us or
          others provide reports or personalized content and ads. Some features
          of the Service may not function properly if use or availability of
          device identifiers is impaired or disabled.
          <br />
          <br />
          Metadata:
        </p>
        <p>
          Metadata is usually technical data that is associated with User
          Content. For example, Metadata can describe how, when and by whom a
          piece of User Content was collected and how that content is formatted.
          <br />
          <br />
          Users can add or may have Metadata added to their User Content
          including a hashtag (e.g., to mark keywords when you post a photo),
          geotag (e.g., to mark your location to a photo), comments or other
          data. This makes your User Content more searchable by others and more
          interactive. If you geotag your photo or tag your photo using other’s
          APIs then, your latitude and longitude will be stored with the photo
          and searchable (e.g., through a location or map feature) if your photo
          is made public by you in accordance with your privacy settings.
          <br />
          <br />
          2. HOW WE USE YOUR INFORMATION
        </p>
        <p>
          In addition to some of the specific uses of information we describe in
          this Privacy Policy, we may use information that we receive to:
        </p>
        <p>
          help you efficiently access your information after you sign in
          <br />
          <br />
          remember information so you will not have to re-enter it during your
          visit or the next time you visit the Service;
          <br />
          <br />
          provide personalized content and information to you and others, which
          could include online ads or other forms of marketing
          <br />
          <br />
          provide, improve, test, and monitor the effectiveness of our Service;
          <br />
          <br />
          integrate the Service with the relevant Tour Company;
          <br />
          <br />
          develop and test new products and features
          <br />
          <br />
          monitor metrics such as total number of visitors, traffic, and
          demographic patterns
          <br />
          <br />
          diagnose or fix technology problems
          <br />
          <br />
          automatically update the I application on your device SafeTravels or
          other Users may run contests, special offers or other events or
          activities (“Events”) on the Service.
          <br />
          <br />
          3. SHARING OF YOUR INFORMATION
        </p>
        <p>
          We may personal information only to our service providers who assist
          us in operating the Services and the relevant Tour Companies. We will
          only disclose personal information to an unrelated third party with
          your consent.
        </p>
        <p>Parties with whom we may share your information:</p>
        <p>
          We may share User Content and your information (including but not
          limited to, information from cookies, log files, device identifiers,
          location data, and usage data) with businesses that are legally part
          of the same group of companies that SafeTravels is part of, or that
          become part of that group (“Affiliates”). Affiliates may use this
          information to help provide, understand, and improve the Service
          (including by providing analytics) and Affiliates’ own services
          (including by providing you with better and more relevant
          experiences). But these Affiliates will honor the choices you make
          about who can see your photos.
          <br />
          <br />
          We also may share your information as well as information from tools
          like cookies, log files, and device identifiers and location data,
          with third-party organizations that help us provide the Service to you
          (“Service Providers”). Our Service Providers will be given access to
          your information as is reasonably necessary to provide the Service
          under reasonable confidentiality terms.
          <br />
          <br />
          We may also share certain information such as cookie data with
          third-party advertising partners. This information would allow
          third-party ad networks to, among other things, deliver targeted
          advertisements that they believe will be of most interest to you.
          <br />
          <br />
          We may remove parts of data that can identify you and share anonymized
          data with other parties. We may also combine your information with
          other information in a way that it is no longer associated with you
          and share that aggregated information.
          <br />
          <br />
          Apart from such circumstances, we may disclose personal information in
          special situations where we have reason to believe that doing so is
          necessary to identify, contact or bring legal action against anyone
          damaging, injuring or interfering (intentionally or unintentionally)
          with our rights or property, users or anyone else who could be harmed
          by such activities.
        </p>
        <p>We may also disclose personal information as required by law.</p>
        <p>
          In the event that we sell or buy businesses or their assets, or engage
          in transfers, acquisitions, mergers, restructurings, changes of
          control and other similar transactions, customer information is
          generally one of the transferable business assets. Thus, your personal
          information may be subject to such a transfer. In the unlikely event
          of insolvency, personal information may be transferred to a trustee or
          debtor in possession and then to a subsequent purchaser.
        </p>
        <p>
          If you voluntarily provide information in publicly accessible areas of
          the Services, the information can be viewed and therefore used by
          others. We advise users not to disclose contact information that they
          do not wish to have publicly available. We are not responsible for
          personal information you choose to submit publicly.
        </p>
        <p>Parties with whom you may choose to share your User Content:</p>
        <p>
          Any information or content that you voluntarily disclose for posting
          to the Service, such as User Content, becomes available to the public,
          as controlled by any applicable privacy settings that you set. To
          change your privacy settings on the Service, please change your
          profile setting. Once you have shared User Content or made it public,
          that User Content may be re-shared by others.
          <br />
          <br />
          If you remove information that you posted to the Service, copies may
          remain viewable in cached and archived pages of the Service, or if
          other Users or third parties using the SafeTravels API have copied or
          saved that information.
          <br />
          <br />
          What happens in the event of a change of control:
        </p>
        <p>
          If we sell or otherwise transfer part or the whole of SafeTravels or
          our assets to another organization (e.g., in the course of a
          transaction like a merger, acquisition, bankruptcy, dissolution,
          liquidation), your information such as name and email address, User
          Content and any other information collected through the Service may be
          among the items sold or transferred. You will continue to own your
          User Content. The buyer or transferee will have to honor the
          commitments we have made in this Privacy Policy.
          <br />
          <br />
          Responding to legal requests and preventing harm:
        </p>
        <p>
          We may access, preserve and share your information in response to a
          legal request (like a search warrant, court order or subpoena) if we
          have a good faith belief that the law requires us to do so. This may
          include responding to legal requests from jurisdictions outside of the
          Australia where we have a good faith belief that the response is
          required by law in that jurisdiction, affects users in that
          jurisdiction, and is consistent with internationally recognized
          standards. We may also access, preserve and share information when we
          have a good faith belief it is necessary to: detect, prevent and
          address fraud and other illegal activity; to protect ourselves, you
          and others, including as part of investigations; and to prevent death
          or imminent bodily harm. Information we receive about you may be
          accessed, processed and retained for an extended period of time when
          it is the subject of a legal request or obligation, governmental
          investigation, or investigations concerning possible violations of our
          terms or policies, or otherwise to prevent harm.
          <br />
          <br />
          4. HOW WE STORE YOUR INFORMATION
        </p>
        <p>Storage and Processing:</p>
        <p>
          Your information collected through the Service may be stored and
          processed in Australia or any other country in which SafeTravels, its
          Affiliates or Service Providers maintain facilities.
          <br />
          <br />
          SafeTravels, its Affiliates, or Service Providers may transfer
          information that we collect about you, including personal information
          across borders and from your country or jurisdiction to other
          countries or jurisdictions around the world. If you are located in the
          European Union or other regions with laws governing data collection
          and use that may differ from U.S. law, please note that we may
          transfer information, including personal information, to a country and
          jurisdiction that does not have the same data protection laws as your
          jurisdiction.
          <br />
          <br />
          By registering for and using the Service you consent to the transfer
          of information to the Australia or to any other country in which
          SafeTravels, its Affiliates or Service Providers maintain facilities
          and the use and disclosure of information about you as described in
          this Privacy Policy.
          <br />
          <br />
          We use commercially reasonable safeguards to help keep the information
          collected through the Service secure and take reasonable steps (such
          as requesting a unique password) to verify your identity before
          granting you access to your account. However, SafeTravels cannot
          ensure the security of any information you transmit to SafeTravels or
          guarantee that information on the Service may not be accessed,
          disclosed, altered, or destroyed.
          <br />
          <br />
          Please do your part to help us. You are responsible for maintaining
          the secrecy of your unique password and account information, and for
          controlling access to emails between you and SafeTravels, at all
          times. Your privacy settings may also be affected by changes the
          social media services you connect to SafeTravels make to their
          services. We are not responsible for the functionality, privacy, or
          security measures of any other organization.
          <br />
          <br />
          5. YOUR CHOICES ABOUT YOUR INFORMATION
        </p>
        <p>Your account information and profile/privacy settings:</p>
        <p>
          Update your account at any time by logging in and changing your
          profile settings.
          <br />
          <br />
          Unsubscribe from email communications from us by clicking on the
          “unsubscribe link” provided in such communications. As noted above,
          you may not opt out of Service-related communications (e.g., account
          verification, purchase and billing confirmations and reminders,
          changes/updates to features of the Service, technical and security
          notices).
          <br />
          <br />
          How long we keep your User Content:
        </p>
        <p>
          Following termination or deactivation of your account, SafeTravels,
          its Affiliates, or its Service Providers may retain information
          (including your profile information) and User Content for a
          commercially reasonable time for backup, archival, and/or audit
          purposes.
          <br />
          <br />
          Access and correction:
        </p>
        <p>
          Australian Privacy Principle 6 of the Privacy Act 1988 (Cth) allows
          you to get access to, and correct, the personal information we hold
          about you in certain circumstances. If you would like to obtain such
          access, please contact us on the details set out above.
          <br />
          <br />
          Please note that the access and correction requirements under this
          Privacy Policy operates alongside and do not replace other informal or
          legal procedures by which an individual can be provided access to, or
          correction of, their personal information, including the requirements
          under the Freedom of Information Act 1982 (Cth).
          <br />
          <br />
          Complaints:
        </p>
        <p>
          Australian Privacy Principle 1 of the Privacy Act 188 (Cth) allows you
          to make a complaint about any alleged breaches of privacy. In order to
          lodge a complaint with us, please contact using the details above with
          the following information: your name and address; details of the
          alleged breach of privacy; and URL link to the alleged breach of
          privacy (if applicable).
          <br />
          <br />
          Please allow us 30 days to investigate your complaint, after which we
          will contact you immediately to resolve the issue.
          <br />
          <br />
          6. CHILDREN’S PRIVACY
        </p>
        <p>
          SafeTravels does not knowingly collect or solicit any information from
          anyone under the age of 13 or knowingly allow such persons to register
          for the Service. The Service and its content are not directed at
          children under the age of 13. In the event that we learn that we have
          collected personal information from a child under age 13 without
          parental consent, we will delete that information as quickly as
          possible. If you believe that we might have any information from or
          about a child under 13, please contact us.
        </p>
        <p>7. OTHER WEB SITES AND SERVICES</p>
        <p>
          We are not responsible for the practices employed by any websites or
          services linked to or from our Service, including the information or
          content contained within them. Please remember that when you use a
          link to go from our Service to another website or service, our Privacy
          Policy does not apply to those third-party websites or services. Your
          browsing and interaction on any third-party website or service,
          including those that have a link on our website, are subject to that
          third party’s own rules and policies. In addition, you agree that we
          are not responsible and do not have control over any third-parties
          that you authorize to access your User Content. If you are using a
          third-party website or service and you allow them to access your User
          Content you do so at your own risk.
        </p>
        <p>8. HOW TO CONTACT US ABOUT A DECEASED USER</p>
        <p>
          In the event of the death of an SafeTravels User, please contact us.
          We will usually conduct our communication via email; should we require
          any other information, we will contact you at the email address you
          have provided in your request.
        </p>
        <p>9. Transfer out of Australia</p>
        <p>
          The website is not hosted in Australia. For that reason, we transfer
          all data on the Site (including all personal information) to our
          hosting service providers and data centres located overseas. You
          hereby expressly and voluntarily grant your informed consent to such
          transfers.
        </p>
        <p>10. HOW TO CONTACT US</p>
        <p>
          If you have any questions about this Privacy Policy or the Service,
          please find the appropriate support channel in SafeTravels help center
          at which to contact us.
        </p>
        <p>11. CHANGES TO OUR PRIVACY POLICY</p>
        <p>
          SafeTravels may modify or update this Privacy Policy from time to
          time, so please review it periodically. We may provide you additional
          forms of notice of modifications or updates as appropriate under the
          circumstances. Your continued use of SafeTravels or the Service after
          any modification to this Privacy Policy will constitute your
          acceptance of such modification.
        </p>
        <p>12. CONSENT</p>
        <p>
          You warrant that you are able to give consents under Australian Law
          or, in the event that you do not have the capacity to give consent,
          you warrant that your guardian or attorney is able to give any consent
          required under this Privacy Policy on your behalf.
        </p>
        <p>
          You hereby expressly and voluntarily grant your informed consent to
          SafeTravels to deal with your personal information in accordance with
          the terms and conditions of this Privacy Policy. Should you retract
          your consent, please contact hello@safetravels.com If you retract your
          consent, you acknowledge and agree that failure to provide certain
          types of personal information may not give you access to the full
          functionality of the Site.
        </p>
      </div>
    </>
  );
};
export default PrivacyPolicy;
