// import React, { FC, InputHTMLAttributes, useState } from "react";
//
// interface Props extends InputHTMLAttributes<HTMLInputElement> {
//   touched?: boolean;
//   errors?: string;
//   placeholder?: string;
//   label?: string;
//   helperText?: string;
// }
//
// const BrandTextAreaInput: FC<Props> = ({
//   touched,
//   errors,
//   placeholder,
//   helperText,
//   label,
//   className,
//   ...rest
// }) => {
//   const [showError, setShowError] = useState(false);
//   return (
//     <div className="mt-8 flex flex-col w-full">
//       <label
//         htmlFor="about"
//         className="pb-2 text-sm  text-gray-800 dark:text-gray-100"
//       >
//         {label}
//       </label>
//       <textarea
//         required
//         className={`bg-transparent border border-gray-300 dark:border-gray-700 pl-3 py-3 shadow-sm rounded text-sm focus:outline-none focus:border-indigo-700 resize-none placeholder-gray-500 text-gray-500 dark:text-gray-400 ${className}`}
//         placeholder={placeholder}
//         rows={5}
//         defaultValue={""}
//       />
//       <p className="w-full text-right text-xs pt-1 text-gray-500 dark:text-gray-400"></p>
//       <div className="text-xs font-thin text-gray-700">{helperText}</div>
//     </div>
//   );
// };
//
// export default BrandTextAreaInput;

import classNames from "classnames";
import React, { CSSProperties, FocusEvent, FunctionComponent } from "react";
import InputHelper from "../Input/Inputhelper/InputHelper";
import SvgIconComponent from "../svgIconComponent/svgIcon.component";
import useId from "../../Hooks/useId";
import withFormikContext from "../withFormikContext";

interface InputProps {
  name?: string;
  onChange: (event: any) => void; // will change the type
  onBlur?: (event: FocusEvent) => void;
  placeholder?: string;
  error?: string;
  label?: string;
  helperText?: string;
  type?: "email" | "password" | "text" | "date" | "radio" | "file" | "number";
  className?: string;
  leadingIcon?: any;
  trailingIcon?: any;
  trailingSvgIcon?: any;
  leadingSvgIcon?: any;
  row?: number;
  col?: number;
  style?: any;
  placeholderColor?: string;
  labelClassName?: string;
  disabled?: boolean;
  readOnly?: boolean;
  labelColor?: string;
  tooltip?: string;
  onKeyUp?: (event: any) => void;
  optionalText?: string;
}

const BrandTextAreaInput: FunctionComponent<InputProps & Partial<any>> = ({
  name,
  value,
  onBlur,
  onChange,
  placeholder,
  label,
  helperText,
  leadingIcon,
  trailingIcon,
  trailingSvgIcon,
  error,
  row,
  col,
  className,
  style,
  placeholderColor,
  labelClassName,
  disabled,
  readOnly,
  leadingSvgIcon,
  labelColor,
  tooltip,
  onKeyUp,
  optionalText,
}) => {
  const id = useId();

  return (
    <>
      {label && (
        <span
          className={`mb-2 block  text-sm  text-gray-800 ${labelClassName}`}
        >
          {label}
        </span>
      )}

      <div className="relative rounded-md ">
        <>
          {(leadingSvgIcon || leadingIcon) && (
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              {leadingSvgIcon ? (
                <SvgIconComponent
                  icon={trailingSvgIcon}
                  onClick={() => null}
                  className={""}
                />
              ) : (
                leadingIcon
              )}
            </div>
          )}

          <textarea
            id={id}
            onChange={onChange}
            onBlur={onBlur}
            name={name}
            value={value}
            rows={row}
            spellCheck={true}
            onKeyUp={onKeyUp}
            disabled={disabled}
            readOnly={readOnly}
            cols={col}
            className={classNames(
              ` border border-gray-300  px-3 py-2 shadow-sm  text-sm focus:outline-none focus:border-success-300 bg-transparent placeholder-gray-500 text-gray-500 rounded-md  flex-1 appearance-none w-full shadow-sm text-base`,
              {
                "pl-10": !!leadingIcon,
                " text-brandGrey2 ": !!disabled,
                "border border-error-300 text-error-900 placeholder-error-300 focus:border-error-300 focus:ring-red":
                  !!error,
              },
              className
            )}
            placeholder={placeholder}
            style={style}
          />
        </>
      </div>
      <InputHelper type={"helper"} text={error ? undefined : helperText} />
      <InputHelper type={"error"} text={error} />
    </>
  );
};

BrandTextAreaInput.defaultProps = {
  type: "text",
  disabled: false,
};

export default withFormikContext(BrandTextAreaInput);

export const BrandTextAreaInputWithoutFormik = BrandTextAreaInput;
