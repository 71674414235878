import React from "react";
import classNames from "classnames";
import Icon from "../Icon/Icon";
import { IconType } from "react-icons";

interface Props {
  type?: "button" | "submit";
  child: IconType;
  onClick: () => void;
  className?: string;
  buttonClassName?: string;
}

const IconButton: React.FunctionComponent<Props> = ({
  type,
  child,
  onClick,
  className,
  buttonClassName,
}) => {
  return (
    <button
      type={type}
      onClick={onClick}
      className={classNames("flex-shrink-0", buttonClassName)}
    >
      <Icon value={child} className={classNames(className)} />
    </button>
  );
};

IconButton.defaultProps = {
  type: "button",
};

export default React.memo(IconButton);
