import { Formik, FormikProps, FormikValues } from "formik";
import React, { FC } from "react";
import * as yup from "yup";
import { BrandButton } from "../../../shared/Button/BrandButton";
import Input from "../../../shared/Input/Input";
import { AppContext } from "App.context";
import { ERROR_WENT_WRONG } from "constants/message";
import Loader from "react-loader-spinner";

interface Props {
  modalHandler: () => void;
  editData?: any;
}

const TripClone: FC<Props> = ({ modalHandler, editData }) => {
  const { store, toast } = AppContext;

  const businessDetails = store.userStore.loggedInUser?.memberships?.items?.[0];

  return (
    <Formik
      initialValues={{
        id: editData.id,
        name: editData?.name || "",
        location: editData?.location || "",
        start_date: editData?.start_date || "",
        end_date: editData?.end_date || "",
      }}
      validationSchema={yup.object().shape({
        name: yup.string().required("First Name is Required"),
        location: yup.string().required("Trip Location is Required"),
        start_date: yup.date().required("Start Date Is Required"),
        end_date: yup
          .date()
          .required("End Date Is Required")
          .when(
            "start_date",
            (eventStartDate, schema) =>
              eventStartDate &&
              schema.min(
                eventStartDate,
                "Finish Date Must Later Then Start Date"
              )
          ),
      })}
      onSubmit={async (data, formikHelpers) => {
        formikHelpers.setSubmitting(true);
        const mappedValues = {
          id: data.id,
          business_id: businessDetails!.business_id!,
          overview: { ...data },
        };
        try {
          await store.tripStore.createFromTemplate(mappedValues);

          formikHelpers.setSubmitting(false);
          modalHandler();
        } catch (e: any) {
          formikHelpers.setSubmitting(false);
          toast.showError(e.message || ERROR_WENT_WRONG);
        }

        formikHelpers.setSubmitting(false);
      }}
    >
      {(formikProps: FormikProps<FormikValues>) => (
        <form onSubmit={formikProps.handleSubmit}>
          <div className="bg-white ">
            <div className="mx-auto bg-white rounded ">
              <div className="border-b border-gray-300 dark:border-gray-700 py-5 bg-white ">
                <div className="w-11/12 mx-auto items-center pb-4">
                  <div className=" items-start  flex flex-col">
                    <h1 className="text-5xl tracking-tight font-bold text-gray-800 sm:text-5xl md:text-5xl py-10">
                      <span className="block xl:inline">
                        Create Trip from Template
                      </span>
                    </h1>
                    <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                      Enter the trip name and start date to clone the trip. All
                      activities in the trip template will be shifted to match
                      the new trip start date.
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-2/3 mx-auto flex flex-col space-y-4 mt-4">
                <div className="flex w-full space-x-6">
                  <div className="w-1/2">
                    <Input
                      label="Trip Name*"
                      helperText="Please enter the trip name."
                      name={"name"}
                    />
                  </div>
                </div>
                <div className="flex w-full space-x-6">
                  <div className={"w-1/2"}>
                    <Input
                      className=""
                      type=""
                      label="Trip Location"
                      helperText="Please enter the trip location."
                      name={"location"}
                    />
                  </div>
                </div>
                <div className="font-normal w-1/2">
                  <Input
                    label="Start Date*"
                    name={"start_date"}
                    type={"date"}
                  />
                </div>
                <div className="font-normal w-1/2">
                  <Input label="End Date*" name={"end_date"} type={"date"} />
                </div>
              </div>
            </div>
            <hr className="h-0.5 mt-6 bg-gray-400 mx-auto" />

            <div className="w-11/12 mx-auto border-gray-300 flex justify-end dark:border-gray-700 py-5 bg-white ">
              {formikProps.isSubmitting && (
                <span className="mr-3">
                  <Loader type="BallTriangle" height={30} width={30} />
                </span>
              )}
              <div className="w-52 items-center justify-between flex pb-4">
                <BrandButton
                  className=" text-base font-semibold bg-gray-200 hover:bg-gray-400"
                  disabled={formikProps.isSubmitting}
                  type="button"
                  theme="basic"
                  onClick={modalHandler}
                  size={"xl"}
                >
                  Cancel
                </BrandButton>
                <BrandButton
                  className=" text-base font-semibold"
                  disabled={formikProps.isSubmitting}
                  type="submit"
                  theme="primary"
                  size={"xl"}
                >
                  Create
                </BrandButton>
              </div>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};
export default TripClone;
