import React, { FunctionComponent } from "react";
import { SortableHandle } from "react-sortable-hoc";
import styled from "styled-components";
import { nanoid } from "nanoid";
import { FaGripLinesVertical } from "react-icons/fa";
import Icon from "shared/Icon/Icon";
const Handle = styled.div`
  margin-right: 10px;
  padding: 0 5px;
  cursor: grab;
`;

interface Props {
  dataArray: any;
}

const RowHandler = SortableHandle(() => (
  <Handle className="handle">
    <Icon
      value={FaGripLinesVertical}
      className="text-gray-500 hover:text-brandPrimary cursor-pointer"
    />
  </Handle>
));

const TableRow: FunctionComponent<Props> = ({ dataArray }) => {
  console.log("dataArray", dataArray?.[0].data);
  return (
    <tr>
      {dataArray?.[0].data?.map((column: any, index: number) => (
        <>
          <td className={`border-b-2 p-4`} key={nanoid()}>
            {index === 0 ? (
              <div className="flex space-x-2 items-center ">
                <RowHandler />{" "}
                <span className={"cursor-pointer "}>{column}</span>
              </div>
            ) : (
              column
            )}
          </td>
        </>
      ))}
    </tr>
  );
};

export default TableRow;
