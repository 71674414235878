import React, { FC, useState } from "react";

/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
// import { MenuIcon, XIcon } from '@heroicons/react/outline'
import {
  AnnotationIcon,
  GlobeIcon,
  LightningBoltIcon,
  ScaleIcon,
  SpeakerphoneIcon,
  XIcon,
  MenuIcon,
} from "@heroicons/react/outline";
import Modal from "../../../shared/Modal/Modal";
import logo from "../../../assets/safetravels-logo.png";
import hawaii from "../../../assets/hawaii.png";
import * as yup from "yup";
import { Formik, FormikProps, FormikValues } from "formik";
import Input from "../../../shared/Input/Input";
import { BrandButton } from "../../../shared/Button/BrandButton";
import { AppContext } from "../../../App.context";
import { ERROR_WENT_WRONG } from "../../../constants/message";
import { RouteConfig } from "../../../constants/RouteConfig";
import Loader from "react-loader-spinner";
import { useHistory } from "react-router";

interface Props {
  modalHandler: () => void;
}

const Sample: FC<Props> = ({ modalHandler }) => {
  const { store, toast } = AppContext;
  const [menu, setMenu] = useState(false);
  const [show, setShow] = useState(false);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const [sent, setSent] = useState(true);

  const openHawaii = () => {
    window.open("https://travel.hawaii.gov/#/", "_blank");
    openNewLink();
    //         var win = window.open(url, '_blank');
    //   win.focus();
  };

  const openNewLink = () => {
    // window.open(
    //   "https://www.viator.com/Hawaii/d278-ttd?pid=P00038104&mcid=42383&medium=link&campaign=SafeTravels-Website",
    //   "_self"
    // );
    console.log("open viator");

    //  newTab.location.href = url;
  };
  return (
    <div className="relative bg-white overflow-hidden">
      <div className="max-w-7xl mx-auto">
        <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
          <svg
            className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
            fill="currentColor"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            aria-hidden="true"
          >
            <polygon points="50,0 100,0 50,100 0,100" />
          </svg>

          <main className="pt-10 mx-auto max-w-7xl px-4 sm:pt-12 sm:px-6 md:pt-16 lg:pt-20 lg:px-8 xl:pt-28">
            <div className="sm:text-center lg:text-left">
              <h1 className="text-4xl tracking-tight font-bold text-gray-800 sm:text-5xl md:text-6xl">
                <span className="block xl:inline">Try out </span>{" "}
                <span className="block text-indigo-600 xl:inline">our app</span>{" "}
                with a sample tour.
              </h1>
              <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                Are you a travel company interest in the SafeTravels app? Enter
                your details below and we will send you a sample tour code, that
                you can use in the app.
              </p>
              <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                <div className="mt-8 w-3/4">
                  <div>
                    {(() => {
                      if (sent) {
                        return (
                          <div>
                            <Formik
                              initialValues={{
                                email: "",
                                name: "",
                                company: "",
                              }}
                              validationSchema={yup.object().shape({
                                email: yup
                                  .string()
                                  .required()
                                  .email("Please enter a valid email"),
                                name: yup
                                  .string()
                                  .required("Please enter your name"),
                                company: yup
                                  .string()
                                  .required("Your enter your company name"),
                              })}
                              onSubmit={async (data, formikHelpers) => {
                                formikHelpers.setSubmitting(true);

                                console.log("submitted");
                                console.log(data);
                                try {
                                  console.log("submitting homwe form");
                                  await store.userStore.homeForm(data);
                                  toast.showSuccess("Sent Successfully");
                                  // history.push("/");
                                  // setSent(!sent)
                                  formikHelpers.setSubmitting(false);
                                } catch (e: any) {
                                  console.log("error", e);

                                  formikHelpers.setSubmitting(false);
                                  toast.showError(
                                    e.message || ERROR_WENT_WRONG
                                  );
                                }
                              }}
                            >
                              {(formikProps: FormikProps<FormikValues>) => (
                                <form onSubmit={formikProps.handleSubmit}>
                                  <div className={"flex flex-col space-y-4"}>
                                    <div>
                                      <Input
                                        placeholder="Travel Company Name"
                                        label="Company"
                                        type="text"
                                        name={"company"}
                                        theme="secondary"
                                        onChange={formikProps.handleChange}
                                      />
                                    </div>
                                    <div>
                                      <Input
                                        placeholder="Your Name"
                                        label="Your name"
                                        type="text"
                                        name={"name"}
                                        theme="secondary"
                                        onChange={formikProps.handleChange}
                                      />
                                    </div>
                                    <div>
                                      <Input
                                        placeholder="Email"
                                        label="Email"
                                        type="email"
                                        name={"email"}
                                        theme="secondary"
                                        onChange={formikProps.handleChange}
                                      />
                                    </div>
                                  </div>

                                  <div className="flex space-x-3 w-full items-center mt-5">
                                    <BrandButton
                                      className=" text-base font-semibold"
                                      disabled={formikProps.isSubmitting}
                                      theme="primary"
                                      size={"xl"}
                                    >
                                      Submit
                                    </BrandButton>

                                    {formikProps.isSubmitting && (
                                      <span className="ml-3">
                                        <Loader
                                          type="BallTriangle"
                                          height={30}
                                          width={30}
                                        />
                                      </span>
                                    )}
                                  </div>
                                </form>
                              )}
                            </Formik>
                          </div>
                        );
                      } else {
                        return (
                          <div>
                            <p className="mt-3 text-base text-indigo-600 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                              Thanks! You should receive an email shortly with
                              instructions to download the SafeTravels app and
                              add the sample tour we have created for you.
                            </p>
                          </div>
                        );
                      }
                    })()}
                  </div>
                </div>
                <div className="mt-3 sm:mt-0 sm:ml-3"></div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 hidden md:block">
        <img
          className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
          src={hawaii}
          alt=""
        />
      </div>
    </div>
  );
};
export default Sample;
