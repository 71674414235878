import React, { FunctionComponent } from "react";
import classNames from "classnames";

export interface Props {
  label?: string;
  className?: string;
}

const InputLabel: FunctionComponent<Props> = ({
  label,

  className,
}) => {
  const renderLabel = (
    <label className={classNames("block flex  leading-none", className)}>
      {label}
    </label>
  );

  return <>{renderLabel}</>;
};

export default InputLabel;
