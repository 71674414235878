import React, { FC, useEffect, useState } from "react";
import Modal from "../../../shared/Modal/Modal";
import { BrandButton } from "../../../shared/Button/BrandButton";
import AddBusinessContent from "./AddBusinessContent";
import GenericHeader from "../../../shared/Headers/GenericHeader";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import AddBusinessContentArticleName from "./AddBusinessContentArticleName";
import { AppContext } from "App.context";
import { observer } from "mobx-react-lite";
import parse from "html-react-parser";
import { EntityIdentifier } from "@devslane/mobx-entity-manager";
import { ERROR_WENT_WRONG } from "constants/message";
import IconButton from "shared/IconButton/IconButton";
import { SectionModel } from "store/models/SectionModal";
import ReorderTable from "./ReorderTable";
import Loader from "react-loader-spinner";

const AddBusinessContents: FC = () => {
  const { store, toast } = AppContext;
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openArticleId, setOpenArticleId] = useState<number | undefined>(
    undefined
  );

  const [isReorder, setIsReorder] = useState<boolean>(false);
  const [sectionItems, setSectionItems] = useState<any>();

  const businessId = store.userStore.loggedInUser?.memberships?.items?.[0]?.id;
  const [editData, setEditData] = useState<SectionModel | undefined>();
  const [editArticleData, setEditArticleData] = useState<any | undefined>();

  const staffTable = {
    headingsArray: ["Section Name (click to add content)", "Actions"],
  };

  const handleSectionDelete = async (id: EntityIdentifier) => {
    try {
      if (confirm("Are you sure you want to delete this section?")) {
        await store.sectionStore.deleteSection({
          section_id: id,
          business_id: businessId!,
        });
        toast.showSuccess("Section Deleted Successfully");
      }
    } catch (e: any) {
      toast.showError(e.message || ERROR_WENT_WRONG);
    }
  };

  const handleArticleDelete = async (id: EntityIdentifier) => {
    try {
      if (confirm("Are you sure you want to delete this section ?")) {
        await store.sectionStore.deleteArticle({
          section_id: store.sectionStore.sectionList.items[openArticleId!].id,
          business_id: businessId!,
          article_id: id,
        });
        toast.showSuccess("Section Deleted Successfully");
      }
    } catch (e: any) {
      toast.showError(e.message || ERROR_WENT_WRONG);
    }
  };

  const closeModal = () => {
    setEditData(undefined);
    setOpenModal(false);
    setEditArticleData(undefined);
  };

  //  Sets the Table Items for the App Content Sections
  const setTableItems = () => {
    setSectionItems(
      (store.sectionStore.sectionList.items || [])?.map((section, index) => {
        return [
          {
            id: section.id,
            name: section.name,
            content: section.content,
            data: [
              <a href="javascript:;" onClick={() => setOpenArticleId(index)}>
                {section.name}
              </a>,
              <div className={"flex space-x-3"}>
                <div
                  className=""
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                >
                  <IconButton
                    onClick={() => setEditData(section)}
                    child={FaEdit}
                    buttonClassName={
                      "text-white rounded-full p-2 bg-indigo-500"
                    }
                    className="h-4 w-4"
                  />
                </div>
                <div
                  className=""
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                >
                  <IconButton
                    child={FaTrashAlt}
                    onClick={() => handleSectionDelete(section.id)}
                    buttonClassName={
                      " text-white rounded-full p-2 bg-error-500"
                    }
                    className="h-4 w-4"
                  />
                </div>
              </div>,
            ],
          },
        ];
      })
    );
  };

  // When section is clicked, we change the view and display the articles for that section
  const [openSectionId, setOpenSectionId] = useState<
    EntityIdentifier | undefined
  >(undefined);
  const setQuestionItems = () => {
    setOpenSectionId(store.sectionStore.sectionList.items[openArticleId!].id);
    setSectionItems(
      (store.sectionStore.sectionList.items[openArticleId!].content || [])?.map(
        (c, index) => {
          return [
            {
              id: c._id,
              name: c.name,
              // description: c.description,
              data: [
                c.name,
                // parse(c.description || ""),
                <div className={"flex space-x-3"}>
                  <div className="cursor-pointer text-white rounded-full p-2 bg-indigo-500">
                    <IconButton
                      onClick={() => setEditArticleData(c)}
                      child={FaEdit}
                      buttonClassName={
                        "text-white rounded-full p-2 bg-indigo-500"
                      }
                      className="h-4 w-4"
                    />
                  </div>
                  <div className="cursor-pointer text-white rounded-full p-2 bg-error-500">
                    <IconButton
                      child={FaTrashAlt}
                      buttonClassName={
                        " text-white rounded-full p-2 bg-error-500"
                      }
                      onClick={() => handleArticleDelete(c._id)}
                      className="h-4 w-4"
                    />
                  </div>
                </div>,
              ],
            },
          ];
        }
      )
    );
  };

  useEffect(() => {
    const loadContent = async () => {
      await store.sectionStore.loadSection(businessId!);
      if (typeof openArticleId === "undefined") setTableItems();
      else setQuestionItems();
    };
    loadContent();
  }, [businessId, openArticleId]);

  useEffect(() => {
    setIsReorder(true);
  }, [sectionItems]);

  useEffect(() => {
    if (typeof openArticleId === "undefined") setTableItems();
    else setQuestionItems();
  }, [store.sectionStore.sectionList.items]);

  const reorder = async () => {
    toast.showInfo("Reordering Started");
    if (typeof openArticleId === "undefined") {
      const payload = sectionItems.map((si: any, index: any) => ({
        id: si[0].id,
        name: si[0].name,
        content: si[0].content,
        order: index + 1,
      }));
      await store.sectionStore.reorderSection(payload, businessId!);
    } else {
      const payload = sectionItems.map((si: any, index: any) => ({
        id: si[0].id,
        name: si[0].name,
        description: si[0].description,
        order: index + 1,
      }));
      await store.sectionStore.reorderQuestion(
        payload,
        businessId!,
        openSectionId!
      );
    }
    toast.showInfo("Reordering Completed");
  };

  return (
    <>
      <Modal
        modalStyle={{ width: "65rem", maxHeight: "95%", overflowY: "auto" }}
        open={openModal || !!editData || !!editArticleData}
        onOutsideClick={closeModal}
      >
        {typeof openArticleId === "undefined" || !!editData ? (
          <AddBusinessContentArticleName
            editData={editData}
            modalHandler={closeModal}
          />
        ) : (
          (typeof openArticleId !== "undefined" || !!editArticleData) && (
            <AddBusinessContent
              editData={editArticleData}
              section_id={
                store.sectionStore.sectionList.items[openArticleId].id || ""
              }
              modalHandler={closeModal}
              label={
                store.sectionStore.sectionList.items[openArticleId].name || ""
              }
            />
          )
        )}
      </Modal>

      {typeof openArticleId === "undefined" ? (
        <div className="bg-white">
          <div className="pb-8">
            <GenericHeader
              heading={"App Content"}
              subHeading={
                "The 'Information' tab of the app is the area of the app where you can provide all of the information and content that guests need for their trips. Typically, companies will use this for sections such as About Us, Packing Lists, Other tours etc. "
              }
              buttonOnClick={() => setOpenModal(true)}
              buttonText={"Add Section"}
            />
            <hr className="h-0.5 mt-2 bg-gray-50 mx-auto w-full" />
          </div>

          {!!store.sectionStore.sectionList.items?.length && (
            <div className={"flex justify-end px-8"}>
              <BrandButton
                className=" text-base font-semibold "
                theme="primary"
                size={"xl"}
                onClick={() => reorder()}
              >
                Reorder
              </BrandButton>
            </div>
          )}

          <div className="mt-6 w-full px-8">
            {store.sectionStore.sectionList.loading ? (
              <div className={"flex justify-center mx-auto"}>
                <Loader type="BallTriangle" height={30} width={30} />
              </div>
            ) : (
              <ReorderTable
                headers={staffTable.headingsArray}
                items={sectionItems}
                setItems={setSectionItems}
              />
            )}
          </div>
          <hr className="h-0.5 mt-10 bg-gray-50 mx-auto w-full" />
        </div>
      ) : (
        <div>
          <div className=" items-start  flex flex-col">
            <h1 className="text-5xl tracking-tight font-bold text-gray-800 sm:text-5xl md:text-5xl py-10">
              <span className="block xl:inline">Add Content for </span>
              <span className="block text-indigo-600 xl:inline">
                {store.sectionStore.sectionList.items[openArticleId!].name}
              </span>
            </h1>
            <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
              Add content sections for{" "}
              {store.sectionStore.sectionList.items[openArticleId!].name}. Drag
              and drop the icon on the left to re-order the content sections and
              click the 'Re-order' button to Save the changes.
            </p>
          </div>

          <div className="flex justify-between py-10">
            <BrandButton
              className=" text-base font-semibold "
              type="button"
              theme="basic"
              size={"sm"}
              onClick={() => setOpenArticleId(undefined)}
            >
              Back
            </BrandButton>
            {!!sectionItems?.length && (
              <div className={"flex justify-end px-8"}>
                <BrandButton
                  className=" text-base font-semibold "
                  theme="primary"
                  size={"xl"}
                  onClick={() => reorder()}
                >
                  Reorder
                </BrandButton>
              </div>
            )}
            <BrandButton
              className=" text-base font-semibold "
              type="button"
              onClick={() => setOpenModal(true)}
              theme="primary"
              size={"xl"}
            >
              Add Content
            </BrandButton>
          </div>
          <div>
            {store.sectionStore.sectionList.loading ? (
              <div className={"flex justify-center mx-auto"}>
                <Loader type="BallTriangle" height={30} width={30} />
              </div>
            ) : (
              <ReorderTable
                headers={["title", "ACTIONS"]}
                items={sectionItems || []}
                setItems={setSectionItems}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default observer(AddBusinessContents);
