import classNames from "classnames";
import React from "react";
import { ThemeType } from "../../constants/enums/ThemeType";
import { AbbreviatedSize } from "../../constants/enums/AbbreviatedSize";

export interface Props {
  type?: "button" | "submit";
  className?: string;
  theme?: ThemeType;
  onClick?: (e?: any) => void;
  children?: string;
  disabled?: boolean; // TODO: use it to change display of button
  borderWidth?: string;
  boxShadow?: boolean;
  name?: string;
  size?: AbbreviatedSize;
}

// TODO: change text color based on type

export const BrandButton: React.FC<Props> = ({
  type,
  className,
  children,
  theme,
  onClick,
  disabled,
  borderWidth,
  boxShadow,

  name,
  size,
}) => {
  const classForTheme: any = {
    primary: "text-white bg-indigo-600 hover:bg-brandPrimary",
    secondary: "text-white bg-secondary-600 hover:bg-secondary-500",
    accent: "text-white bg-accent-600 hover:bg-accent-500",
    error: "text-white bg-error-600 hover:bg-error-500",
    warn: "text-white bg-warn-600 hover:bg-warn-500",
    white: "text-gray-700 bg-white hover:text-gray-500",
    outlined: "text-brandPrimary bg-white border border-brandPrimary",
    yellow: " bg-brandYellow text-gray-800  active:text-gray-800",
    basic: "rounded-lg bg-gray-200 hover:bg-gray-400",
    redirect: "text-barndBluePrimary hover:text-primary-600",
  };

  const classForSize: any = {
    xs: `px-2.5 py-1.5 text-xs leading-4 `,
    sm: `px-3 py-2 text-sm leading-4 `,
    md: `px-4 py-2 text-sm leading-5 `,
    lg: `px-4 py-2 text-base leading-6 `,
    xl: `px-6 py-3 text-base leading-6`,
  };

  return (
    <button
      type={type}
      onClick={onClick}
      name={name}
      style={{
        boxShadow: boxShadow ? "20px 20px 60px rgba(79, 179, 236, 0.4)" : "",
      }}
      disabled={disabled}
      className={classNames(
        "inline-flex relative",
        "justify-center",
        "items-center",
        `${borderWidth}`,
        "border-transparent",
        "rounded-md",
        "transition ease-in-out",
        "duration-150",
        classForTheme[theme!],
        disabled ? "pointer-events-none opacity-60" : "",
        classForSize[size!],
        className
      )}
    >
      {children}
    </button>
  );
};

BrandButton.defaultProps = {
  type: "submit",
  disabled: false,
  boxShadow: false,
};

export default React.memo(BrandButton);
