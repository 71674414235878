import { FormikProps, FormikValues, Formik } from "formik";
import React, { FC, useState } from "react";
import * as yup from "yup";
import BrandTextEditor from "../../../../shared/Input/BrandTextEditor";
import GenericHeader from "../../../../shared/Headers/GenericHeader";
import { AppContext } from "../../../../App.context";
import FormikBrandTextEditor from "../../../../shared/Input/FormikBrandTextEditor";
interface Props {
  formikProps: FormikProps<FormikValues>;
}

const TripOverivewDescription: FC<Props> = ({ formikProps }) => {
  return (
    <div className="bg-white ">
      <div className="container mx-auto  rounded">
        <div className="xl:w-full py-5 "></div>
        <hr className="h-0.5 mt-6 bg-gray-200 mx-auto w-full" />

        <form onSubmit={formikProps.handleSubmit}>
          <div className="mx-10">
            <div className=" mx-auto xl:mx-0 flex flex-col space-y-10 mt-12">
              <div>
                <FormikBrandTextEditor
                  placeholder="Description"
                  name="description"
                  height={"500px"}
                  labelClassName={"text-sm  text-gray-800 mb-2"}
                />
              </div>
            </div>
          </div>
        </form>
        <hr className="h-0.5 mt-6 bg-gray-200 mx-auto w-full" />
      </div>
    </div>
  );
};
export default TripOverivewDescription;
