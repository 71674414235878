import { ContextStatic, EntityIdentifier } from "@devslane/mobx-entity-manager";
import { map } from "lodash";
import { TripModel } from "store/models/TripModel";
import queryString from "query-string";
import { ActivityModal } from "../../models/ActivityModal";
import { AppContext } from "App.context";
import { ContactModel } from "store/models/ContactModal";

class TripService {
  static getInstance(): TripService {
    return new TripService();
  }

  async updateTripActivity(
    business_id: EntityIdentifier,
    trip_id: EntityIdentifier,
    data: {
      _id: EntityIdentifier;
      title: string;
      start: string;
      end: string;
      description: string;
      staff_notes: string;
      start_location: any;
      end_location: any;
      images: Array<{ id: EntityIdentifier; url: string }>;
      button: any;
      library?: boolean;
      private?: boolean;
      private_guests?: [{
        guest_id: string;
        first_name: string;
        last_name: string;
        email: string;
      }];
    }
  ): Promise<any> {
    debugger
    return await ContextStatic.default.apiService
      .post(
        `trips/activities?business_id=${business_id}&trip_id=${trip_id}`,
        data
      )
      .then((res: any) => {
        return {
          activities: map(res?.data, (activity) => {
            activity = {
              id: activity._id,
              ...activity,
              images: activity.images?.map((image: any) => ({
                id: image._id,
                url: image.url,
              })),
            };
            return ActivityModal.fromJson(activity) as ActivityModal;
          }),
        };
      });
  }

  async createTripActivity(
    business_id: EntityIdentifier,
    trip_id: EntityIdentifier,
    data: {
      title: string;
      start: string;
      end: string;
      description: string;
      staff_notes: string;
      start_location: any;
      end_location: any;
      images: Array<{ id: EntityIdentifier; url: string }>;
      button: any;
    }
  ): Promise<any> {
    debugger
    return await ContextStatic.default.apiService
      .post(
        `trips/activities/add?business_id=${business_id}&trip_id=${trip_id}`,
        data
      )
      .then((res: any) => {
        return {
          activities: map(res?.data, (activity) => {
            activity = {
              id: activity._id,
              ...activity,
              images: activity.images?.map((image: any) => ({
                id: image._id,
                url: image.url,
              })),
            };
            return ActivityModal.fromJson(activity) as ActivityModal;
          }),
        };
      });
  }

  async deleteTripActivity(
    business_id: EntityIdentifier,
    trip_id: EntityIdentifier,
    activity_id: EntityIdentifier
  ): Promise<any> {
    return await ContextStatic.default.apiService
      .delete(
        `trips/activities?business_id=${business_id}&trip_id=${trip_id}&activity_id=${activity_id}`
      )
      .then((res: any) => {
        return {
          activities: map(res?.data, (activity) => {
            activity = {
              id: activity._id,
              ...activity,
              images: activity.images?.map((image: any) => ({
                id: image._id,
                url: image.url,
              })),
            };
            return ActivityModal.fromJson(activity) as ActivityModal;
          }),
        };
      });
  }

  async getTripActivity(
    business_id: EntityIdentifier,
    trip_id: EntityIdentifier
  ): Promise<any> {
    return await ContextStatic.default.apiService
      .get(`trips/activities?business_id=${business_id}&trip_id=${trip_id}`)
      .then((res: any) => {
        // console.log(res.data.activities)
        return {
          activities: map(res?.data?.activities, (activity) => {
            activity = {
              id: activity._id,
              ...activity,
              images: activity.images?.map((image: any) => ({
                id: image._id,
                url: image.url,
              })),
            };

            return ActivityModal.fromJson(activity) as ActivityModal;
          }),
        };
      });
  }

  async createTrip(data: {
    business_id: EntityIdentifier;
    overview: {
      name: string;
      location: string;
      start_date: string;
    };
  }): Promise<{ data: any }> {
    return await ContextStatic.default.apiService
      .post(`trips/add?business_id=${data.business_id}`, data)
      .then((res: any) => {
        return { data: res || [] };
      });
  }
  async createFromTemplate(data: {
    business_id: EntityIdentifier;
    overview: {
      name: string;
      location: string;
      start_date: string;
    };
  }): Promise<{ data: any }> {
    return await ContextStatic.default.apiService
      .post(`trips/create-from-template?business_id=${data.business_id}`, data)
      .then((res: any) => {
        return { data: res || [] };
      });
  }
  async createTripContact(
    trip_id: EntityIdentifier,
    business_id: EntityIdentifier,
    data: ContactModel
  ): Promise<{ data: any }> {
    return await ContextStatic.default.apiService
      .post(
        `trips/contacts/add?business_id=${business_id}&trip_id=${trip_id}`,
        data
      )
      .then((res: any) => {
        return {
          data: map(res.data, (contact) => {
            contact = {
              id: contact._id,
              ...contact,
            };
            return ContactModel.fromJson(contact);
          }),
        };
      });
  }
  // async updateStaff(data: {
  //   business_id: EntityIdentifier;
  //   overview: {
  //     name: string;
  //     location: string;
  //     start_date: string;
  //   };
  // }): Promise<any> {
  //   return await ContextStatic.default.apiService.post(
  //     `business/staff?business_id=${data.business_id}`,
  //     data
  //   );
  // }

  async getTrips(business_id: EntityIdentifier): Promise<any> {
    return await ContextStatic.default.apiService
      .get(`trips?business_id=${business_id}`)
      .then((res: any) => {
        const data = res?.data || [];

        return {
          data: map(data, (overview) => {
            overview = {
              id: overview._id,
              ...overview.overview,
            };
            return TripModel.fromJson(overview);
          }),
        };
      });
  }

  async getTripContacts(
    trip_id: EntityIdentifier,
    business_id: EntityIdentifier
  ): Promise<any> {
    return await ContextStatic.default.apiService
      .get(`trips/contacts?business_id=${business_id}&trip_id=${trip_id}`)
      .then((res: any) => {
        const data = res?.data.contacts || [];
        return {
          data: map(data, (contact) => {
            contact = {
              id: contact._id,
              ...contact,
            };
            return ContactModel.fromJson(contact);
          }),
        };
      });
  }

  async getTrip(
    business_id: EntityIdentifier,
    trip_id: EntityIdentifier
  ): Promise<any> {
    return await ContextStatic.default.apiService
      .get(`trips/trip?business_id=${business_id}&trip_id=${trip_id}`)
      .then((res: any) => {
        let trip = res?.data || [];

        trip = {
          id: trip._id,
          ...trip,
          overview: { ...trip.overview, id: trip.overview._id },
          images: trip.images.map((img: any) => ({ ...img, id: img.image_id })),
        };
        return TripModel.fromJson(trip);
      });
  }

  async getTripTemplatess(business_id: EntityIdentifier): Promise<any> {
    return await ContextStatic.default.apiService
      .get(`trips/templates?business_id=${business_id}`)
      .then((res: any) => {
        const data = res?.data || [];

        return {
          data: map(data, (overview) => {
            overview = {
              id: overview._id,
              ...overview.overview,
            };
            return TripModel.fromJson(overview);
          }),
        };
      });
  }

  async updateTripContact(
    trip_id: EntityIdentifier,
    business_id: EntityIdentifier,
    contact_id: EntityIdentifier,
    data: any
  ): Promise<any> {
    const contactPayload = { ...data, _id: contact_id };
    return await ContextStatic.default.apiService
      .post(
        `trips/contacts?business_id=${business_id}&trip_id=${trip_id}`,
        contactPayload
      )
      .then((res: any) => {
        const data = res?.data || [];
        return {
          data: map(data, (contact) => {
            contact = {
              id: contact._id,
              ...contact,
            };
            return ContactModel.fromJson(contact);
          }),
        };
      });
  }

  async updateTrip(
    trip_id: EntityIdentifier,
    business_id: EntityIdentifier,
    data: any
  ): Promise<any> {
    data = {
      overview: {
        library: false,
        ...data,
      },
      images: data.images,
      _id: trip_id,
    };

    return await ContextStatic.default.apiService
      .post(`trips?business_id=${business_id}`, data)
      .then((res: any) => {
        data = res?.data || [];
        data = {
          ...data,
          id: data._id,
        };
        return TripModel.fromJson(data);
      });
  }

  async updateTripChatSettings(
    trip_id: EntityIdentifier,
    business_id: EntityIdentifier,
    data: any
  ): Promise<any> {
    data = {
      overview: { library: false, ...data.overview },
      images: data.images,
      _id: trip_id,
    };

    return await ContextStatic.default.apiService
      .post(`trips?business_id=${business_id}`, data)
      .then((res: any) => {
        data = res?.data || [];
        data = {
          ...data,
          id: data._id,
        };
        return TripModel.fromJson(data);
      });
  }

  async makeTemplate(business_id: EntityIdentifier, data: any): Promise<any> {
    return await ContextStatic.default.apiService
      .post(`trips/add-template?business_id=${business_id}`, data)
      .then((res: any) => {
        return { data: res || [] };
      });
  }
  async deleteTrip(data: {
    business_id: EntityIdentifier;
    trip_id: EntityIdentifier;
  }): Promise<any> {
    const query = queryString.stringify(data);

    return await ContextStatic.default.apiService.delete(`trips?${query}`);
  }

  async deleteTripContact(
    trip_id: EntityIdentifier,
    business_id: EntityIdentifier,
    contact_id: EntityIdentifier
  ): Promise<any> {
    const { store } = AppContext;
    return await ContextStatic.default.apiService
      .delete(
        `trips/contacts?business_id=${business_id}&trip_id=${trip_id}&contact_id=${contact_id}`
      )
      .then((res: any) => {
        const data = res?.data || [];
        return {
          data: map(data, (contact) => {
            contact = {
              id: contact._id,
              ...contact,
            };
            return ContactModel.fromJson(contact);
          }),
        };
      });
  }

  async getFavouritesTripActivity(business_id: EntityIdentifier): Promise<any> {
    return await ContextStatic.default.apiService
      .get(`trips/activities/favourites?business_id=${business_id}`)
      .then((res: any) => {
        return map(res?.data, ({ activity }) => {
          activity = {
            id: activity._id,
            ...activity,
            images: activity.images?.map((image: any) => ({
              id: image._id,
              url: image.url,
            })),
          };

          return ActivityModal.fromJson(activity) as ActivityModal;
        });
      });
  }
}

export const tripService = TripService.getInstance();
