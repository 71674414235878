import React, { useState, FC } from "react";
import useOutsideClickDetector from "../Hooks/useOutsideClickDetector";
import { HeaderDropDown } from "./HeaderDropDown";
import { observer } from "mobx-react";
import { Fragment } from "react";

import defaultProfile from "../assets/ProfilePlaceholder.jpg";
import { Popover, Transition } from "@headlessui/react";
// import { MenuIcon, XIcon } from '@heroicons/react/outline'
import {
  AnnotationIcon,
  GlobeIcon,
  LightningBoltIcon,
  ScaleIcon,
  SpeakerphoneIcon,
  XIcon,
  MenuIcon,
  CalendarIcon,
  ChatIcon,
  BadgeCheckIcon,
  HeartIcon,
  LocationMarkerIcon,
  DocumentDownloadIcon,
  BellIcon,
  UserGroupIcon,
  PhoneIcon,
  DeviceMobileIcon,
} from "@heroicons/react/outline";
import logo from "../assets/safetravels-logo.png";

import { AppContext } from "../App.context";
import { useHistory } from "react-router";
import { RouteConfig } from "../constants/RouteConfig";
interface Props {
  name: string;
  avatar?: string;
  isAdminOrStaff: boolean;
}
const Header: FC<Props> = ({ name, avatar, isAdminOrStaff }) => {
  const [menu, setMenu] = useState(false);
  const [show, setShow] = useState(false);
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);
  const dropdownRef = useOutsideClickDetector<HTMLDivElement>(() =>
    setOpenDropdown(false)
  );
  const { store, storage } = AppContext;

  const history = useHistory();

  const logout = () => {
    store.logout();
    storage.reset();
    window.location.reload();
  };
  const navigation = [
    { name: "Trips", href: "/trips" },
    { name: "Business Account", href: "/business-details" },
    { name: "Business Staff", href: "/staff" },
    { name: "App Content", href: "/business-content" },
    { name: "Image Gallery", href: "/image-gallery" },
    { name: "My Profile", href: "/profile" },
  ];
  return (
    <Popover>
      <div className="relative pt-6 pb-6 px-4 sm:px-6 lg:px-8 px-10 bg-white">
        <nav
          className="relative flex items-center justify-between sm:h-10 lg:justify-start"
          aria-label="Global"
        >
          <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
            <div className="flex items-center justify-between w-full md:w-auto">
              <a href="#">
                {/* <span className="sr-only">Workflow</span> */}
                <img className="h-8 w-auto sm:h-10" src={logo} />
              </a>
              <div className="-mr-2 flex items-center md:hidden">
                <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <span className="sr-only">Open main menu</span>
                  <MenuIcon className="h-6 w-6" aria-hidden="true" />
                </Popover.Button>
              </div>
            </div>
          </div>
          <div className="hidden md:block md:ml-10 md:pr-4 md:space-x-8 xl:w-100 lg:w-100">
            {navigation.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className="font-medium text-gray-00 hover:text-gray-800"
              >
                {item.name}
              </a>
            ))}
            <a
              onClick={logout}
              className=" px-8 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 md:py-4 md:text-lg md:px-10"
            >
              Log-out
            </a>
          </div>
        </nav>
      </div>

      <Transition
        as={Fragment}
        enter="duration-150 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
        >
          <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
            <div className="px-5 pt-4 flex items-center justify-between">
              <div>
                <img className="h-8 w-auto" src={logo} alt="" />
              </div>
              <div className="-mr-2">
                <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <span className="sr-only">Close main menu</span>
                  {/* <XIcon className="h-6 w-6" aria-hidden="true" /> */}
                </Popover.Button>
              </div>
            </div>
            <div className="px-2 pt-2 pb-3 space-y-1">
              {navigation.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-800 hover:bg-gray-50"
                >
                  {item.name}
                </a>
              ))}
            </div>
            <a
              onClick={logout}
              className=" px-8 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 md:py-4 md:text-lg md:px-10"
            >
              Log-out
            </a>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export default observer(Header);
